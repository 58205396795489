import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import classnames from "classnames";

import { IoClose } from "react-icons/io5";
import editIcon from "../../assets/icons/user/edit.svg";
import { handleCreateCompanyProfileData } from "../../actions/adminPanel";

import { BASE_URL, UNKNOWN_USER_PHOTO } from "../../utils/constants";

function ProfileModal({
  open,
  closeModal,
  cb_handleCloseModal,
  openCreateCompanyProfilePhotoModal,
  authedUser,
  dispatch,
  adminPanel,
}) {
  const cancelButtonRef = useRef();
  const [subDialogOpen, setSubDialogeOpen] = useState(false);
  const [name, setName] = useState("");
  const [companyInfo, setCompanyInfo] = useState("");
  const [maxEmployees, setMaxEmployees] = useState(0);
  const [address, setAddress] = useState("");
  const [photo, setPhoto] = useState(null);
  const [photoObj, setPhotoObj] = useState(null);

  const handleCloseModal = () => {
    if (!subDialogOpen) {
      cb_handleCloseModal();
      closeModal();
    }
  };

  const handleCloseCreateCompanyProfilePhotoModal = (photo = null) => {
    setSubDialogeOpen(false);
    setPhotoObj(photo);
    if (photo !== null) {
      setPhoto(URL.createObjectURL(photo));
    }
  };

  const handleOpenCreateCompanyProfilePhotoModal = () => {
    setSubDialogeOpen(true);
    openCreateCompanyProfilePhotoModal(
      () => (photo) => handleCloseCreateCompanyProfilePhotoModal(photo)
    );
  };

  const handleCreateSuccess = () => {
    closeModal();
    cb_handleCloseModal();
  };

  const handleCreate = () => {
    dispatch(
      handleCreateCompanyProfileData(
        {
          name,
          company_info: companyInfo,
          company_image: photoObj,
          max_employees: maxEmployees,
          address,
        },
        () => () => handleCreateSuccess()
      )
    );
  };

  return (
    <>
      <Transition show={open} as={Fragment}>
        <Dialog
          as="div"
          className="text-sm font-inter bg-black bg-opacity-50 fixed inset-0 z-20 overflow-y-auto"
          initialFocus={cancelButtonRef}
          static
          open={open}
          onClose={handleCloseModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full sm:w-2/3 md:w-3/5 xl:w-6/12 2xl:w-5/12 transition transition-width ease-in duration-700 my-8 text-left align-middle transition-all transform text-my-gray-900 bg-white shadow-xl rounded-2xl">
                <div className="z-10 h-0 absolute top-0 left-0 bg-transparent py-4">
                  <input
                    className="z-10 h-0 cursor-auto bg-my-gray-300 placeholder-my-gray-300 "
                    placeholder=""
                  />
                </div>
                <div className="relative flex justify-between items-center w-full h-20 rounded-tl-xl rounded-tr-xl bg-my-green-400 px-8">
                  <p className="text-my-gray-900 font-bold text-lg">
                    Company Profile
                  </p>
                  <div>
                    <span
                      ref={cancelButtonRef}
                      onClick={handleCloseModal}
                      className="cursor-pointer font-medium text-white text-xs hover:text-opacity-75"
                    >
                      <IoClose
                        size={25}
                        className="text-my-gray-400 hover:text-my-red-100"
                      />
                    </span>
                  </div>
                </div>
                <div className="w-full h-full px-16 my-8">
                  <div className="flex w-full">
                    <div className="relative w-24 h-24 rounded-full p-1">
                      <img
                        className="w-full h-full rounded-full"
                        src={photo ? photo : UNKNOWN_USER_PHOTO}
                        alt="Company avatar"
                      />

                      <span
                        onClick={handleOpenCreateCompanyProfilePhotoModal}
                        className="cursor-pointer absolute top-3 right-1 flex justify-center items-center w-6 h-6 rounded-full bg-my-sky-blue-200"
                      >
                        <img className="w-3 h-3" src={editIcon} alt="edit" />
                      </span>
                    </div>
                    <div className="flex flex-col justify-center ml-2">
                      <p className="text-my-gray-900 text-lg font-bold">
                        {name}
                      </p>
                      <span className="text-my-gray-400 font-medium">
                        {companyInfo}
                      </span>
                    </div>
                  </div>
                  <div className="grid grid-cols-12 gap-4 mt-6">
                    <div className="col-span-12 md:col-span-6 flex flex-col justify-start">
                      <p className="font-medium tracking-wider">Name:</p>
                      <input
                        className="h-10 w-full font-bold outline-none bg-my-gray-201 disabled:bg-my-gray-101 rounded-md px-4 mt-1"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="col-span-12 md:col-span-6 flex flex-col justify-start">
                      <p className="font-medium tracking-wider">
                        Company Info:
                      </p>
                      <input
                        className="h-10 w-full font-bold outline-none bg-my-gray-201 disabled:bg-my-gray-101 rounded-md px-4 mt-1"
                        value={companyInfo}
                        onChange={(e) => setCompanyInfo(e.target.value)}
                      />
                    </div>

                    <div className="col-span-12 md:col-span-6 flex flex-col justify-start">
                      <p className="font-medium tracking-wider">
                        Max Employees:
                      </p>
                      <input
                        className="h-10 w-full font-bold outline-none bg-my-gray-201 disabled:bg-my-gray-101 rounded-md px-4 mt-1"
                        value={maxEmployees}
                        onChange={(e) => setMaxEmployees(e.target.value)}
                      />
                    </div>
                    <div className="col-span-12 md:col-span-6 flex flex-col justify-start">
                      <p className="font-medium tracking-wider">Address:</p>
                      <input
                        className="h-10 w-full font-bold outline-none bg-my-gray-201 disabled:bg-my-gray-101 rounded-md px-4 mt-1"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="relative flex justify-end items-center w-full h-20 rounded-bl-xl rounded-br-xl bg-my-green-400">
                  <div
                    onClick={handleCloseModal}
                    className="cursor-pointer flex justify-center items-center w-42 lg:w-52 h-12 tracking-widest text-my-sky-blue-200 font-bold rounded-md border-2 border-my-sky-blue-200 bg-white my-4 mr-4"
                  >
                    <p className="uppercase mx-3">cancel</p>
                  </div>
                  <div
                    onClick={handleCreate}
                    className="cursor-pointer flex justify-center items-center w-62 lg:w-72 h-12 tracking-widest text-white font-bold rounded-md bg-gradient-to-b from-my-sky-blue-100 to-my-sky-blue-200 my-4 mr-4"
                  >
                    <p className="uppercase mx-3">create</p>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

const mapStateToProps = ({ authedUser, adminPanel }) => ({
  authedUser,
  adminPanel,
});

export default connect(mapStateToProps)(ProfileModal);
