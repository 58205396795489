import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import classnames from "classnames";

import { IoClose } from "react-icons/io5";
import editIcon from "../../assets/icons/user/edit.svg";
import { handleSaveProfileData } from "../../actions/authedUser";
import ModalSelect from "../common/ModalSelect";

import { BASE_URL, UNKNOWN_USER_PHOTO } from "../../utils/constants";
import { getImageUrlOfAwsToQuestionMark } from "../../utils/helper";

function ProfileModal({
  open,
  closeModal,
  openProfilePhotoModal,
  authedUser,
  dispatch,
  adminPanel,
}) {
  const cancelButtonRef = useRef();
  const [subDialogOpen, setSubDialogeOpen] = useState(false);
  const [fullName, setFullName] = useState("");
  const [username, setUsername] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [photo, setPhoto] = useState(null);
  const [photoObj, setPhotoObj] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const [selectedCompany, setSelectedCompany] = useState({
    id: 0,
    name: "Empty",
  });

  useEffect(() => {
    if (open && !isEdit) {
      setFullName(
        adminPanel?.isImpersonating
          ? `${adminPanel?.client.first_name} ${adminPanel?.client.last_name}`
          : `${authedUser.first_name} ${authedUser.last_name}`
      );
      setPhone(
        adminPanel?.isImpersonating
          ? adminPanel?.client.phone_number
            ? adminPanel?.client.phone_number
            : ""
          : authedUser.phone_number
          ? authedUser.phone_number
          : ""
      );
      setAddress(
        adminPanel?.isImpersonating
          ? adminPanel?.client.address
            ? adminPanel?.client.address
            : ""
          : authedUser.address
          ? authedUser.address
          : ""
      );
      setPhoto(
        adminPanel?.isImpersonating
          ? !adminPanel?.client?.isGoogle
            ? adminPanel?.client?.profile_image
              ? getImageUrlOfAwsToQuestionMark(
                  adminPanel?.client?.profile_image
                )
              : UNKNOWN_USER_PHOTO
            : adminPanel?.client?.social_login_image
            ? adminPanel?.client?.social_login_image
            : UNKNOWN_USER_PHOTO
          : !authedUser?.isGoogle
          ? authedUser?.profile_image
            ? getImageUrlOfAwsToQuestionMark(authedUser?.profile_image)
            : UNKNOWN_USER_PHOTO
          : authedUser?.social_login_image
          ? authedUser?.social_login_image
          : UNKNOWN_USER_PHOTO
      );
      setUsername(
        adminPanel?.isImpersonating
          ? adminPanel?.client?.email
            ? adminPanel?.client?.email
            : ""
          : authedUser.email
          ? authedUser.email
          : ""
      );
      const companyId = adminPanel?.isImpersonating
        ? adminPanel?.client?.company
        : authedUser?.company;
      const company =
        companyId &&
        adminPanel?.companies &&
        adminPanel?.companies.results.length > 0
          ? adminPanel?.companies.results.filter(
              (companyObj) => companyObj.id === companyId
            )
          : null;
      if (company && company.length > 0) {
        setSelectedCompany(company[0]);
      }
    }
    // address: null;
    // company_info: null;
    // email: "user3@demo.com";
    // first_name: "Test";
    // last_name: "User";
    // phone_number: null;
    // profile_image: null;
  }, [open, isEdit, adminPanel?.isImpersonating]);

  const handleCloseModal = () => {
    if (!subDialogOpen) {
      closeModal();
      setIsEdit(false);
    }
  };

  const handleCloseProfilePhotoModal = (photo = null) => {
    setSubDialogeOpen(false);
    setPhotoObj(photo);
    if (photo !== null) {
      setPhoto(URL.createObjectURL(photo));
    }
  };

  const handleOpenProfilePhotoModal = () => {
    // if (isEdit) {
    setIsEdit(true);
    setSubDialogeOpen(true);
    openProfilePhotoModal(() => (photo) => handleCloseProfilePhotoModal(photo));
    // }
  };

  const handleSaveSuccess = () => {
    setIsEdit(false);
    closeModal();
  };

  const handleSave = () => {
    // fullName
    // username
    // address
    // phone
    // photo

    // address
    // company_info
    // email
    // first_name
    // last_name
    // phone_number
    // profile_image
    dispatch(
      handleSaveProfileData(
        {
          id: adminPanel?.isImpersonating
            ? adminPanel?.client?.id
            : authedUser?.id,
          address,
          company_info: authedUser.company_info,
          email: username,
          first_name: fullName.substring(0, fullName.indexOf(" ")).trim(),
          last_name: fullName.substring(fullName.indexOf(" ")).trim(),
          phone_number: phone,
          profile_image: photoObj,
          company: selectedCompany.id === 0 ? null : selectedCompany.id,
        },
        () => () => handleSaveSuccess()
      )
    );
  };

  return (
    <>
      <Transition show={open} as={Fragment}>
        <Dialog
          as="div"
          className="text-sm font-inter bg-black bg-opacity-50 fixed inset-0 z-20 overflow-y-auto"
          initialFocus={cancelButtonRef}
          static
          open={open}
          onClose={handleCloseModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full sm:w-2/3 md:w-3/5 xl:w-6/12 2xl:w-5/12 transition transition-width ease-in duration-700 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="z-10 h-0 absolute top-0 left-0 bg-transparent py-4">
                  <input
                    className="z-10 h-0 cursor-auto bg-my-gray-300 placeholder-my-gray-300 "
                    placeholder=""
                  />
                </div>
                <div className="relative flex justify-between items-center w-full h-20 rounded-tl-xl rounded-tr-xl bg-my-green-400 px-8">
                  <p className="text-my-gray-900 font-bold text-lg">
                    {isEdit ? "Edit Your Profile" : "Your Profile"}
                  </p>
                  <div>
                    <span
                      ref={cancelButtonRef}
                      onClick={handleCloseModal}
                      className="cursor-pointer font-medium text-white text-xs hover:text-opacity-75"
                    >
                      <IoClose
                        size={25}
                        className="text-my-gray-400 hover:text-my-red-100"
                      />
                    </span>
                  </div>
                </div>
                <div className="w-full h-full px-16 my-8">
                  <div className="flex w-full">
                    <div className="relative w-24 h-24 rounded-full p-1">
                      <img
                        className="w-full h-full rounded-full"
                        src={photo}
                        alt="User avatar"
                      />
                      {(!authedUser?.isGoogle || adminPanel?.isImpersonating) &&
                        !adminPanel?.client?.social_login_image && (
                          <span
                            onClick={handleOpenProfilePhotoModal}
                            className={classnames(
                              "cursor-pointer absolute top-3 right-1 flex justify-center items-center w-6 h-6 rounded-full bg-my-sky-blue-200",
                              {
                                // "cursor-pointer": isEdit,
                                // "cursor-default": !isEdit
                              }
                            )}
                          >
                            <img
                              className="w-3 h-3"
                              src={editIcon}
                              alt="edit"
                            />
                          </span>
                        )}
                    </div>
                    <div className="flex flex-col justify-center ml-2">
                      <p className="text-my-gray-900 text-lg font-bold">
                        {fullName}
                      </p>
                      <span className="text-my-gray-400 font-medium">
                        Canva Representative
                      </span>
                    </div>
                  </div>
                  <div className="text-my-gray-900 mt-10">
                    <div className="grid grid-cols-12 gap-4">
                      <div className="col-span-12 md:col-span-6 flex flex-col justify-start">
                        <p className="font-medium tracking-wider">Full Name:</p>
                        <input
                          disabled={!isEdit}
                          className="h-10 w-full font-bold outline-none bg-my-gray-201 disabled:bg-my-gray-101 rounded-md px-4 mt-1"
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                        />
                      </div>
                      <div className="col-span-12 md:col-span-6 flex flex-col justify-start">
                        <p className="font-medium tracking-wider">Username:</p>
                        <input
                          disabled={!isEdit}
                          className="h-10 w-full font-bold outline-none bg-my-gray-201 disabled:bg-my-gray-101 rounded-md px-4 mt-1"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </div>

                      <div className="col-span-12 md:col-span-6 flex flex-col justify-start">
                        <p className="font-medium tracking-wider">Address:</p>
                        <input
                          disabled={!isEdit}
                          className="h-10 w-full font-bold outline-none bg-my-gray-201 disabled:bg-my-gray-101 rounded-md px-4 mt-1"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </div>
                      <div className="col-span-12 md:col-span-6 flex flex-col justify-start">
                        <p className="font-medium tracking-wider">Phone:</p>
                        <input
                          disabled={!isEdit}
                          className="h-10 w-full font-bold outline-none bg-my-gray-201 disabled:bg-my-gray-101 rounded-md px-4 mt-1"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>

                      {adminPanel?.isImpersonating ||
                        (authedUser?.is_admin && (
                          <div className="col-span-12 md:col-span-6 flex gap-y-1 flex-col justify-start">
                            <p className="font-medium tracking-wider">
                              Company:
                            </p>
                            <div className="z-10 h-10">
                              <ModalSelect
                                isDisabled={!isEdit}
                                options={
                                  adminPanel?.companies
                                    ? [
                                        { id: 0, name: "Empty" },
                                        ...adminPanel?.companies?.results,
                                      ]
                                    : [{ id: 0, name: "Empty" }]
                                }
                                selected={selectedCompany}
                                setSelected={setSelectedCompany}
                              />
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>

                {!isEdit && (
                  <div className="relative flex justify-end items-center w-full h-20 rounded-bl-xl rounded-br-xl bg-my-green-400">
                    {(!authedUser?.isGoogle || adminPanel?.isImpersonating) &&
                      !adminPanel?.client?.social_login_image && (
                        <div
                          onClick={() => setIsEdit(!isEdit)}
                          className="cursor-pointer flex justify-center items-center w-42 lg:w-52 h-12 tracking-widest text-my-sky-blue-200 font-bold rounded-md border-2 border-my-sky-blue-200 bg-white my-4 mr-4"
                        >
                          <p className="uppercase mx-3">edit profile</p>
                        </div>
                      )}
                    <div
                      onClick={handleCloseModal}
                      className="cursor-pointer flex justify-center items-center w-62 lg:w-72 h-12 tracking-widest text-white font-bold rounded-md bg-gradient-to-b from-my-sky-blue-100 to-my-sky-blue-200 my-4 mr-4"
                    >
                      <p className="uppercase mx-3">Close</p>
                    </div>
                  </div>
                )}
                {isEdit && (
                  <div className="relative flex justify-end items-center w-full h-20 rounded-bl-xl rounded-br-xl bg-my-green-400">
                    <div
                      onClick={() => setIsEdit(false)}
                      className="cursor-pointer flex justify-center items-center w-42 lg:w-52 h-12 tracking-widest text-my-sky-blue-200 font-bold rounded-md border-2 border-my-sky-blue-200 bg-white my-4 mr-4"
                    >
                      <p className="uppercase mx-3">cancel</p>
                    </div>
                    <div
                      onClick={handleSave}
                      className="cursor-pointer flex justify-center items-center w-62 lg:w-72 h-12 tracking-widest text-white font-bold rounded-md bg-gradient-to-b from-my-sky-blue-100 to-my-sky-blue-200 my-4 mr-4"
                    >
                      <p className="uppercase mx-3">save</p>
                    </div>
                  </div>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

const mapStateToProps = ({ authedUser, adminPanel }) => ({
  authedUser,
  adminPanel,
});

export default connect(mapStateToProps)(ProfileModal);
