import axios from "axios";

import { showLoading, hideLoading } from "react-redux-loading";
import {
  addAuthorizedUserRequest,
  getAuthorizedUsersRequest
} from "../utils/api";
import { setErrorMessage } from "./shared";

// Actions declaration
export const ADD_USERS = "ADD_USERS";
export const ADD_USER = "ADD_USER";

// Action functions for updating the store
export function addUsers(authorizedUsers) {
  return {
    type: ADD_USERS,
    authorizedUsers
  };
}

export function addUser(authorizedUser) {
  return {
    type: ADD_USER,
    authorizedUser
  };
}

// Handling fetch authorized users request
export function handleFetchAllAuthorizedUsers(
  cb_handleReceiveAuthorizedUsersSuccess
) {
  return (dispatch) => {
    dispatch(showLoading());
    getAuthorizedUsersRequest()
      .then((response) => {
        dispatch(addUsers(response.data.results));
        cb_handleReceiveAuthorizedUsersSuccess();
      })
      .then(() =>
        dispatch(
          setErrorMessage({
            message: "Authorized Users Fetched Successfully",
            type: "info"
          })
        )
      )
      .catch((err) => {
        cb_handleReceiveAuthorizedUsersSuccess();
        let msg = "Failed to Fetch Authorized Users";
        if (err && err.response && err.response.data) {
          msg = JSON.stringify(err.response.data).replace(/"/g, "");
        }
        dispatch(
          setErrorMessage({
            message: msg,
            type: "error"
          })
        );
      })
      .then(() =>
        setTimeout(() => {
          dispatch(setErrorMessage({}));
        }, 3000)
      )
      .then(() => dispatch(hideLoading()));
  };
}

// Handling add authorized user request
export function handleAddAuthorizedUser(
  { email, company_id },
  cb_handleSuccess = () => {},
  cb_handleFailure = () => {}
) {
  return (dispatch) => {
    dispatch(showLoading());
    addAuthorizedUserRequest({ email, company_id })
      .then(() => {
        dispatch(addUser(email));
        cb_handleSuccess();
      })
      .then(() =>
        dispatch(
          setErrorMessage({
            message: "User Has Been Invited Successfully",
            type: "info"
          })
        )
      )
      .catch((err) => {
        cb_handleFailure();
        let msg = "Failed to Invite User";
        if (err && err.response && err.response.data) {
          msg = JSON.stringify(err.response.data).replace(/"/g, "");
        }

        dispatch(
          setErrorMessage({
            message: msg,
            type: "error"
          })
        );
      })
      .then(() =>
        setTimeout(() => {
          dispatch(setErrorMessage({}));
        }, 3000)
      )
      .then(() => dispatch(hideLoading()));
  };
}
