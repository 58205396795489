import SelectSource from "./SelectSource";
import SelectSource2 from "./SelectSource2";
import SelectSource3 from "./SelectSource3";

const documentsDummy = [
  {
    id: 1,
    name: "Marketing Material",
    files_count: 10,
  },
  {
    id: 2,
    name: "Videos",
    files_count: 3,
  },
  {
    id: 3,
    name: "Uploaded Restoration Reports",
    files_count: 90,
  },
  {
    id: 4,
    name: "Other",
    files_count: 0,
  },
  {
    id: 5,
    name: "Documentations",
    files_count: 5,
  },
];
function Documents() {
  return (
    <div className="w-full h-full">
      <div className="font-montserrat font-bold text-xl">Documents</div>
      <div className="flex items-center w-full my-4">
        <div className="w-full border-t border-my-gray-102"></div>
      </div>
      <SelectSource2 />
      {false && documentsDummy && (
        <div className="grid grid-cols-4 grid-rows-1 auto-cols-fr auto-rows-fr gap-3">
          {documentsDummy.map((doc) => (
            <div
              key={doc?.id}
              className="cursor-pointer flex flex-col items-center text-my-gray-201 hover:text-my-sky-blue-100 gap-y-0"
            >
              <span>
                <svg
                  width="112"
                  height="112"
                  viewBox="0 0 112 112"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M111.934 28.7328L100.778 80.5656C100.406 82.3944 98.42 84 96.5694 84H4.05127C3.31846 84 2.70814 83.7441 2.34064 83.2869C1.97314 82.8297 1.85283 82.1712 2.00595 81.4538L13.1622 29.61C13.5538 27.7878 15.5225 26.1844 17.3797 26.1844H109.891C110.624 26.1844 111.23 26.4403 111.6 26.9019C111.969 27.3634 112.088 28 111.934 28.7328ZM17.3753 22.0456H102.797V16.7956C102.797 15.4292 102.257 14.1183 101.294 13.149C100.33 12.1797 99.023 11.6309 97.6566 11.6222H42.3128V5.18219C42.3152 3.81463 41.776 2.50178 40.8131 1.53069C39.8502 0.559588 38.5419 0.00924995 37.1744 0H13.5144C12.1476 0.00751873 10.8395 0.556033 9.87614 1.52555C8.91277 2.49507 8.37259 3.80667 8.37377 5.17344V11.6025H5.14064C3.77218 11.6106 2.46272 12.1608 1.49916 13.1326C0.535605 14.1043 -0.00348038 15.4184 1.69107e-05 16.7869V68.3856C0.0087228 68.92 0.100226 69.4499 0.271267 69.9562L9.13721 28.7328C9.94658 24.9813 13.5625 22.0456 17.3753 22.0456Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
              <span className="text-black text-center font-bold">{`${doc.name}${
                doc?.files_count > 0 ? ` (${doc?.files_count})` : ""
              }`}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Documents;
