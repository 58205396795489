import { withRouter } from "react-router-dom";

function MarketingPortal({ match }) {
  return (
    <div className="border col-start-1 md:col-start-2 lg:col-start-3 col-end-17 row-start-3 row-end-24 grid grid-rows-24 gap-y-2 h-full bg-white rounded-t-xl">
      <div className="row-span-2 flex items-center w-full rounded-t-xl bg-my-green-400">
        <span className="font-bold text-xs md:text-sm lg:text-base lg:text-xl text-center text-my-gray-900 ml-4">
          Marketing Portal
        </span>
      </div>
      <div className="flex border border-purple-500">
        <div className="w-60 border border-red-500">
          <span>1</span>
          <span>1</span>
        </div>
        <div className="w-60 border border-green-500">
          <span>2</span>
        </div>
      </div>
    </div>
  );
}

export default withRouter(MarketingPortal);
