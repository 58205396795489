import { useEffect } from "react";
import useDrivePicker from "react-google-drive-picker";
import { CLIENT_ID, GMAP_KEY as API_KEY } from "../../../../../utils/constants";

function SelectSource2() {
  const [openPicker, data, authResponse] = useDrivePicker();
  // const customViewsArray = [new google.picker.DocsView()]; // custom view
  const handleOpenPicker = () => {
    openPicker({
      clientId:
        "148652465688-ld0hukaj8231t6ahhqogbjkkkj7po8q3.apps.googleusercontent.com",
      developerKey: "AIzaSyBxPAHJg3IX8S-ANPfv8SjKt_sneQ9Olu8",
      viewId: "DOCS",
      // token: token, // pass oauth token in case you already have one
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      // customViews: customViewsArray, // custom view
    });
  };

  useEffect(() => {
    // do anything with the selected/uploaded files
    if (data) {
      data.docs.map((i) => console.log(i.name));
    }
  }, [data]);

  return (
    <div>
      <button onClick={() => handleOpenPicker()}>Open Picker</button>
    </div>
  );
}

export default SelectSource2;
