import React, { Component } from "react";
import { Link } from "react-router-dom";
import UnderContruction from "../../assets/images/404.png";

class Page404 extends Component {
  render() {
    return (
      <div className="flex flex-col justify-center items-center h-screen w-full dark:bg-true-gray-900 dark:bg-opacity-25">
        <img src={UnderContruction} alt="under construction" />
        <Link
          to="/signin"
          className="font-bold underline text-2xl text-true-gray-900 mt-8"
        >
          Go to Sign In page
        </Link>
      </div>
    );
  }
}

export default Page404;
