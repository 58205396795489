// import shared actions
import { SET_ERROR_MESSAGE } from "../actions/shared";

// shared reducer
export default function shared(state = {}, action) {
  switch (action.type) {
    case SET_ERROR_MESSAGE:
      return {
        ...action.messageObj,
      };
    default:
      return state;
  }
}
