import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";

import blueCircleIcon from "../../../assets/icons/dashboard/chart/blueCircle.svg";
import arrowDownIcon from "../../../assets/icons/dashboard/chart/arrowDown.svg";
import arrowUpIcon from "../../../assets/icons/dashboard/chart/arrowUp.svg";

import useWindowDimensions from "../../main/useWindowDimensions";

function FirstGraphSelect({
  graphOptions,
  selectedFirstGraph,
  setSelectedFirstGraph
}) {
  const { isSmScreen } = useWindowDimensions();
  return (
    <Listbox value={selectedFirstGraph} onChange={setSelectedFirstGraph}>
      {({ open }) => (
        <div className="w-5 md:w-max fixed relative">
          <Listbox.Button className="relative px-3 w-full rounded-lg cursor-pointer">
            <span className="absolute inset-y-0 left-0 flex items-center md:pr-2 pointer-events-none">
              <img
                style={{
                  height: isSmScreen ? 10 : 13,
                  width: isSmScreen ? 10 : 17
                }}
                src={blueCircleIcon}
                alt="blue circle"
              />
            </span>
            <span className="invisible md:visible block truncate text-center md:px-6">
              {selectedFirstGraph.name}
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center md:pr-2 pointer-events-none">
              {open ? (
                <img
                  style={{
                    height: isSmScreen ? 7 : 10,
                    width: isSmScreen ? 7 : 10
                  }}
                  src={arrowUpIcon}
                  alt="arrow Up"
                />
              ) : (
                <img
                  style={{
                    height: isSmScreen ? 7 : 10,
                    width: isSmScreen ? 7 : 10
                  }}
                  src={arrowDownIcon}
                  alt="arrow Down"
                />
              )}
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute top-7 -right-3 md:left-0 w-24 md:w-full overflow-auto text-center bg-white rounded-md shadow-lg max-h-70 ring-1 ring-black ring-opacity-5 focus:outline-none">
              {graphOptions.map((option, index) => (
                <Listbox.Option
                  disabled={!option.available}
                  key={option.id}
                  className={({ active }) =>
                    `${
                      active
                        ? "bg-my-gray-101 text-amber-900 bg-amber-100"
                        : "text-gray-900"
                    }${
                      !option.available
                        ? " cursor-not-allowed opacity-75 "
                        : " cursor-pointer "
                    }
                          ${index % 2 !== 0 && " bg-my-gray-201 "}
                          cursor-pointer select-none relative text-right md:text-center py-2 pl-7 md:pl-10 md:pr-4`
                  }
                  value={option}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`${
                          selected ? "font-bold" : "font-normal"
                        } block truncate hover:whitespace-normal text-center`}
                      >
                        {option.name}
                      </span>
                      {selected ? (
                        <span
                          className={`${
                            active ? "text-amber-600" : "text-amber-600"
                          }
                                absolute inset-y-0 left-0 flex items-center rounded-full pl-2 md:pl-3`}
                        >
                          <img
                            style={{
                              height: isSmScreen ? 10 : 13,
                              width: isSmScreen ? 10 : 17
                            }}
                            src={blueCircleIcon}
                            alt="blue circle"
                          />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}

export default FirstGraphSelect;
