// Import Authorized users actions
import { GET_GRAPH_DATA, GET_GRAPH_SUMMARY_DATA } from "../actions/graphData";

// Graph data reducer
export default function graphData(state = {}, action) {
  switch (action.type) {
    case GET_GRAPH_DATA:
      return { ...state, graphData: action.graphData };
    case GET_GRAPH_SUMMARY_DATA:
      return { ...state, graphSummaryData: action.graphSummaryData };
    default:
      return state;
  }
}
