import { useState, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import moment from "moment";
import customizeIcon from "../../assets/icons/dashboard/table/customize.svg";
import arrowDownIcon from "../../assets/icons/dashboard/table/arrowDown.svg";
import arrowUpIcon from "../../assets/icons/dashboard/table/arrowUp.svg";
import printerIcon from "../../assets/icons/dashboard/table/printer.svg";
import exportIcon from "../../assets/icons/dashboard/table/export.svg";
import sortArrowDownIcon from "../../assets/icons/dashboard/table/sortArrowDown.svg";
import sortArrowUpIcon from "../../assets/icons/dashboard/table/sortArrowUp.svg";

import {
  getDataDynamicallyFromObject,
  checkIsNumberAndHasDecimal,
  aroundDecimalValueToOneDecimal,
  numberWithCommas,
  isValidDate,
} from "../../utils/helper";

import { handleDownloadRestorationDataRecordReport } from "../../actions/restorationData";

import useWindowDimensions from "../main/useWindowDimensions";

import Tippy from "@tippyjs/react";
import { formatISO } from "date-fns";

function MyTable({
  columnsInit,
  limit,
  offsetInit,
  handleGetData,
  openCustomizeTableColumnsModal,
  tableData,
  openRestorationSiteModal,
  openCertificateModal,
  dispatch,
  search,
  userId,
  dropDownData,
  tableFilter,
  keepFetchingData,
  authedUser,
}) {
  const { isSmScreen } = useWindowDimensions();
  const [columns, setColumns] = useState(columnsInit);
  const [data, setData] = useState([]);
  const [pages, setPages] = useState(0);
  const [offset, setOffset] = useState(offsetInit);
  const [selectedPage, setSelectedPage] = useState(0);
  const [expandedRecords, setExpandedRecords] = useState([]);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [ordering, setOrdering] = useState("transaction__created_date");

  // useEffect(() => {
  //   const ordering = `transaction__created_date`;
  //   dispatch(
  //     handleGetData({ limit, offset, ordering, search, userId, tableFilter })
  //   );
  // }, []);

  useEffect(() => {
    const ordering = `transaction__created_date`;
    dispatch(
      handleGetData({
        limit,
        offset: offsetInit,
        ordering,
        search,
        userId,
        tableFilter,
      })
    );
  }, [tableFilter]);

  useEffect(() => {
    dispatch(
      handleGetData({
        limit,
        offset: offsetInit,
        ordering,
        search,
        userId,
        tableFilter,
      })
    );
  }, [search]);

  useEffect(() => {
    if (keepFetchingData) {
      dispatch(
        handleGetData(
          {
            limit,
            offset: offsetInit,
            ordering,
            search,
            userId,
            tableFilter,
          },
          false
        )
      );
    }
  }, [keepFetchingData]);

  useEffect(() => {
    if (tableData?.count >= 0) {
      setData(tableData?.results);
      const numPages = Math.ceil(tableData?.count / Number(limit));
      const pages = [];
      for (let i = 1; i <= numPages; i++) {
        pages.push(i);
      }

      setPages(pages);

      let offset = undefined;

      if (tableData?.current && tableData?.current.includes("&")) {
        offset = Number(
          tableData?.current.split("&")[1].replace("offset=", "")
        );
      }

      if (offset !== undefined) {
        setOffset(offset);
        setSelectedPage(offset / limit + 1);
      }
    }
  }, [tableData]);

  const handleSorting = (id) => {
    let ordering = `transaction__created_date`;
    const col = columns.filter((column) => column?.id === id)[0];
    if (sortedColumn === null || sortedColumn?.id !== col?.id) {
      ordering = `transaction__${id}`;
      setSortedColumn({ ...col, order: "asc" });
    } else {
      if (sortedColumn?.order === "asc") {
        ordering = `-transaction__${id}`;
        setSortedColumn({ ...sortedColumn, order: "desc" });
      } else {
        setSortedColumn(null);
      }
    }

    setOrdering(ordering);
    dispatch(
      handleGetData({
        limit,
        offset: offsetInit,
        ordering,
        search,
        userId,
        tableFilter,
      })
    );
  };

  const handleApplyChanges = ({ items, selected }) => {
    const leftColumns = items.map((item) => ({ ...item, isVisible: true }));
    const rightColumns = selected.map((item) => ({
      ...item,
      isVisible: false,
    }));
    setColumns([
      {
        id: "sn",
        content: "SN",
        isVisible: true,
      },
      ...leftColumns,
      ...rightColumns,
    ]);
  };

  const handleClickedRow = (id) => {
    if (expandedRecords.includes(id)) {
      setExpandedRecords(expandedRecords.filter((expId) => expId !== id));
    } else {
      setExpandedRecords([...expandedRecords, id]);
    }
  };

  const visibleColumns = columns.filter((column) => column?.isVisible === true);

  const inVisibleColumns = columns.filter(
    (column) => column?.isVisible === false
  );
  return (
    // <Fragment className="row-start-1 row-end-25 grid grid-rows-24 gap-y-1">
    <Fragment>
      <div className="row-start-1 row-end-23 w-full max-h-96 h-max border rounded-xl overflow-y-auto scrollbar scrollbar-thumb-transparent scrollbar-track-transparent">
        <table className="min-w-full text-xxs md:text-xs">
          <thead className="sticky top-0 shadow-md">
            <tr className="py-4 bg-my-green-400">
              {visibleColumns.map((column, index) => (
                <th
                  key={column?.id}
                  scope="col"
                  className={classnames(
                    "pl-3 pr-2 py-4 text-left font-medium tracking-wider border-my-gray-102 border-r",
                    { "rounded-tl-lg": index === 0 }
                  )}
                >
                  <span
                    onClick={() => {
                      if (
                        column?.id !== "sn" &&
                        (sortedColumn === null ||
                          sortedColumn?.id !== column?.id)
                      ) {
                        handleSorting(column?.id);
                      }
                    }}
                    className={classnames(
                      "flex justify-between items-center h-full",
                      {
                        "cursor-pointer":
                          column?.id !== "sn" &&
                          (sortedColumn === null ||
                            sortedColumn?.id !== column?.id),
                      }
                    )}
                  >
                    <Tippy
                      arrow={true}
                      placement="top"
                      content={column?.content}
                    >
                      <span className="capitalized">{column?.content}</span>
                    </Tippy>

                    {column?.id !== "sn" && (
                      <span
                        onClick={() => {
                          if (
                            sortedColumn !== null &&
                            sortedColumn?.id === column?.id
                          ) {
                            handleSorting(column?.id);
                          }
                        }}
                        className="cursor-pointer flex justify-center items-center w-3 h-3"
                      >
                        {sortedColumn?.id === column?.id &&
                        sortedColumn.order === "asc" ? (
                          <img src={sortArrowUpIcon} alt="sortArrowUp" />
                        ) : sortedColumn?.id === column?.id &&
                          sortedColumn.order === "desc" ? (
                          <img src={sortArrowDownIcon} alt="sortArrowDown" />
                        ) : (
                          <img
                            className="opacity-1"
                            src={sortArrowDownIcon}
                            alt="sortArrowDown"
                          />
                        )}
                      </span>
                    )}
                  </span>
                </th>
              ))}

              <th
                onClick={() =>
                  openCustomizeTableColumnsModal(
                    {
                      leftColumnItems: visibleColumns.filter(
                        (column) => column?.id !== "sn"
                      ),
                      rightColumnItems: inVisibleColumns,
                    },
                    () =>
                      ({ items, selected }) =>
                        handleApplyChanges({ items, selected })
                  )
                }
                scope="col"
                className={classnames(
                  "cursor-pointer pl-12 py-4 text-left font-bold text-xs md:text-sm tracking-wider bg-gradient-to-tl from-my-green-102 to-my-green-101 rounded-tr-lg",
                  { "rounded-tl-lg": visibleColumns.length === 0 }
                )}
              >
                <span className="flex justify-center items-center gap-x-6 h-full text-white">
                  <span className="uppercase">CUSTOMIZE</span>
                  <img
                    style={{
                      height: isSmScreen ? 10 : 13,
                      width: isSmScreen ? 10 : 17,
                    }}
                    className="ml-4 mr-8"
                    src={customizeIcon}
                    alt="customize"
                  />
                </span>
              </th>
            </tr>
          </thead>
          <tbody className="font-medium">
            {data
              // .sort(
              //   (a, b) =>
              //     a?.transaction.created_date - b?.transaction.created_date
              // )
              .map((record, index) => (
                <Fragment key={record?.id}>
                  <tr
                    key={record?.id}
                    className={classnames("w-full mt-4", {
                      "bg-my-blue-101": index % 2 !== 0,
                      "bg-white": index % 2 === 0,
                      "z-10 shadow-xl": expandedRecords.includes(
                        record?.transaction?.id
                      ),
                      "border-b border-my-gray-102":
                        !expandedRecords.includes(record?.transaction?.id) &&
                        index !== data.length - 1,
                    })}
                  >
                    {visibleColumns.map((column, colIndex) => (
                      <td
                        key={column?.id}
                        className={classnames(
                          "whitespace-nowrap w-max font-semibold text-my-gray-900 pl-3 py-4",
                          {
                            "rounded-bl-xl":
                              colIndex === 0 &&
                              index === data.length - 1 &&
                              !expandedRecords.includes(
                                record?.transaction?.id
                              ),
                          }
                        )}
                      >
                        {!column ? (
                          <div>NULL</div>
                        ) : column?.id === "sn" ? (
                          <div className="flex flex items-center">
                            {index + 1 + (selectedPage - 1) * limit}
                          </div>
                        ) : (
                          <div className="flex flex items-center">
                            <p
                              onClick={() => {
                                if (
                                  column &&
                                  column?.id === "certificate_number"
                                ) {
                                  openCertificateModal(index, record?.user);
                                }
                              }}
                              className={classnames({
                                "cursor-pointer text-my-sky-blue-200 border-b-2 border-dotted":
                                  column?.id === "certificate_number",
                              })}
                            >
                              {record?.transaction[column?.id]
                                ? !column?.doFormat
                                  ? record?.transaction[column?.id]
                                  : isNaN(record?.transaction[column?.id]) &&
                                    isValidDate(record?.transaction[column?.id])
                                  ? moment(
                                      new Date(record?.transaction[column?.id])
                                    ).format("DD/MM/YYYY hh:mm A")
                                  : checkIsNumberAndHasDecimal(
                                      record?.transaction[column?.id]
                                    )
                                  ? numberWithCommas(
                                      aroundDecimalValueToOneDecimal(
                                        record?.transaction[column?.id]
                                      )
                                    )
                                  : !isNaN(record?.transaction[column?.id])
                                  ? numberWithCommas(
                                      record?.transaction[column?.id]
                                    )
                                  : record?.transaction[column?.id]
                                : "N/A"}
                            </p>
                          </div>
                        )}
                      </td>
                    ))}
                    <td
                      className={classnames(
                        "whitespace-nowrap w-max border-l border-my-gray-102 py-2",
                        {
                          "bg-my-blue-101": index % 2 !== 0,
                          "bg-my-gray-103":
                            index % 2 === 0 &&
                            !expandedRecords.includes(record?.transaction?.id),
                          "bg-white":
                            index % 2 === 0 &&
                            expandedRecords.includes(record?.transaction?.id),
                          "rounded-br-xl":
                            index === data.length - 1 &&
                            !expandedRecords.includes(record?.transaction?.id),
                          "rounded-bl-xl":
                            visibleColumns.length === 0 &&
                            index === data.length - 1 &&
                            !expandedRecords.includes(record?.transaction?.id),
                        }
                      )}
                    >
                      <div className="flex justify-evenly items-center ml-2">
                        <img
                          style={{
                            height: isSmScreen ? 15 : 20,
                            width: isSmScreen ? 15 : 20,
                          }}
                          className="cursor-pointer hidden"
                          src={printerIcon}
                          alt="printer"
                        />
                        <img
                          onClick={() => {
                            dispatch(
                              handleDownloadRestorationDataRecordReport(
                                record?.id,
                                record?.user
                              )
                            );
                          }}
                          style={{
                            height: isSmScreen ? 15 : 20,
                            width: isSmScreen ? 15 : 20,
                          }}
                          className="cursor-pointer"
                          src={exportIcon}
                          alt="export"
                        />
                        <span
                          className="cursor-pointer"
                          onClick={() =>
                            handleClickedRow(record?.transaction?.id)
                          }
                        >
                          {expandedRecords.includes(record?.transaction?.id) ? (
                            <img
                              style={{
                                height: isSmScreen ? 15 : 20,
                                width: isSmScreen ? 15 : 20,
                              }}
                              src={arrowUpIcon}
                              alt="arrowUp"
                            />
                          ) : (
                            <img
                              style={{
                                height: isSmScreen ? 15 : 20,
                                width: isSmScreen ? 15 : 20,
                              }}
                              src={arrowDownIcon}
                              alt="arrowDown"
                            />
                          )}
                        </span>
                      </div>
                    </td>
                  </tr>
                  {expandedRecords.includes(record?.transaction?.id) && (
                    <tr className="w-full">
                      <td colSpan={visibleColumns.length + 1}>
                        <div
                          className={classnames(
                            "z-0 grid grid-cols-12 gap-x-6 gap-y-3 w-full bg-my-gray-101 bg-opacity-75 pl-32 py-8 text-xxs md:text-xs lg:text-sm",
                            {
                              "rounded-bl-xl rounded-br-xl":
                                index === data.length - 1,
                              "border-b border-my-gray-102":
                                index !== data.length - 1,
                            }
                          )}
                        >
                          {dropDownData.map((dropDownRecord) => {
                            const {
                              path,
                              label,
                              separators,
                              formats,
                              prefix,
                              doFormat,
                              isClickable,
                              onClick,
                              onClickParameterPath,
                              unit,
                            } = dropDownRecord;

                            const values = path
                              .map((record, recordIndex) => {
                                const dataFromObject =
                                  getDataDynamicallyFromObject(
                                    record,
                                    data[index]
                                  );
                                const formatArray = formats
                                  ? formats.filter(
                                      (format) => format?.id === record
                                    )
                                  : [];
                                const format =
                                  formatArray.length > 0
                                    ? formatArray[0]
                                    : undefined;
                                const value = dataFromObject
                                  ? !doFormat
                                    ? dataFromObject
                                    : isNaN(dataFromObject) &&
                                      isValidDate(dataFromObject)
                                    ? moment(new Date(dataFromObject)).format(
                                        format
                                          ? format.output
                                          : "DD/MM/YYYY hh:mm A"
                                      )
                                    : checkIsNumberAndHasDecimal(dataFromObject)
                                    ? numberWithCommas(
                                        aroundDecimalValueToOneDecimal(
                                          dataFromObject
                                        )
                                      )
                                    : !isNaN(dataFromObject)
                                    ? numberWithCommas(dataFromObject)
                                    : !format
                                    ? dataFromObject
                                    : format.output.reduce(
                                        (previousValue, currentValue) => {
                                          if (
                                            currentValue.startsWith("substr")
                                          ) {
                                            const subValues =
                                              currentValue.split(".");
                                            return previousValue.substr(
                                              subValues[1],
                                              subValues[2]
                                            );
                                          } else if (
                                            currentValue.startsWith("uppercase")
                                          ) {
                                            return previousValue.toUpperCase();
                                          } else {
                                            return previousValue;
                                          }
                                        },
                                        dataFromObject
                                      )
                                  : "N/A";
                                if (recordIndex !== path.length - 1) {
                                  return `${value}${separators[recordIndex]}`;
                                } else {
                                  return value;
                                }
                              })
                              .join("");

                            const presentableValue = `${
                              prefix && prefix !== "" ? prefix : ""
                            }${values} ${values !== "N/A" && unit ? unit : ""}`;
                            return (
                              <div className="col-span-2" key={path}>
                                <p className="capitalize font-normal text-my-gray-400">
                                  {label}
                                </p>
                                <p
                                  onClick={() => {
                                    if (isClickable) {
                                      onClick(
                                        getDataDynamicallyFromObject(
                                          onClickParameterPath,
                                          data[index]
                                        )
                                      );
                                    }
                                  }}
                                  className={classnames("font-bold", {
                                    "cursor-pointer text-my-sky-blue-200":
                                      isClickable,
                                    "text-my-gray-900": !isClickable,
                                  })}
                                >
                                  {presentableValue}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))}
          </tbody>
        </table>
      </div>
      {pages.length > 0 && (
        <div className="row-span-2 flex justify-center items-center gap-x-0.5 md:gap-x-2 text-xs md:text-sm">
          <p
            onClick={() => {
              if (tableData?.previous) {
                dispatch(
                  handleGetData({
                    limit,
                    offset: (selectedPage - 2) * limit,
                    ordering,
                    search,
                    userId,
                    tableFilter,
                  })
                );
              }
            }}
            className={classnames("text-xxs md:text-xs", {
              "cursor-pointer text-my-sky-blue-200": tableData?.previous,
              "cursor-default text-my-gray-300": !tableData?.previous,
            })}
          >{`< Previous`}</p>

          {selectedPage - 2 > 1 && (
            <span
              onClick={() => {
                if (selectedPage !== 1) {
                  dispatch(
                    handleGetData({
                      limit,
                      offset: (1 - 1) * limit,
                      ordering,
                      search,
                      userId,
                      tableFilter,
                    })
                  );
                }
              }}
              className={classnames("px-1.5 md:px-2 py-0.5 md:py-1 rounded", {
                "cursor-pointer text-my-gray-400 hover:text-white hover:bg-my-green-101 hover:bg-opacity-50":
                  selectedPage !== 1,
                "bg-my-green-101 text-white": selectedPage === 1,
              })}
            >
              1
            </span>
          )}
          {selectedPage - 3 > 1 && <span className="ml-1 md:ml-2">...</span>}
          {pages.map((page) => (
            <span
              key={page}
              onClick={() => {
                if (selectedPage !== page) {
                  dispatch(
                    handleGetData({
                      limit,
                      offset: (page - 1) * limit,
                      ordering,
                      search,
                      userId,
                      tableFilter,
                    })
                  );
                }
              }}
              key={page}
              className={classnames("px-1.5 md:px-2 py-0.5 md:py-1 rounded", {
                "cursor-pointer text-my-gray-400 hover:text-white hover:bg-my-green-101 hover:bg-opacity-50":
                  selectedPage !== page,
                "bg-my-green-101 text-white": selectedPage === page,
                hidden:
                  pages.length > 7 &&
                  ((selectedPage - 2 > 1 && page < selectedPage - 2) ||
                    (selectedPage + 2 < pages.length &&
                      page > selectedPage + 2)),
              })}
            >
              {page}
            </span>
          ))}
          {selectedPage + 3 < pages.length && (
            <span className="mr-1 md:mr-2">...</span>
          )}
          {selectedPage + 2 < pages.length && (
            <span
              onClick={() => {
                if (selectedPage !== pages.length) {
                  dispatch(
                    handleGetData({
                      limit,
                      offset: (pages.length - 1) * limit,
                      ordering,
                      search,
                      userId,
                      tableFilter,
                    })
                  );
                }
              }}
              className={classnames("px-1.5 md:px-2 py-0.5 md:py-1 rounded", {
                "cursor-pointer text-my-gray-400 hover:text-white hover:bg-my-green-101 hover:bg-opacity-50":
                  selectedPage !== pages.length,
                "bg-my-green-101 text-white": selectedPage === pages.length,
              })}
            >
              {pages.length}
            </span>
          )}
          <p
            onClick={() => {
              if (tableData?.next) {
                dispatch(
                  handleGetData({
                    limit,
                    offset: selectedPage * limit,
                    ordering,
                    search,
                    userId,
                    tableFilter,
                  })
                );
              }
            }}
            className={classnames("text-xxs md:text-xs", {
              "cursor-pointer text-my-sky-blue-200": tableData?.next,
              "cursor-default text-my-gray-300": !tableData?.next,
            })}
          >{`Next >`}</p>
        </div>
      )}
    </Fragment>
  );
}

const mapStateToProps = ({ authedUser }) => ({
  authedUser,
});

export default connect(mapStateToProps)(MyTable);
