// Import admin panel actions
import {
  GET_USERS_IN_ADMIN_PANEL,
  IMPERSONATE,
  CLOSE_IMPERSONATION,
  SAVE_PROFILE_DATA_IMPERSONATION,
  GET_COMPANIES,
  GET_ADMIN_COMPANIES,
  ADD_COMPANY,
  GET_RESTORATION_SITES,
} from "../actions/adminPanel";

// Admin panel reducer
export default function adminPanel(state = {}, action) {
  switch (action.type) {
    case GET_USERS_IN_ADMIN_PANEL:
      return {
        ...state,
        users: {
          [action.users.companyId]: {
            results: action.users.users,
          },
        },
      };
    case IMPERSONATE:
      return {
        ...state,
        isImpersonating: true,
        client: action.client,
      };
    case SAVE_PROFILE_DATA_IMPERSONATION:
      return { ...state, client: action.client };
    case CLOSE_IMPERSONATION:
      return { ...state, isImpersonating: false, client: {} };
    case GET_COMPANIES:
      return { ...state, companies: action.companies };
    case GET_ADMIN_COMPANIES:
      return { ...state, adminCompanies: action.companies };
    case ADD_COMPANY:
      return {
        ...state,
        companies: {
          ...state.companies,
          results: state.companies.results.concat([action.company]),
        },
      };
    case GET_RESTORATION_SITES:
      return { ...state, sites: action.sites };
    default:
      return state;
  }
}
