import PropTypes from "prop-types";
import styled from "styled-components";

const WrapperDef = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
  &:hover {
    z-index: 1;
  }
`;

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  user-select: none;
  transform: translate(-50%, -50%);
  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
  &:hover {
    z-index: 1;
  }
`;

// const Marker = ({ text, onClick }) => (
//   <WrapperDef alt={text} onClick={onClick} />
// );
const Marker = ({ text, onClick }) => (
  <Wrapper alt={text} onClick={onClick}>
    <svg
      width="22"
      height="26"
      viewBox="0 0 22 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3783 23.4764C10.5083 23.6964 10.7444 23.8314 11 23.8314C11.2556 23.8314 11.492 23.6964 11.6217 23.4766L12.7019 21.6498C14.9079 17.9202 16.9916 14.3975 17.7149 12.953L17.7301 12.9231C18.229 11.8985 18.482 10.7973 18.482 9.65067C18.482 5.52518 15.1258 2.16895 11 2.16895C6.87454 2.16895 3.51831 5.52518 3.51807 9.65067C3.51807 10.7983 3.77128 11.8995 4.27336 12.9289C4.98499 14.3572 7.07765 17.8951 9.29448 21.643L10.3783 23.4764Z"
        fill="#81C055"
      />
      <path
        d="M7.47461 9.37848C7.47461 11.3223 9.05597 12.9037 10.9998 12.9037C12.9434 12.9037 14.525 11.3226 14.525 9.37872C14.525 7.43487 12.9434 5.85352 10.9998 5.85352C9.05597 5.85352 7.47461 7.43463 7.47461 9.37848Z"
        fill="white"
      />
    </svg>
  </Wrapper>
);
Marker.defaultProps = {
  onClick: null,
};

Marker.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
};

export default Marker;
