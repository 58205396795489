import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
  ResponsiveContainer
} from "recharts";
import { connect } from "react-redux";
import Tippy from "@tippyjs/react";
import classnames from "classnames";

import { NumberFormater } from "../../../utils/helper";

import useWindowDimensions from "../../main/useWindowDimensions";

const dataInit = [
  {
    name: "Jan",
    pv: 2000,
    amt: 1100
  },
  {
    name: "Feb",
    pv: 2000,
    amt: 1100
  },
  {
    name: "Mar",
    pv: 2800,
    amt: 2990
  },
  {
    name: "Apr",
    pv: 3908,
    amt: 2000
  },
  {
    name: "May",
    pv: 4800,
    amt: 4181
  },
  {
    name: "Jun",
    pv: 3800,
    amt: 2500
  },
  {
    name: "Jul",
    pv: 4300,
    amt: 2100
  },
  {
    name: "Aug",
    pv: 5500,
    amt: 4290
  },
  {
    name: "Sep",
    pv: 2400,
    amt: 2400
  },
  {
    name: "Oct",
    pv: 8500,
    amt: 4290
  },
  {
    name: "Nov",
    pv: 4800,
    amt: 2181
  },
  {
    name: "Dec",
    pv: 1398,
    amt: 2210
  }
];

function StackedBarChart({
  selectedFirstGraph,
  selectedSecondGraph,
  data,
  keys
}) {
  const { isSmScreen } = useWindowDimensions();

  const [activeIndex, setActiveIndex] = useState(-1);

  const getPath2 = (x, y, width, height) => {
    return `M${x},${y + height}C${x + width / 3},${y + height} ${
      x + width / 2
    },${y + height / 3} 
  ${x + width / 2}, ${y}
  C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${
      x + width
    }, ${y + height}
  Z`;
  };

  const getPath = (x, y, width, height) => {
    const xx = `M${x} ${height === 0 ? y - 1 : y + 4}C${x} ${
      height === 0 ? y : y + 1.79086
    } ${x + 1.79086} ${y} ${x + 4} ${y}H${x + width * 1.6 - width}C${
      x + 2.2091 + width * 1.6 - width
    } ${y} ${x + 4 + width * 1.6 - width} ${height === 0 ? y : y + 1.79086} ${
      x + 4 + width * 1.6 - width
    } ${height === 0 ? y - 1 : y + 4}V${
      height === 0 ? 1 + y / 1.015 : y + height - 2 - 2
    }C${x + 4 + width * 1.6 - width} ${
      height === 0 ? y / 1.015 : y + height - 2
    } ${x + 2.2091 + width * 1.6 - width} ${
      height === 0 ? y / 1.015 : y + height - 2 + 1.79086
    } ${x + width * 1.6 - width} ${
      height === 0 ? y / 1.015 : y + height - 2 + 1.79086
    }H${x + 4}C${x + 1.79086} ${
      height === 0 ? y / 1.015 : y + height - 2 + 1.79086
    } ${x} ${height === 0 ? y / 1.015 : y + height - 2} ${x} ${
      height === 0 ? +1 + y / 1.015 : y + height - 2 - 2
    }V${height === 0 ? y / 1.015 : y + height - 2 - 2}Z`;

    return xx;
  };

  const RectangleBar = ({
    fill,
    placement,
    dataKey,
    payload,
    dataLabel,
    index,
    data,
    x,
    y,
    height,
    width
  }) => {
    const pastValue = index === 0 ? "NA" : data[index - 1][dataKey];
    const presentValue = payload[dataKey];
    const percentage =
      pastValue === "NA"
        ? 0
        : (presentValue - pastValue) /
          (pastValue === 0 && presentValue > 0
            ? pastValue
            : pastValue === 0
            ? 0.0000000000001
            : pastValue);

    return (
      <Tippy
        delay={500}
        hideDelay={500}
        duration={800}
        animation="fade"
        theme="light"
        arrow={true}
        visible={activeIndex === index}
        placement={placement}
        content={
          <div className="font-inter w-24 md:w-36 flex flex-col rounded-xl p-1 md:p-2">
            <span className="font-bold text-sm md:text-xl text-my-gray-901">
              {new Intl.NumberFormat().format(presentValue)}
            </span>
            <span className="capitalize font-light text-xxs md:text-xs text-my-gray-900 tracking-wider mt-1">
              {dataLabel}
            </span>
            <span
              className={classnames("self-end text-xxs mt-2", {
                "text-my-red-100": Math.sign(percentage) === -1,
                "text-my-green-101": Math.sign(percentage) === 1
              })}
            >
              {new Intl.NumberFormat("en-US", {
                style: "percent",
                minimumFractionDigits: 1,
                maximumFractionDigits: 1
              }).format(percentage)}
            </span>
          </div>
        }
      >
        <path
          className="cursor-pointer"
          d={getPath(x, y, width, height)}
          stroke="none"
          fill={fill}
          onMouseEnter={() => {
            setActiveIndex(index);
          }}
          onMouseLeave={() => {
            setActiveIndex(-1);
          }}
        />
      </Tippy>
    );
  };

  return (
    <div className="col-start-1 col-end-17 md:col-end-15 row-start-3 row-end-17">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          margin={{ top: 4, left: -15, right: 4, bottom: 0 }}
          barGap={-8}
          data={data}
        >
          <XAxis
            xAxisId={0}
            tickLine={false}
            tick={{ fontSize: 10, transform: "translate(0, 3)" }}
            dataKey="name"
          />
          <YAxis
            tickLine={false}
            tick={{ fontSize: 10, transform: "translate(-3, 0)" }}
            tickFormatter={NumberFormater}
          />
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          {/* <Legend /> */}
          {keys.map((key, index) => (
            <Bar
              key={key}
              barSize={25}
              xAxisId={0}
              dataKey={key}
              fill={`${key === selectedSecondGraph.id ? "#80BF54" : "#4DC0E2"}`}
              style={{ zIndex: key === selectedSecondGraph.id ? 99 : 1 }}
              radius={[3, 3, 3, 3]}
              reverseStackOrder={true}
              shape={
                <RectangleBar
                  dataKey={key}
                  dataLabel={key.replace(/_/g, " ")}
                  placement={`${
                    key === selectedSecondGraph.id
                      ? isSmScreen
                        ? "top"
                        : "right"
                      : isSmScreen
                      ? "bottom"
                      : "left"
                  }`}
                  data={data}
                />
              }
            >
              {data.map((entry, indexCell) => {
                return (
                  <Cell
                    key={`cell-${indexCell}`}
                    fill={
                      key === selectedSecondGraph.id ? "#80BF54" : "#4DC0E2"
                    }
                  />
                );
              })}
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default connect()(StackedBarChart);

class CustomizedDot extends React.Component {
  render() {
    const {
      cx,
      cy,
      fill,
      placement,
      dataKey,
      payload,
      dataLabel,
      index,
      data
    } = this.props;

    const pastValue = index === 0 ? "NA" : data[index - 1][dataKey];
    const presentValue = payload[dataKey];
    const percentage =
      pastValue === "NA"
        ? 0
        : (presentValue - pastValue) /
          (pastValue === 0 && presentValue > 0
            ? pastValue
            : pastValue === 0
            ? 0.0000000000001
            : pastValue);

    return (
      <Tippy
        delay={1000}
        hideDelay={1000}
        duration={800}
        animation="fade"
        theme="light"
        arrow={true}
        visible={true}
        placement={placement}
        content={
          <div className="font-inter w-36 flex flex-col rounded-xl p-2">
            <span className="font-bold text-xl text-my-gray-901">
              {new Intl.NumberFormat().format(presentValue)}
            </span>
            <span className="capitalize font-light text-xs text-my-gray-900 tracking-wider mt-1">
              {dataLabel}
            </span>
            <span
              className={classnames("self-end text-xxs mt-2", {
                "text-my-red-100": Math.sign(percentage) === -1,
                "text-my-green-101": Math.sign(percentage) === 1
              })}
            >
              {new Intl.NumberFormat("en-US", {
                style: "percent",
                minimumFractionDigits: 1,
                maximumFractionDigits: 1
              }).format(percentage)}
            </span>
          </div>
        }
      >
        <circle
          cx={cx}
          cy={cy}
          fill={fill}
          stroke="white"
          strokeWidth={2}
          r={5}
          cursor="pointer"
        >
          <span className="tooltiptext">Tooltip text</span>
        </circle>
      </Tippy>
    );
  }
}
