import useSWR from "swr";
import { getRequestDefault, postRequestDefault } from "../utils/api";

export function HandleGetRestorationSites() {
  const { data, error } = useSWR(`/api/restoration-site/`, getRequestDefault, {
    fallbackData: [],
  });

  return {
    data: data?.data,
    isLoading: !error && !data,
    isError: error,
  };
}

export function HandleGetRestorationSite(id) {
  const { data, error } = useSWR(
    `/api/restoration-site/${id}/`,
    getRequestDefault,
    {
      fallbackData: [],
    }
  );

  return {
    data: data?.data,
    isLoading: !error && !data,
    isError: error,
  };
}

export function HandleGetGraphDataRestorationSite({ flag, value, site_id }) {
  const { data, error, mutate } = useSWR(
    `/api/generate-graph/`,
    postRequestDefault({
      flag,
      value,
      site_id,
    }),
    {
      fallbackData: [],
    }
  );

  return {
    data: data?.data,
    flag: data?.data ? JSON.parse(data?.config?.data)?.flag : undefined,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}

export function HandleGetGraphSummaryRestorationSite({ flag, value, site_id }) {
  const { data, error, mutate } = useSWR(
    `/api/graph-point-data/`,
    postRequestDefault({
      flag,
      value,
      site_id,
    }),
    {
      fallbackData: [],
    }
  );

  return {
    data: data?.data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}

export function HandleGetRestorationSiteData(id) {
  const { data, error } = useSWR(
    id ? `/api/restoration-site-data/${id}/` : null,
    getRequestDefault,
    {
      fallbackData: [],
    }
  );

  return {
    data: data?.data,
    isLoading: !error && !data,
    isError: error,
  };
}
