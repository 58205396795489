import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef } from "react";
import { connect } from "react-redux";
import moment from "moment";

import { IoClose } from "react-icons/io5";

import {
  checkIsNumberAndHasDecimal,
  aroundDecimalValueToOneDecimal,
  numberWithCommas,
  isValidDate,
} from "../../../utils/helper";

function RestorationSiteModal({ open, closeModal, site }) {
  const cancelButtonRef = useRef();

  return (
    <>
      <Transition show={open} as={Fragment}>
        <Dialog
          as="div"
          className="font-inter bg-black bg-opacity-50 fixed inset-0 z-20 overflow-y-auto"
          initialFocus={cancelButtonRef}
          static
          open={open}
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay as="div" className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full sm:w-2/3 md:w-3/5 xl:w-6/12 2xl:w-5/12 transition transition-width ease-in duration-700 my-8 text-sm text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="z-10 h-0 absolute top-0 left-0 bg-transparent py-4">
                  <input
                    className="z-10 h-0 cursor-auto bg-my-gray-300 placeholder-my-gray-300 "
                    placeholder=""
                  />
                </div>
                <div className="relative flex justify-between items-center w-full h-20 rounded-tl-xl rounded-tr-xl bg-my-green-400 px-8">
                  <p className="text-my-gray-900 font-bold text-lg">
                    Restoration Site ID Details
                  </p>
                  <div>
                    <span
                      ref={cancelButtonRef}
                      onClick={closeModal}
                      className="cursor-pointer font-medium text-white hover:text-opacity-75"
                    >
                      <IoClose
                        size={25}
                        className="text-my-gray-400 hover:text-my-red-100"
                      />
                    </span>
                  </div>
                </div>
                <div className="w-full h-full px-16 my-8">
                  <div className="text-my-gray-900 mt-10">
                    <div className="grid grid-cols-12 gap-4 w-full text-my-gray-900">
                      {Object.keys(site)
                        .filter(
                          (key) =>
                            !["site_media", "site_tag", "tree_data"].includes(
                              key
                            )
                        )
                        .map((key, index) => (
                          <div
                            key={key}
                            className="col-span-12 md:col-span-6 flex flex-col justify-start text-xxs md:text-xs lg:text-sm"
                          >
                            <p className="capitalize font-medium text-my-gray-400 tracking-wider">
                              {`${key.replace(/_/g, " ")}:`}
                            </p>
                            <input
                              disabled
                              className="h-8 w-full text-my-gray-900 font-bold bg-transparent outline-none rounded-md"
                              value={
                                site[key]
                                  ? isNaN(site[key]) && isValidDate(site[key])
                                    ? moment(new Date(site[key])).format(
                                        "DD/MM/YYYY hh:mm A"
                                      )
                                    : checkIsNumberAndHasDecimal(site[key])
                                    ? numberWithCommas(
                                        aroundDecimalValueToOneDecimal(
                                          site[key]
                                        )
                                      )
                                    : !isNaN(site[key])
                                    ? numberWithCommas(site[key])
                                    : site[key]
                                  : "N/A"
                              }
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </div>

                <div className="relative flex justify-end items-center w-full h-20 rounded-bl-xl rounded-br-xl bg-my-green-400">
                  <div
                    onClick={closeModal}
                    className="cursor-pointer flex justify-center items-center w-42 lg:w-52 h-12 tracking-widest text-my-sky-blue-200 font-bold rounded-md border-2 border-my-sky-blue-200 bg-white my-4 mr-4"
                  >
                    <p className="uppercase mx-3">close</p>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default connect()(RestorationSiteModal);
