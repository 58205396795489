import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
// import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { withRouter } from "react-router-dom";

import { IoClose } from "react-icons/io5";

import { connect } from "react-redux";

import classnames from "classnames";

import {
  checkIsNumberAndHasDecimal,
  aroundDecimalValueToOneDecimal,
  aroundDecimalValueToCustomDecimal,
  numberWithCommas,
} from "../../../utils/helper";

const items = [
  {
    id: 1,
    value: "1.23mm",
    label: "Pieces Printed",
    percentage: "+1.5%",
  },
  {
    id: 2,
    value: "$19k",
    label: "Program Profit",
    percentage: "+3.5%",
  },
  {
    id: 3,
    value: "102,344",
    label: "Print Jobs",
    percentage: "+6.0%",
  },
  {
    id: 4,
    value: "120,113",
    label: "Trees Planted",
    percentage: "+5.5%",
  },
];

function ChartRightBar({
  graphSummaryKeys,
  graphSummaryData,
  isChartRightBarOpen,
  setIsChartRightBarOpen,
}) {
  return (
    <div
      className={classnames(
        "w-full flex flex-col justify-between gap-2 w-full transform ease-in-out transition-all duration-1000 bg-my-blue-100 md:bg-transparent"
      )}
    >
      <span
        onClick={() => setIsChartRightBarOpen(false)}
        className="z-10 cursor-pointer absolute md:hidden -top-3 -left-3 flex justify-center items-center font-medium text-white hover:text-opacity-75 bg-my-blue-100 rounded-full p-1"
      >
        <IoClose
          size={12}
          className="text-my-green-300 hover:text-my-red-100"
        />
      </span>
      {graphSummaryKeys.map((key) => (
        <div
          key={key}
          className="w-full h-full flex flex-col justify-between rounded-lg bg-my-gray-101 py-1 px-3"
        >
          <p className="font-bold text-sm md:text-base text-my-gray-900">
            {graphSummaryData[key]
              ? checkIsNumberAndHasDecimal(graphSummaryData[key])
                ? numberWithCommas(
                    aroundDecimalValueToCustomDecimal(graphSummaryData[key], 3)
                  )
                : !isNaN(graphSummaryData[key])
                ? numberWithCommas(graphSummaryData[key])
                : graphSummaryData[key]
              : graphSummaryData[key]}
          </p>
          <p className="self-start flex-grow whitespace-normal text-xxs capitalize text-my-gray-400 w-full">
            {key.replace(/_/g, " ")}
          </p>
          {/* <p className="self-end text-xxs text-my-green-101 w-max">{0}</p> */}
        </div>
      ))}
    </div>
  );
}

const mapStateToProps = ({ graphData }) => ({
  graphSummaryData: graphData["graphSummaryData"]
    ? graphData["graphSummaryData"]
    : [],
  graphSummaryKeys: graphData["graphSummaryData"]
    ? Object.keys(graphData["graphSummaryData"])
    : [],
});

export default withRouter(connect(mapStateToProps)(ChartRightBar));
