import { Fragment } from "react";
import { withRouter, NavLink, Route, Redirect } from "react-router-dom";

import { HandleGetRestorationSite } from "../../../actions/restorationSites";
import { useParams } from "react-router";
import { Spinner } from "../../common/Icons";
import MapOfProjectLocation from "./MapOfProjectLocation";
import Information from "./Information";
import SiteSummaryAndDescription from "./SiteSummaryAndDescription";
import MonthlyEscrowRelease from "./MonthlyEscrowRelease";
import Photos from "./Photos";
import Tags from "./Tags";
import Species from "./Species";
import Documents from "./documents/Documents";
import GlobalForestWatchMap from "./GlobalForestWatchMap";

function RestorationSite({ match }) {
  const { site_id } = useParams();
  const {
    data: site,
    isLoading: rsIsLoading,
    isError: rsIsError,
  } = HandleGetRestorationSite(Number(site_id));

  return (
    <div className="col-start-1 md:col-start-2 lg:col-start-3 col-end-17 row-start-3 row-end-24 grid grid-rows-24 gap-y-2 h-full bg-white rounded-t-xl">
      {!site || rsIsLoading ? (
        <Fragment>
          <div className="row-span-2 flex items-center w-full rounded-t-xl bg-my-green-400">
            <span className="font-bold text-xs md:text-sm lg:text-base lg:text-xl text-center text-my-gray-900 ml-4">
              Restoration Site
            </span>
          </div>
          <div className="w-full row-start-5 row-end-25 flex justify-center items-center">
            <Spinner />
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <Route
            exact
            path={`/home/restoration-sites/:site_id`}
            render={() => <Redirect to={`${match.url}/barChart`} />}
          />
          <div className="row-span-2 flex items-center w-full rounded-t-xl bg-my-green-400">
            <span className="font-bold text-xs md:text-sm lg:text-base lg:text-xl text-center text-my-gray-900 ml-4">
              <span className="cursor font-bold text-xl">{site?.name}</span>
              {" — "}
              <span className="font-normal text-sm">{site?.region} </span>
            </span>
          </div>
          <div className="row-span-2 w-full text-my-sky-blue-200 text-xs px-8 my-auto">
            <NavLink
              to="/home/restoration-sites"
              className="cursor underline underline-offset-1"
            >{`Restoration Sites`}</NavLink>
            <span>{` > ${site?.name}`}</span>
          </div>
          <div className="row-start-5 row-end-25 pr-4">
            <div className="h-full w-full grid grid-cols-2 grid-rows-1 auto-rows-fr gap-10 overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-my-gray-201 scrollbar-track-transparent px-8 pr-12">
              <div className="col-span-1 row-span-9">
                {site?.longitude && site?.latitude ? (
                  <MapOfProjectLocation site={site} />
                ) : (
                  <div className="flex justify-center items-center h-full">
                    <span className="font-bold text-3xl">
                      No Coordinates found
                    </span>
                  </div>
                )}
              </div>
              <div className="col-span-1 row-span-10">
                <Information site={site} />
              </div>
              <div className="col-span-1 row-span-4">
                <SiteSummaryAndDescription site={site} />
              </div>
              <div className="col-span-2 row-span-10">
                <MonthlyEscrowRelease />
              </div>
              <div className="col-span-1 row-span-5">
                <Photos site_media={site?.site_media} />
              </div>
              <div className="col-span-1 row-span-4">
                <Tags site_tag={site?.site_tag} />
              </div>
              <div className="col-span-1 row-span-5">
                <Species tree_data={site?.tree_data} />
              </div>
              <div className="col-span-1 row-span-5">
                <Documents />
              </div>
              <div className="col-span-1 row-span-2">
                <GlobalForestWatchMap />
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default withRouter(RestorationSite);
