import { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch } from "react-router-dom";
import moment from "moment";
import { useParams } from "react-router";

import ChartTopBar from "../../dashboard/chart/ChartTopBar";
import ChartRightBar from "../../dashboard/chart/ChartRightBar";
import StackedAreaChart from "../../dashboard/chart/StackedAreaChart";
import StackedBarChart from "../../dashboard/chart/StackedBarChart";

import Tippy from "@tippyjs/react";

import { HiOutlineClipboardList } from "react-icons/hi";

import { MONTHS } from "../../../utils/constants";

import {
  checkIsNumberAndHasDecimal,
  aroundDecimalValueToCustomDecimal,
  numberWithCommas,
} from "../../../utils/helper";

import {
  HandleGetGraphDataRestorationSite,
  HandleGetGraphSummaryRestorationSite,
  // handleGetFilteredGraphData,
  // handleGetGraphSummaryData,
} from "../../../actions/restorationSites";
import yearsToMonths from "date-fns/yearsToMonths/index.js";

import { Spinner } from "../../common/Icons";
import MonthlyEscrowReleaseBodyTable from "./MonthlyEscrowReleaseBodyTable";

const periods = [
  { id: "week", name: "Week", available: true },
  { id: "month", name: "Month", available: true },
  { id: "quarter", name: "Quarter", available: true },
  { id: "year", name: "Year", available: true },
];

const graphOptions = [
  { id: "print_orders", name: "Print Orders", available: true },
  { id: "pieces_printed", name: "Pieces Printed", available: true },
  {
    id: "print_jobs",
    name: "Print Jobs",
    available: true,
  },
  { id: "tree_planted", name: "Trees planted", available: true },
  { id: "tree_in_escrow", name: "Trees in Escrow", available: true },
  { id: "tree_released", name: "Trees Released from Escrow", available: true },
  { id: "MtCO2e_sequestered", name: "MtCO2e Sequestered", available: true },
  {
    id: "MtCO2e_to_be_sequestered",
    name: "MtCO2e to be Sequestered",
    available: true,
  },
  { id: "none", name: "None", available: true },
];

function MonthlyEscrowReleaseBody({
  dispatch,
  // graphData,
  match,
  // graphSummaryKeys,
  // graphSummaryData,
  fetchGraphData = false,
  isInitCall,
  setIsInitCall,
  selectedView,
}) {
  const { site_id } = useParams();

  const [isChartRightBarOpen, setIsChartRightBarOpen] = useState(false);
  const [selectedChart, setSelectedChart] = useState(0);
  const [selectedPeriod, setSelectedPeriod] = useState(
    periods[periods.length - 1]
  );
  const [selectedFirstGraph, setSelectedFirstGraph] = useState(graphOptions[0]);
  const [selectedSecondGraph, setSelectedSecondGraph] = useState(
    graphOptions[1]
  );
  const [data, setData] = useState([]);
  const [keys, setKeys] = useState([]);
  const [selectedDate, handleDateChange] = useState(new Date());
  const [selectedRangeDate, setSelectedRangeDate] = useState([null, null]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedFlag, setSelectedFlag] = useState(null);

  const [selectedFromYear, setSelectedFromYear] = useState(null);
  const [selectedFromMonth, setSelectedFromMonth] = useState(null);
  const [selectedFromDay, setSelectedFromDay] = useState(null);

  const [selectedToYear, setSelectedToYear] = useState(null);
  const [selectedToMonth, setSelectedToMonth] = useState(null);
  const [selectedToDay, setSelectedToDay] = useState(null);

  const [finalFlag, setFinalFlag] = useState("year");
  const [finalValue, setFinalValue] = useState(2021);
  const [graphSummaryKeys, setGraphSummaryKeys] = useState([]);

  const {
    data: graphData,
    flag: graphFlag,
    isLoading: graphDataIsLoading,
    isError: graphDataIsError,
    mutate: graphDataMutate,
  } = HandleGetGraphDataRestorationSite({
    flag: finalFlag,
    value: finalValue,
    site_id: Number(site_id),
  });

  const {
    data: graphSummaryData,
    isLoading: graphSummaryDataIsLoading,
    isError: graphSummaryDataIsError,
    mutate: graphSummaryMutate,
  } = HandleGetGraphSummaryRestorationSite({
    flag: finalFlag,
    value: finalValue,
    site_id: Number(site_id),
  });

  useEffect(() => {
    if (graphSummaryData) {
      setGraphSummaryKeys(Object.keys(graphSummaryData));
    }
  }, [graphSummaryData]);

  useEffect(() => {
    const dateObj = new Date(selectedDate);
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth();
    const dateFromObj = new Date(selectedRangeDate[0]);
    const dateToObj = new Date(selectedRangeDate[1]);
    const fromYear = dateFromObj.getFullYear();
    const fromMonth = dateFromObj.getMonth();
    const fromDay = dateFromObj.getDate();

    const toYear = dateToObj.getFullYear();
    const toMonth = dateToObj.getMonth();
    const toDay = dateToObj.getDate();

    const flag = selectedPeriod.id;
    let callEndpoint = false;
    let value = 0;
    console.log(year);
    if (
      flag === "year" &&
      !isNaN(year) &&
      (fetchGraphData || flag !== selectedFlag || year !== selectedYear)
    ) {
      setSelectedFlag(flag);
      setSelectedYear(year);
      value = year;
      callEndpoint = true;
    } else if (
      flag === "month" &&
      !isNaN(year) &&
      (fetchGraphData ||
        flag !== selectedFlag ||
        year !== selectedYear ||
        month !== selectedMonth)
    ) {
      setSelectedFlag(flag);
      setSelectedYear(year);
      setSelectedMonth(month);
      value = `${year}|${month + 1}`;
      callEndpoint = true;
    } else if (
      flag === "quarter" &&
      !isNaN(year) &&
      (fetchGraphData || flag !== selectedFlag || year !== selectedYear)
    ) {
      setSelectedFlag(flag);
      setSelectedYear(year);
      value = year;
      callEndpoint = true;
    } else if (
      flag === "week" &&
      selectedRangeDate[0] !== null &&
      selectedRangeDate[1] !== null &&
      (fetchGraphData ||
        flag !== selectedFlag ||
        fromYear !== selectedFromYear ||
        fromMonth !== selectedFromMonth ||
        fromDay !== selectedFromDay ||
        toYear !== selectedToYear ||
        toMonth !== selectedToMonth ||
        toDay !== selectedToDay)
    ) {
      setSelectedFlag(flag);
      setSelectedFromYear(fromYear);
      setSelectedFromMonth(fromMonth);
      setSelectedFromDay(fromDay);
      setSelectedToYear(toYear);
      setSelectedToMonth(toMonth);
      setSelectedToDay(toDay);
      value = `${fromYear}-${fromMonth + 1}-${fromDay}|${toYear}-${
        toMonth + 1
      }-${toDay}`;
      callEndpoint = true;
    }

    if (callEndpoint) {
      setFinalFlag(flag);
      setFinalValue(value);
    }
  }, [selectedDate, selectedRangeDate, selectedPeriod]);

  useEffect(() => {
    graphDataMutate();
    graphSummaryMutate();
  }, [finalFlag, finalValue]);

  useEffect(() => {
    if (graphData) {
      const dataArray = [];
      const keys = Object.keys(graphData ? graphData : []).filter(
        (key) => key === selectedFirstGraph.id || key === selectedSecondGraph.id
      );
      if (keys.length > 0) {
        setKeys(keys);
        Object.keys(graphData[keys[0]]).forEach((record) => {
          let dataObject = {};

          debugger

          dataObject["name"] =
            graphFlag === "year"
              ? MONTHS[Number(record) - 1].short
              : graphFlag === "quarter"
              ? `Q${record}`
              : graphFlag === "week"
              ? `W${record}`
              : record;
          keys.forEach((key) => {
            dataObject[key] = graphData[key][record];
          });
          dataArray.push({ ...dataObject });
          dataObject = {};
        });
      }
      setData(dataArray);
    }
  }, [graphData, selectedFirstGraph, selectedSecondGraph]);

  return (
    <div className="relative row-start-3 row-end-12 grid grid-cols-16 grid-rows-16 gap-y-1 gap-x-4 w-full px-2 md:px-3 lg:px-4 xl:px-8">
      <Tippy
        delay={200}
        hideDelay={200}
        animation="fade"
        theme="light"
        arrow={true}
        placement="bottom"
        trigger="click"
        content={
          <div className="flex flex-col justify-between gap-2 w-full bg-white">
            {graphSummaryKeys.map((key) => (
              <div
                key={key}
                className="w-full h-full flex flex-col justify-between rounded-lg bg-my-gray-101 py-1 px-3"
              >
                <p className="font-bold text-sm md:text-base text-my-gray-900">
                  {graphSummaryData && graphSummaryData[key]
                    ? checkIsNumberAndHasDecimal(graphSummaryData[key])
                      ? numberWithCommas(
                          aroundDecimalValueToCustomDecimal(
                            graphSummaryData[key],
                            3
                          )
                        )
                      : !isNaN(graphSummaryData[key])
                      ? numberWithCommas(graphSummaryData[key])
                      : graphSummaryData[key]
                    : ""}
                </p>
                <p className="self-start flex-grow whitespace-normal text-xxs capitalize text-my-gray-400 w-full">
                  {key.replace(/_/g, " ")}
                </p>
                {/* <p className="self-end text-xxs text-my-green-101 w-max">{0}</p> */}
              </div>
            ))}
          </div>
        }
      >
        <span className="z-10 cursor-pointer absolute md:hidden top-10 right-3.5 flex justify-center items-center font-medium text-white hover:text-opacity-75 border rounded-full p-1">
          <HiOutlineClipboardList
            size={20}
            className="text-my-green-300 hover:text-my-green-100"
          />
        </span>
      </Tippy>
      <div className="row-span-2 col-start-1 col-end-17 md:col-end-15">
        <ChartTopBar
          periods={periods}
          graphOptions={graphOptions}
          selectedChart={selectedChart}
          setSelectedChart={setSelectedChart}
          selectedPeriod={selectedPeriod}
          setSelectedPeriod={setSelectedPeriod}
          selectedFirstGraph={selectedFirstGraph}
          setSelectedFirstGraph={setSelectedFirstGraph}
          selectedSecondGraph={selectedSecondGraph}
          setSelectedSecondGraph={setSelectedSecondGraph}
          selectedDate={selectedDate}
          selectedRangeDate={selectedRangeDate}
          setSelectedRangeDate={setSelectedRangeDate}
          handleDateChange={handleDateChange}
          isChart={selectedView === "graph"}
        />
      </div>

      {selectedView === "table" ? (
        <div className="col-start-1 col-end-17 row-start-3 row-end-17 flex justify-center items-center">
          <MonthlyEscrowReleaseBodyTable data={data} keys={keys} />
        </div>
      ) : (
        <Fragment>
          <div className="md:col-span-2 md:row-start-1 md:row-end-17 hidden md:flex">
            <ChartRightBar
              isChartRightBarOpen={isChartRightBarOpen}
              setIsChartRightBarOpen={setIsChartRightBarOpen}
            />
          </div>

          <Switch>
            <Route
              path={`${match.path}/barChart`}
              render={(props) => (
                <StackedBarChart
                  {...props}
                  selectedFirstGraph={selectedFirstGraph}
                  selectedSecondGraph={selectedSecondGraph}
                  data={data}
                  keys={keys}
                />
              )}
            />
            <Route
              path={`${match.path}/areaChart`}
              render={(props) => (
                <StackedAreaChart
                  {...props}
                  selectedFirstGraph={selectedFirstGraph}
                  selectedSecondGraph={selectedSecondGraph}
                  data={data}
                  keys={keys}
                />
              )}
            />

            <Route
              path={`${match.path}/candleChart`}
              render={(props) => (
                // <StackedCandleChart2
                //   {...props}
                //   selectedFirstGraph={selectedFirstGraph}
                //   selectedSecondGraph={selectedSecondGraph}
                // />
                <div className="col-start-1 col-end-15 row-start-3 row-end-17 flex justify-center items-center">
                  <p className="font-bold tracking-widest text-xl">{`Coming Soon`}</p>
                </div>
              )}
            />
            <Route
              path="*"
              render={(props) => (
                <div className="capitalize col-start-1 col-end-15 row-start-3 row-end-17 flex justify-center items-center">
                  <p className="font-bold tracking-widest text-xl">
                    please select <span className="lowercase">a</span> chart
                    type to be presented here
                  </p>
                </div>
              )}
            ></Route>
          </Switch>
        </Fragment>
      )}

      {(!graphData ||
        graphDataIsLoading ||
        !graphSummaryData ||
        graphSummaryDataIsLoading) && (
        <div className="z-10 absolute top-0 bottom-0 right-2 left-2 flex justify-center items-center bg-black bg-opacity-25 rounded">
          <p className="text-2xl font-bold tracking-widest text-white">
            <Spinner />
          </p>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = ({ graphData }) => ({
  // graphData,
  // graphSummaryData: graphData["graphSummaryData"]
  //   ? graphData["graphSummaryData"]
  //   : [],
  // graphSummaryKeys: graphData["graphSummaryData"]
  //   ? Object.keys(graphData["graphSummaryData"])
  //   : [],
});

export default withRouter(connect(mapStateToProps)(MonthlyEscrowReleaseBody));
