import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { IoClose } from "react-icons/io5";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody
} from "@chakra-ui/react";

function CustomizeTableColumnsModal({
  open,
  closeModal,
  leftColumnItems = [],
  rightColumnItems = [],
  cb_handleApplyChanges,
  dispatch
}) {
  const cancelButtonRef = useRef();

  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    setItems(leftColumnItems);
    setSelected(rightColumnItems);
    // setItems(getItems(10));
    // setSelected(getItems(5, 10));
  }, [leftColumnItems, rightColumnItems]);

  const id2List = {
    droppable: "items",
    droppable2: "selected"
  };

  const getList = (id) => (id2List[id] === "items" ? items : selected);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        getList(source.droppableId),
        source.index,
        destination.index
      );

      if (source.droppableId === "droppable2") {
        setSelected(items);
      } else {
        setItems(items);
      }
    } else {
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );

      setSelected(result.droppable2);
      setItems(result.droppable);
    }
  };

  // fake data generator
  const getItems = (count, offset = 0) =>
    Array.from({ length: count }, (v, k) => k).map((k) => ({
      id: `item-${k + offset + 2}`,
      content: `item ${k + offset}`
    }));

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  /**
   * Moves an item from one list to another list.
   */
  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    // background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle
  });

  const getListStyle = (isDraggingOver) => ({
    // background: isDraggingOver ? "lightblue" : "white",
    // padding: grid
    // width: 250
  });

  const handleCloseModal = () => {
    closeModal();
  };

  const removeItem = (id) => {
    const item = items.filter((item) => item.id === id)[0];
    setSelected([...selected, item]);
    setItems(items.filter((item) => item.id !== id));
  };

  return (
    <Modal isCentered size="4xl" isOpen={open} onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent borderRadius="xl">
        <ModalHeader className="h-20 font-inter rounded-t-2xl bg-my-green-400">
          <div className="relative flex justify-between items-center w-full">
            <p className="text-my-gray-900 font-bold text-lg">
              Customize Table Columns
            </p>
            <div>
              <span
                ref={cancelButtonRef}
                onClick={handleCloseModal}
                className="cursor-pointer font-medium text-white text-xs hover:text-opacity-75"
              >
                <IoClose
                  size={25}
                  className="text-my-gray-400 hover:text-my-red-100"
                />
              </span>
            </div>
          </div>
        </ModalHeader>
        <ModalBody className="flex justify-between gap-x-4 text-sm font-inter rounded-xl mx-4 my-4">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  className={classnames(
                    "flex flex-col gap-4 w-full py-3 px-4 rounded-lg",
                    {
                      "bg-my-green-400": snapshot.isDraggingOver
                    }
                  )}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  <span className="font-bold text-my-gray-900">
                    Visible Columns
                  </span>
                  <div className="flex flex-col gap-2 w-full">
                    {items &&
                      items.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              className={classnames(
                                "flex justify-between items-center text-white rounded px-4 py-2",
                                {
                                  "bg-my-sky-blue-101 bg-opacity-50":
                                    snapshot.isDragging,
                                  "bg-gradient-to-b from-my-sky-blue-100 to-my-sky-blue-200":
                                    !snapshot.isDragging
                                }
                              )}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <span>{item.content}</span>
                              <span
                                onClick={() => removeItem(item.id)}
                                className="cursor-pointer flex justify-center items-center w-5 h-5"
                              >
                                <IoClose
                                  size={25}
                                  className="text-white hover:text-my-red-100"
                                />
                              </span>
                            </div>
                          )}
                        </Draggable>
                      ))}
                  </div>

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <div className="bg-my-gray-102 border-l"></div>
            <Droppable droppableId="droppable2">
              {(provided, snapshot) => (
                <div
                  className={classnames(
                    "flex flex-col gap-4 py-3 px-4 rounded-lg w-full",
                    {
                      "bg-my-green-400": snapshot.isDraggingOver
                    }
                  )}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  <span className="font-bold text-my-gray-900">
                    Hidden Columns
                  </span>
                  <div className="flex flex-col gap-2 w-full">
                    {selected &&
                      selected.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              className={classnames("rounded px-4 py-1", {
                                "text-white bg-my-sky-blue-101 bg-opacity-50":
                                  snapshot.isDragging,
                                "bg-my-gray-101": !snapshot.isDragging
                              })}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              {item.content}
                            </div>
                          )}
                        </Draggable>
                      ))}
                  </div>

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </ModalBody>

        <ModalFooter className="h-20 font-inter text-sm rounded-bl-xl rounded-br-xl bg-my-green-400">
          <div className="relative flex justify-end items-center">
            <div
              onClick={() => {
                closeModal();
                cb_handleApplyChanges({ items, selected });
              }}
              className="cursor-pointer flex justify-center items-center w-52 lg:w-62 h-12 tracking-widest text-white font-bold rounded-md bg-gradient-to-b from-my-sky-blue-100 to-my-sky-blue-200 my-4"
            >
              <p className="uppercase mx-3">apply changes</p>
            </div>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

const mapStateToProps = ({ authorizedUsers }) => ({
  authorizedUsers
});

export default connect(mapStateToProps)(CustomizeTableColumnsModal);
