import React, { Component } from "react";
import { connect } from "react-redux";
// import classnames from "classnames";
// import "../App.css";
import { Link } from "react-router-dom";
import { handleRegistration } from "../../actions/authedUser";
import logoImage from "../../assets/images/logo_white.svg";
import passwordOpenedEyeIcon from "../../assets/icons/signup/password_opened_eye.svg";
import passwordClosedEyeIcon from "../../assets/icons/signup/password_closed_eye.svg";

class Signup extends Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    isAccepted: false,
    loading: false,
    isShowPassword: false,
    isShowConfirmPassword: false
  };

  handleSignUpClick = () => {
    const { dispatch } = this.props;
    const { firstName, lastName, email, password, confirmPassword } =
      this.state;
    this.setState(() => ({
      loading: true
    }));
    dispatch(
      handleRegistration({
        first_name: firstName,
        last_name: lastName,
        email,
        password1: password,
        password2: confirmPassword
      })
    );
    this.setState(() => ({
      loading: false
    }));
  };

  handleChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    this.setState(() => ({
      [key]: value
    }));
  };

  handleShowHidePassword = () => {
    this.setState((currentState) => ({
      isShowPassword: !currentState.isShowPassword
    }));
  };

  handleShowHideConfirmPassword = () => {
    this.setState((currentState) => ({
      isShowConfirmPassword: !currentState.isShowConfirmPassword
    }));
  };

  render() {
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      isAccepted,
      isShowPassword,
      isShowConfirmPassword
    } = this.state;
    const { openPrivacyPolicyModal } = this.props;
    return (
      <div className="flex flex-col w-screen h-screen bg-my-blue-100 overflow-x-hidden">
        <span
          className="flex-grow inline-block h-max align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div className="flex justify-center items-center w-full h-50rem 2xl:h-42rem">
          <div className="flex flex-col h-full lg:flex-row min-1/4 w-4/5 md:w-2/3 xl:w-3/5 2xl:w-3/5 transition transition-width ease-in duration-700 border shadow-2xl rounded-xl bg-white">
            <div className="hidden lg:inline-block w-1/2 2xl:w-2/5 h-full rounded-br-xl lg:rounded-br-none lg:rounded-tl-xl rounded-bl-xl bg-auth1 bg-no-repeat bg-cover">
              <div className="relative flex flex-col justify-center items-center w-full h-full rounded-br-xl lg:rounded-br-none lg:rounded-tl-xl rounded-bl-xl bg-gradient-to-b from-my-blue-200-75 to-my-blue-300-75">
                <div className="w-3/5">
                  <p className="font-bold tracking-widest text-white text-4xl mb-4">
                    Did you know?
                  </p>
                  <p className="font-extralight text-my-gray-200">
                    Almost half of all temperate grasslands and 16 percent of
                    tropical grasslands have been converted to agricultural or
                    industrial uses and only one percent of the original
                    tallgrass prairie exists today.
                  </p>
                </div>
                <div className="absolute bottom-10 flex flex-col items-center text-white text-xs">
                  <img className="mb-2" src={logoImage} alt="logo" />
                  <p>Powered by</p>
                  <p className="font-bold">Reduce. Reuse. Grow. Inc.</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col xl:w-full 2xl:w-3/5 justify-center items-center py-6">
              <div className="flex flex-col justify-center items-center w-4/5 xl:w-3/5 2xl:w-3/5 text-xs text-my-gray-100">
                <p className="font-bold tracking-widest text-black text-4xl mb-16">
                  Sign Up
                </p>
                <div className="flex flex-col 2xl:flex-row gap-4 2xl:justify-between 2xl:gap-0 w-full">
                  <div className="flex flex-col justify-start w-full 2xl:mr-2">
                    <p className="uppercase font-medium tracking-wider">
                      first name
                    </p>
                    <input
                      className="h-10 w-full outline-none text-my-gray-901 border border-my-gray-100 rounded-md px-4 mt-1"
                      name="firstName"
                      value={firstName}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="flex flex-col justify-start w-full">
                    <p className="uppercase font-medium tracking-wider">
                      last name
                    </p>
                    <input
                      className="h-10 w-full outline-none text-my-gray-901 border border-my-gray-100 rounded-md px-4 mt-1"
                      name="lastName"
                      value={lastName}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div className="flex flex-col justify-start w-full mt-4">
                  <p className="uppercase font-medium tracking-wider">email</p>
                  <input
                    className="h-10 outline-none text-my-gray-901 border border-my-gray-100 rounded-md px-4 mt-1"
                    name="email"
                    type="email"
                    value={email}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="flex flex-col justify-start w-full mt-4">
                  <p className="uppercase font-medium tracking-wider">
                    password
                  </p>
                  <div className="relative w-full">
                    <input
                      className="h-10 w-full outline-none text-my-gray-901 border border-my-gray-100 rounded-md px-4 mt-1"
                      name="password"
                      value={password}
                      onChange={this.handleChange}
                      type={isShowPassword ? "text" : "password"}
                    />
                    <img
                      onClick={this.handleShowHidePassword}
                      className="cursor-pointer absolute top-3 right-4 mb-2"
                      src={
                        isShowPassword
                          ? passwordOpenedEyeIcon
                          : passwordClosedEyeIcon
                      }
                      alt="passwordEye"
                    />
                  </div>
                </div>
                <div className="flex flex-col justify-start w-full mt-4">
                  <p className="uppercase font-medium tracking-wider">
                    confirm password
                  </p>
                  <div className="relative w-full">
                    <input
                      className="h-10 w-full outline-none text-my-gray-901 border border-my-gray-100 rounded-md px-4 mt-1"
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={this.handleChange}
                      type={isShowConfirmPassword ? "text" : "password"}
                    />
                    <img
                      onClick={this.handleShowHideConfirmPassword}
                      className="cursor-pointer absolute top-3 right-4 mb-2"
                      src={
                        isShowConfirmPassword
                          ? passwordOpenedEyeIcon
                          : passwordClosedEyeIcon
                      }
                      alt="passwordEye"
                    />
                  </div>
                </div>
                <div className="flex justify-start w-full my-4">
                  <input
                    className="outline-none border border-my-gray-100 mr-2"
                    type="checkbox"
                    name="isAccepted"
                    value={isAccepted}
                    onChange={this.handleChange}
                  />

                  <span>
                    I have read and agree to the{" "}
                    <span
                      onClick={openPrivacyPolicyModal}
                      className="cursor-pointer text-my-sky-blue-101 ml-1"
                    >
                      Terms of Service
                    </span>{" "}
                    as well as{" "}
                    <span
                      onClick={openPrivacyPolicyModal}
                      className="cursor-pointer text-my-sky-blue-101 ml-1"
                    >
                      Privacy Policy
                    </span>
                  </span>
                </div>
                <div
                  onClick={this.handleSignUpClick}
                  className="cursor-pointer flex justify-center items-center w-full h-12 tracking-widest text-white font-bold rounded-md bg-gradient-to-b from-my-sky-blue-100 to-my-sky-blue-200 mt-4"
                >
                  <p className="uppercase mx-3">sign up</p>
                </div>
                <div className="flex items-center w-full my-8">
                  <div className="w-full border-t"></div>
                </div>
                <div>
                  <span>
                    Already have an account?{" "}
                    <Link
                      to="/signin"
                      className="cursor-pointer text-my-sky-blue-101 ml-1"
                    >
                      Sign In
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span
          className="flex-grow inline-block h-max align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>
      </div>
    );
  }
}

export default connect()(Signup);
