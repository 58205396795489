function SiteSummaryAndDescription({ site }) {
  return (
    <div className="w-full h-full">
      <div className="font-montserrat font-bold text-xl">
        Site Summary and Description
      </div>
      <div className="flex items-center w-full my-4">
        <div className="w-full border-t border-my-gray-102"></div>
      </div>
      <div className="text-sm">{site?.description}</div>
    </div>
  );
}

export default SiteSummaryAndDescription;
