import { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classnames from "classnames";
import SummaryCard from "./SummaryCard";
import rightArrowIcon from "../../../assets/icons/dashboard/summaryBar/rightArrow.svg";
import leftArrowIcon from "../../../assets/icons/dashboard/summaryBar/leftArrow.svg";

import { handleGetTotalImpactData } from "../../../actions/dashboard";

import useWindowDimensions from "../../main/useWindowDimensions";

const items = [
  {
    id: 1,
    value: "102,344",
    label: "Trees Committed to Date",
  },
  {
    id: 2,
    value: "4.92k",
    label: "MT of Carbon to be Sequestered",
  },
  {
    id: 3,
    value: "95,412",
    label: "Trees Released from Escrow",
  },
  {
    id: 4,
    value: "185,203",
    label: "Trees in Escrow",
  },
  {
    id: 5,
    value: "83,214",
    label: "Trees Planted",
  },
  {
    id: 6,
    value: "5.95k",
    label: "MT of Carbon Sequestered to Date",
  },
  {
    id: 7,
    value: "960",
    label: "Work Hours Created",
  },
  {
    id: 8,
    value: "270",
    label: "Employees Employed",
  },
];

function SummaryBar({ dashboard, dispatch, keepFetchingData }) {
  const { width, isSmScreen } = useWindowDimensions();
  const scrollBar = useRef(null);

  const [isAtBegin, setIsAtBegin] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(true);

  useEffect(() => {
    onScroll(scrollBar.current);
    dispatch(handleGetTotalImpactData());
  }, []);

  useEffect(() => {
    if (keepFetchingData) {
      onScroll(scrollBar.current);
      dispatch(handleGetTotalImpactData(false));
    }
  }, [keepFetchingData]);

  useEffect(() => {
    onScroll(scrollBar.current);
  }, [width, dashboard]);

  const onScroll = (e) => {
    if (e?.scrollLeft <= 0) {
      setIsAtBegin(true);
    } else {
      setIsAtBegin(false);
    }
    if (e?.scrollWidth - e?.clientWidth <= e?.scrollLeft) {
      setIsAtEnd(true);
    } else {
      setIsAtEnd(false);
    }
  };

  const scrollTo = (value) => {
    if (value > 0) {
      scrollBar.current.scrollLeft =
        scrollBar.current?.scrollWidth - scrollBar.current?.clientWidth;
    } else {
      scrollBar.current.scrollLeft = 0;
    }
  };

  return (
    <div className="row-span-2 flex items-center grid grid-cols-16 gap-x-1 md:gap-x-2 w-full rounded-t-xl bg-my-green-400 px-1">
      <span className="col-span-2 font-bold text-xs md:text-sm lg:text-base lg:text-xl text-center text-my-gray-900">
        Total Impact
      </span>
      <div className="col-start-3 col-end-17 flex md:py-0.5">
        <div
          onMouseDown={() => {
            if (!isAtBegin) {
              scrollTo(-40);
            }
          }}
          className={classnames(
            "self-stretch flex justify-center items-center w-5 md:w-7 text-black rounded-l",
            {
              "cursor-pointer bg-gradient-to-l from-my-green-101 to-my-green-102":
                !isAtBegin,
              "cursor-default bg-my-gray-202": isAtBegin,
            }
          )}
        >
          <img
            style={{
              height: isSmScreen ? 10 : 13,
              width: isSmScreen ? 10 : 17,
            }}
            src={leftArrowIcon}
            alt="Left Arrow"
          />
        </div>
        <div
          ref={scrollBar}
          onScroll={(e) => onScroll(e.target)}
          className="flex gap-2 flex-nowrap scroller overflow-x-scroll hide-scroll-bar h-full w-full"
        >
          {(dashboard?.totalImpact
            ? Object.keys(dashboard?.totalImpact).filter(
                (key) =>
                  key !== "user_id" && key !== "id" && key !== "client_name"
              )
            : []
          ).map((key, index) => (
            <SummaryCard
              value={dashboard?.totalImpact[key]}
              label={key.replace(/_/g, " ")}
              key={key}
              isFirst={index === 0}
              isLast={index === items.length - 1}
            />
          ))}
        </div>

        <div
          onMouseDown={() => {
            if (!isAtEnd) {
              scrollTo(40);
            }
          }}
          className={classnames(
            "self-stretch flex justify-center items-center w-5 md:w-7 text-black rounded-tr rounded-br",
            {
              "cursor-pointer bg-gradient-to-l from-my-green-101 to-my-green-102":
                !isAtEnd,
              "cursor-default bg-my-gray-202": isAtEnd,
            }
          )}
        >
          <img
            style={{
              height: isSmScreen ? 10 : 13,
              width: isSmScreen ? 10 : 17,
            }}
            src={rightArrowIcon}
            alt="Right Arrow"
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ authedUser, dashboard }) => ({
  authedUser,
  dashboard,
});

export default withRouter(connect(mapStateToProps)(SummaryBar));
