import RsSmall from "../RsSmall/RsSmall";
import { Spinner } from "../../common/Icons";

function MapView({ rsData, rsIsLoading }) {
  return (
    <div className="h-full pr-4">
      <div className="h-full overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-my-gray-201 scrollbar-track-transparent px-8">
        {!rsData || rsIsLoading ? (
          <div className="w-full h-full flex justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className="grid grid-cols-2 lg:grid-cols-3 grid-flow-row auto-rows-max gap-2">
            {rsData?.results?.map((site) => (
              //   <div key={site?.id} className="col-span-1 row-span-1">
              <RsSmall key={site?.id} site={site} />
              //   </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default MapView;
