// Import Authorized users actions
import { ADD_USERS, ADD_USER } from "../actions/authorizedUsers";

// Authorized users reducer
export default function authorizedUsers(state = [], action) {
  switch (action.type) {
    case ADD_USERS:
      return action.authorizedUsers;
    case ADD_USER:
      return state.concat([action.authorizedUser]);
    default:
      return state;
  }
}
