import { Fragment, useState, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { connect } from "react-redux";

import logoImage from "../../assets/images/logo_black.svg";
import arrowDownIcon from "../../assets/icons/dashboard/table/sortArrowDown.svg";
import arrowUpIcon from "../../assets/icons/dashboard/table/sortArrowUp.svg";

import { UNKNOWN_USER_PHOTO } from "../../utils/constants";

import Tippy from "@tippyjs/react";

import AuthorizedList from "../admin/AuthorizedList";

import { handleFetchAllAuthorizedUsers } from "../../actions/authorizedUsers";
import { getImageUrlOfAwsToQuestionMark } from "../../utils/helper";

function PanelTopBar({
  openProfileModal,
  openFeedbackModal,
  openChangePasswordModal,
  openSignoutModal,
  openAuthorizedUsersModal,
  openReleaseTreesFromEscrowModal,
  authedUser,
  authorizedUsers,
  dispatch,
}) {
  const options = [
    {
      id: 1,
      name: "Authorized Users",
      hasTooltip: true,
      onClick: () => {},
    },
    {
      id: 2,
      name: "Release Tree",
      hasTooltip: false,
      onClick: openReleaseTreesFromEscrowModal,
    },
    { id: 3, name: "Feedback", hasTooltip: false, onClick: openFeedbackModal },
    {
      id: 4,
      name: "Change Password",
      hasTooltip: false,
      onClick: openChangePasswordModal,
    },
    { id: 5, name: "Signout", hasTooltip: false, onClick: openSignoutModal },
  ];

  const [selectedOption, setSelectedOption] = useState(options[0]);

  const [loading, setLoading] = useState(false);
  const [isListOpen, setIsListOpen] = useState(false);
  const [userName, setUserName] = useState(null);
  const [userImage, setUserImage] = useState(null);

  useEffect(() => {
    const { first_name, profile_image, social_login_image, isGoogle } =
      authedUser;
    const userName = first_name;
    const userImage = isGoogle
      ? social_login_image
        ? social_login_image
        : UNKNOWN_USER_PHOTO
      : profile_image
      ? getImageUrlOfAwsToQuestionMark(profile_image)
      : UNKNOWN_USER_PHOTO;

    setUserName(userName);
    setUserImage(userImage);
  }, [authedUser]);

  useEffect(() => {
    // if (isListOpen) {
    setLoading(true);
    dispatch(
      handleFetchAllAuthorizedUsers(handleReceiveAuthorizedUsersSuccess)
    );
    // }
  }, []);

  const handleReceiveAuthorizedUsersSuccess = () => {
    setLoading(false);
  };

  return (
    <div className="col-start-1 col-end-17 row-span-2 flex justify-between items-center px-8">
      <div className="flex items-center">
        <img
          onClick={openProfileModal}
          className="cursor-pointer w-10 h-10 rounded-full shadow"
          src={userImage}
          alt="User avatar"
        />
        <div className="flex flex-col justify-center text-xs ml-6">
          <p className="font-bold text-sm md:text-base">{`Hi ${userName}!`}</p>
          <span className="hidden md:block text-my-gray-300">
            Here's a bird's-eye view of what's going on in the{" "}
            <span className="font-bold tracking-wide text-my-green-101">
              Restoration Dashboard
            </span>
          </span>
        </div>

        <div className="cursor-pointer h-full flex items-center text-xs rounded">
          <Listbox
            value={selectedOption}
            onChange={(value) => {
              setIsListOpen(!isListOpen);
              setSelectedOption(value);
            }}
          >
            {({ open }) => (
              <div className="fixed relative">
                <Listbox.Button
                  onClick={() => setIsListOpen(!isListOpen)}
                  className="relative w-0 pl-3 pr-10 text-left rounded-lg cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500"
                >
                  <span className="invisible block truncate text-center">
                    {selectedOption.name}
                  </span>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    {open ? (
                      <img src={arrowUpIcon} alt="arrow Up" />
                    ) : (
                      <img src={arrowDownIcon} alt="arrow Down" />
                    )}
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="z-30 absolute w-max overflow-auto text-center bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none py-2 px-1">
                    {options.map((option, index) => (
                      <Listbox.Option
                        key={option.id}
                        // className={({ active }) =>
                        //   `${
                        //     active
                        //       ? "bg-my-gray-101 text-amber-900 bg-amber-100"
                        //       : "text-gray-900"
                        //   }
                        //   cursor-pointer select-none relative`
                        // }
                        value={option}
                      >
                        {({ selected, active }) => (
                          <>
                            {option.hasTooltip ? (
                              <Tippy
                                delay={200}
                                hideDelay={200}
                                animation="fade"
                                theme="light"
                                arrow={true}
                                placement="right"
                                content={
                                  <AuthorizedList
                                    loading={loading}
                                    authorizedUsers={authorizedUsers}
                                  />
                                }
                              >
                                <span
                                  onClick={option.onClick}
                                  className={`${
                                    active
                                      ? "bg-my-gray-101 text-amber-900 bg-amber-100"
                                      : "text-gray-900"
                                  }${
                                    selected ? "font-bold" : "font-normal"
                                  } block truncate text-center mx-2 px-1 py-2 rounded`}
                                >
                                  {option.name}
                                </span>
                              </Tippy>
                            ) : (
                              <span
                                onClick={option.onClick}
                                className={`${
                                  active
                                    ? "bg-my-gray-101 text-amber-900 bg-amber-100"
                                    : "text-gray-900"
                                }${
                                  selected ? "font-bold" : "font-normal"
                                } block truncate text-center mx-2 px-1 py-2 rounded`}
                              >
                                {option.name}
                              </span>
                            )}

                            {index !== options.length - 1 && (
                              <div className="flex items-center w-full px-2">
                                <div className="w-full border-t"></div>
                              </div>
                            )}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            )}
          </Listbox>
        </div>
      </div>
      <div className="hidden md:block">
        <div className="flex flex-col items-center text-black text-xxs">
          <img className="w-10 h-10" src={logoImage} alt="logo" />
          <p>Powered by</p>
          <p className="font-bold">Reduce. Reuse. Grow. Inc.</p>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ authedUser, authorizedUsers, shared }) => ({
  authedUser,
  authorizedUsers,
  shared,
});

export default connect(mapStateToProps)(PanelTopBar);
