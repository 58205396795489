import { showLoading, hideLoading } from "react-redux-loading";
import {
  uploadCsvRequest,
  getRestorationDataRequest,
  downloadRecordOfRestorationDataBlobRequest,
} from "../utils/api";
import { setErrorMessage } from "./shared";
import { handleGetUploadingStatusOfCsv } from "./dashboard";
import { downloadFromBlob } from "../utils/helper";

// Actions declaration
export const GET_RESTORATION_DATA = "GET_RESTORATION_DATA";

// Action functions for updating the store
export function getRestorationData(restorationData) {
  return {
    type: GET_RESTORATION_DATA,
    restorationData,
  };
}

// Handling upload csv file request
export function handleUploadCsvFile(csvFile, cb_handleUploadCsvSuccess) {
  return (dispatch) => {
    dispatch(showLoading());
    uploadCsvRequest(csvFile)
      .then((res) => {
        cb_handleUploadCsvSuccess();
        dispatch(handleGetUploadingStatusOfCsv());
      })
      .then(() =>
        dispatch(
          setErrorMessage({
            message: "CSV File Uploaded Successfully",
            type: "info",
          })
        )
      )
      .catch((err) => {
        if (err.code === "ECONNABORTED") {
          cb_handleUploadCsvSuccess();
          dispatch(handleGetUploadingStatusOfCsv());
        } else {
          let msg = "Failed to Upload CSV File";
          if (err && err.response && err.response.data) {
            msg = JSON.stringify(err.response.data).replace(/"/g, "");
          }
          dispatch(
            setErrorMessage({
              message: msg,
              type: "error",
            })
          );
        }
      })
      .then(() =>
        setTimeout(() => {
          dispatch(setErrorMessage({}));
        }, 3000)
      )
      .then(() => dispatch(hideLoading()));
  };
}

// Handling fetch restoration data request
export function handleGetRestorationData(parameters, showMessage = true) {
  return (dispatch) => {
    dispatch(showLoading());
    getRestorationDataRequest(parameters)
      .then((response) => {
        dispatch(
          getRestorationData({ ...response.data, current: response.config.url })
        );
      })
      .then(() => {
        if (showMessage) {
          dispatch(
            setErrorMessage({
              message: "Restoration Data Fetched Successfully",
              type: "info",
            })
          );
        }
      })
      .catch((err) => {
        let msg = "Failed to Fetch Restoration Data";
        if (err && err.response && err.response.data) {
          msg = JSON.stringify(err.response.data).replace(/"/g, "");
        }
        dispatch(
          setErrorMessage({
            message: msg,
            type: "error",
          })
        );
      })
      .then(() =>
        setTimeout(() => {
          dispatch(setErrorMessage({}));
        }, 3000)
      )
      .then(() => dispatch(hideLoading()));
  };
}

// Handling download restoration data record report doc blob request
export function handleDownloadRestorationDataRecordReport(id, userId) {
  return (dispatch, getState) => {
    dispatch(showLoading());

    const { adminPanel } = getState();
    const u_id = userId
      ? userId
      : adminPanel?.isImpersonating
      ? adminPanel?.client?.id
      : null;

    downloadRecordOfRestorationDataBlobRequest(id, u_id)
      .then((res) => {
        downloadFromBlob(
          res.data,
          `Restoration Data Report (Record_${id}).csv`
        );
      })
      .then(() =>
        dispatch(
          setErrorMessage({
            message: "Restoration Data Report Downloaded Successfully",
            type: "info",
          })
        )
      )
      .catch((err) => {
        let msg = `Failed To Download Restoration Data Report (Record_${id})`;
        if (err && err.response && err.response.data) {
          msg = JSON.stringify(err.response.data).replace(/"/g, "");
        }
        dispatch(
          setErrorMessage({
            message: msg,
            type: "error",
          })
        );
      })
      .then(() =>
        setTimeout(() => {
          dispatch(setErrorMessage({}));
        }, 3000)
      )
      .then(() => dispatch(hideLoading()));
  };
}
