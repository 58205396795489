import axios from "axios";

// Call signin API
export async function signinRequest(user) {
  // Post request to signin URL
  return await axios.post(
    "/rest-auth/login/",

    { ...user }
  );
}

// Call logout API
export async function logoutRequest() {
  // Post request to logout URL
  return await axios.post("/rest-auth/logout/");
}

// Call Google signin API
export async function googleSigninRequest(access_token) {
  // Post request to Google signin URL
  return await axios.post(
    "/auth/google",

    { access_token }
  );
}

// Call signup API
export async function signupRequest(user) {
  // Post request to signup URL
  return await axios.post(
    "/rest-auth/registration/", // /api/v1/signup/ /rest-auth/registration/

    { ...user }
  );
}

// Call signout API
export async function signoutRequest() {
  // Post request to signout URL
  return await axios.post("/rest-auth/logout/");
}

// Call forgotpassword API
export async function forgotPasswordRequest(email) {
  // Post request to forgot password URL
  const res = await axios.post("/rest-auth/password/reset/", { email });

  return res;
}

// Call user info API
export async function userInfoRequest(key) {
  // GET request to user info URL
  return await axios.post("/api/profile/", { key });
}

// Call user info API
export async function saveUserInfoRequest({
  key,
  id,
  address,
  company_info,
  email,
  first_name,
  last_name,
  phone_number,
  profile_image,
  social_login_image,
  company,
}) {
  var formData = new FormData();

  formData.append("key", key);
  formData.append("u_id", id);
  formData.append("address", address);
  formData.append("company_info", company_info);
  // formData.append("email", email);
  formData.append("first_name", first_name);
  formData.append("last_name", last_name);
  formData.append("phone_number", phone_number);
  // formData.append("company", company);
  if (profile_image !== null) {
    formData.append("profile_image", profile_image);
    // formData.append("social_login_image", social_login_image);
  }

  // PATCH request to user info URL
  let result = await axios.put("/api/profile/", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return result;
}

// Call feedback API
export async function sendFeedbackRequest(text) {
  // Post request to feedback URL

  return await axios.post("/api/feedback/", { text });
}

// Call change password API
export async function changePasswordRequest({
  currentPassword,
  newPassword,
  confirmPassword,
}) {
  // Post request to change password URL

  return await axios.post("/rest-auth/password/change/", {
    new_password1: newPassword,
    new_password2: confirmPassword,
    old_password: currentPassword,
  });
}

// Call reset password API
export async function resetPasswordRequest(email) {
  // Post request to reset password URL

  return await axios.post("/rest-auth/password/reset/", {
    email,
  });
}

// Call regestration API
export async function registrationRequest({
  first_name,
  last_name,
  email,
  password1,
  password2,
}) {
  // Post request to regestration URL

  return await axios.post("/rest-auth/registration/", {
    first_name,
    last_name,
    email,
    password1,
    password2,
  });
}

// Call authorized users API
export async function getAuthorizedUsersRequest() {
  // GET request to get authorized users URL
  return await axios.get("/api/authorized-user/");
}

// Call authorized users API
export async function addAuthorizedUserRequest({ email, company_id }) {
  return await axios.post("/api/add-users/", { email, company_id });
}

// Call csv API
export async function uploadCsvRequest(csv_file) {
  // Post request to upload csv URL
  var formData = new FormData();

  formData.append("csv_file", csv_file);

  return await axios.post("/api/upload-csv/", formData, {
    timeout: 10000,
    timeoutErrorMessage: "timeoutReached",
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function uploadCsvRequest_old() {
  // Post request to upload csv URL

  return await axios.post("http://localhost:8080/api/upload-csv/");
}

export async function getCsvUploadStatus() {
  // GET request to fetch uploading status
  return await axios.get("/api/csv-file-data/?limit=200&offset=0");
}

// Call restoration data API
export async function getRestorationDataRequest({
  limit,
  offset,
  ordering,
  search,
  tableFilter,
}) {
  // GET request to get restoration data URL
  return await axios.get(
    `/api/restoration-data/?limit=${limit}&offset=${offset}&ordering=${ordering}${
      search && search !== "" ? `&search=${search}` : ""
    }${tableFilter && tableFilter !== "" ? `&${tableFilter}` : ""}`
  );
}

// Call restoration data per user API
export async function getRestorationDataPerUserRequest({
  limit,
  offset,
  ordering,
  search,
  userId,
  tableFilter,
}) {
  // GET request to get restoration data per user URL
  return await axios.get(
    `/api/user-restoration-data/${userId}?limit=${limit}&offset=${offset}&ordering=${ordering}${
      search && search !== "" ? `&search=${search}` : ""
    }${tableFilter && tableFilter !== "" ? `&${tableFilter}` : ""}`
  );
}

export async function downloadRecordOfRestorationDataBlobRequest(id, u_id) {
  // GET request to download restoration data record report URL
  return await axios.get(
    `/api/restoration-data-download/${id}/${u_id ? `?u_id=${u_id}` : ""}`,
    {
      responseType: "blob",
    }
  );
}

// Call csrf token API
export async function getCsrfTokenRequest() {
  // GET request to get csrf token URL
  return await axios.get("/api/csrf/");
}

// Call graph data API
export async function getGraphDataRequest(userId) {
  // GET request to get graph data URL
  return await axios.get(`/api/generate-graph/?u_id=${userId}`);
}

export async function getGraphSummaryDataRequest({ flag, value, userId }) {
  let formData = new FormData();
  formData.append("flag", flag);
  formData.append("value", value);
  if (userId) {
    formData.append("u_id", userId);
  }

  // GET request to get graph summary data URL
  return await axios.post(`/api/graph-point-data/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function getFilteredGraphDataRequest({ flag, value, u_id }) {
  // POST request to get graph data URL
  return await axios.post(
    "/api/generate-graph/",
    {
      flag,
      value,
      u_id,
    },
    { timeout: 1000000 }
  );
}

// Call users in admin panel API
export async function getUsersInAdminPanelRequest(companyId) {
  // GET request to get users in admin panel URL
  return await axios.get(`/api/admin-users/${companyId}/`);
}

// Call all companies API
export async function getAllCompaniesRequest() {
  // GET request to get all companies URL
  return await axios.get("/api/company/");
}

// Call all admin companies API
export async function getAllAdminCompaniesRequest() {
  // GET request to get all admin companies URL
  return await axios.get("/api/admin-company/");
}

export async function downloadCompaniesReportBlobRequest() {
  // GET request to download companies report URL
  return await axios.get("/api/admin-company/?download=true", {
    responseType: "blob",
  });
}

// Call all users API
export async function getAllUsersRequest() {
  // GET request to get all users URL
  return await axios.get("/api/users/");
}

// Call single user API
export async function getUserRequest(userId) {
  // GET request to get user URL
  return await axios.get(`/api/users/${userId}/`);
}

// Call total impact data API
export async function getTotalImpactDataRequest(userId) {
  // GET request to get total impact data URL
  return await axios.get(`/api/total-impact/?u_id=${userId}`);
}

// Call create user profile API
export async function createUserProfileRequest({
  email,
  first_name,
  last_name,
  company_id,
}) {
  return await axios.post("/api/create-user/", {
    email,
    first_name,
    last_name,
    company_id,
  });
}

// Call create company profile API
export async function createCompanyProfileRequest({
  name,
  company_info,
  company_image,
  max_employees,
  address,
  company_id,
}) {
  var formData = new FormData();

  formData.append("name", name);
  formData.append("company_info", company_info);
  formData.append("address", address);
  formData.append("max_employees", max_employees);
  formData.append("company_id", company_id);
  if (company_image !== null) {
    formData.append("company_image", company_image);
  }

  return await axios.post("/api/company/", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

// Call all restoration sites API
export async function getAllRestorationSitesRequest() {
  // GET request to get all restoration sites URL
  return await axios.get("/api/restoration-site/");
}

// Call all trees API
export async function getAllTreesRequest() {
  // GET request to get all trees URL
  return await axios.get("/api/tree/");
}

// Call all trees sites API
export async function getAllTreesSitesRequest() {
  // GET request to get all trees sites URL
  return await axios.get("/api/tree-site/");
}

// Call release trees from escrow API
export async function releaseTreesFromEscrowRequest({ site, date, amount }) {
  // POST request to release trees from escrow URL
  return await axios.post(
    "/api/release-from-escrow/",
    { site, date, amount },
    { timeout: 10000, timeoutErrorMessage: "timeoutReached" }
  );
}

export function getRequestDefault(url) {
  return axios.get(url);
}

export const postRequestDefault = (params) => (url) => axios.post(url, params);
