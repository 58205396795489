import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef, useState, useEffect } from "react";
import { connect } from "react-redux";

import { IoClose } from "react-icons/io5";

import {
  handleAddAuthorizedUser,
  handleFetchAllAuthorizedUsers,
} from "../../actions/authorizedUsers";

import AuthorizedList from "./AuthorizedList";

function AuthorizedUsersModal({ open, closeModal, authorizedUsers, dispatch }) {
  const cancelButtonRef = useRef();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(true);
  const [addingUser, setAddingUser] = useState(false);

  useEffect(() => {
    setEmail("");
    dispatch(
      handleFetchAllAuthorizedUsers(handleReceiveAuthorizedUsersSuccess)
    );
  }, []);

  const handleCloseModal = () => {
    closeModal();
  };

  const handleClickAuthorize = () => {
    setAddingUser(true);
    dispatch(handleAddAuthorizedUser(email, handleAddAuthorizedUsersSuccess));
  };

  const handleReceiveAuthorizedUsersSuccess = () => {
    setLoading(false);
  };

  const handleAddAuthorizedUsersSuccess = () => {
    setAddingUser(false);
  };

  return (
    <>
      <Transition show={open} as={Fragment}>
        <Dialog
          as="div"
          className="text-sm font-inter bg-black bg-opacity-50 fixed inset-0 z-20 overflow-y-auto"
          initialFocus={cancelButtonRef}
          static
          open={open}
          onClose={handleCloseModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full sm:w-2/3 md:w-3/5 xl:w-6/12 2xl:w-5/12 transition transition-width ease-in duration-700 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="z-10 h-0 absolute top-0 left-0 bg-transparent py-4">
                  <input
                    className="z-10 h-0 cursor-auto bg-my-gray-300 placeholder-my-gray-300 "
                    placeholder=""
                  />
                </div>
                <div className="relative flex justify-between items-center w-full h-20 rounded-tl-xl rounded-tr-xl bg-my-green-400 px-8">
                  <p className="text-my-gray-900 font-bold text-lg">
                    Authorized Users
                  </p>
                  <div>
                    <span
                      ref={cancelButtonRef}
                      onClick={handleCloseModal}
                      className="cursor-pointer font-medium text-white text-xs hover:text-opacity-75"
                    >
                      <IoClose
                        size={25}
                        className="text-my-gray-400 hover:text-my-red-100"
                      />
                    </span>
                  </div>
                </div>
                <div className="w-full h-full px-16 my-8">
                  <div className="flex gap-x-4 items-end w-full">
                    <div className="flex flex-col justify-start w-full">
                      <p className="uppercase font-medium text-xs text-my-gray-100 tracking-wider">
                        email
                      </p>
                      <input
                        className="h-10 outline-none text-my-gray-901 border rounded-md px-4 mt-1"
                        name="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div
                      onClick={handleClickAuthorize}
                      className="cursor-pointer flex justify-center items-center w-56 lg:w-72 h-10 tracking-widest text-white text-xxs lg:text-xs xl:text-sm font-bold rounded-md bg-gradient-to-b from-my-sky-blue-100 to-my-sky-blue-200 px-2 lg:px-0"
                    >
                      {addingUser && (
                        <svg
                          className="animate-spin mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      )}
                      <p className="uppercase">authorize</p>
                    </div>
                  </div>
                  <AuthorizedList
                    loading={loading}
                    authorizedUsers={authorizedUsers}
                  />
                </div>

                <div className="relative flex justify-end items-center w-full h-20 rounded-bl-xl rounded-br-xl bg-my-green-400">
                  <div
                    onClick={handleCloseModal}
                    className="cursor-pointer flex justify-center items-center w-42 lg:w-52 h-12 tracking-widest text-my-sky-blue-200 font-bold rounded-md border-2 border-my-sky-blue-200 bg-white my-4 mr-4"
                  >
                    <p className="uppercase mx-3">Close</p>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

const mapStateToProps = ({ authorizedUsers }) => ({
  authorizedUsers,
});

export default connect(mapStateToProps)(AuthorizedUsersModal);
