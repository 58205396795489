import React from "react";

function BottomBar() {
  return (
    <div className="col-start-1 md:col-start-2 lg:col-start-3 col-end-17 row-span-1 flex justify-center items-center bg-my-gray-101">
      <p className="text-center text-xxs md:text-xs text-my-gray-900">
        This dashboard is proprietary to RRG clients and program data generated
        by the Restoration Print Program
      </p>
    </div>
  );
}

export default BottomBar;
