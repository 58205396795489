function GlobalForestWatchMap() {
  return (
    <div className="w-full h-full">
      <div className="font-montserrat font-bold text-xl">
        Global Forest Watch Map
      </div>
      <div className="flex items-center w-full my-4">
        <div className="w-full border-t border-my-gray-102"></div>
      </div>
    </div>
  );
}

export default GlobalForestWatchMap;
