// export const BASE_URL = "https://snowy-forest-26993.botics.co/";
export const BASE_URL = "https://www.restorationdashboard.com/";
export const GMAP_KEY = "AIzaSyBxPAHJg3IX8S-ANPfv8SjKt_sneQ9Olu8"; // RRG
// export const GMAP_KEY = "AIzaSyDwJjs5B3qUkPPbti5Moj_a8RSYwDjuxqk"; // VCC
export const CLIENT_ID =
  "148652465688-ld0hukaj8231t6ahhqogbjkkkj7po8q3.apps.googleusercontent.com";

// Array of API discovery doc URLs for APIs
export const DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
];

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
export const SCOPES = "https://www.googleapis.com/auth/drive.metadata.readonly";

export const UNKNOWN_USER_PHOTO =
  "https://cdn.pixabay.com/photo/2020/07/14/13/07/icon-5404125_1280.png";

export const GOOGLE_CLIENT_ID =
  "52442677487-pd63slufhs79bp0l8gk1gib60jdt4pk8.apps.googleusercontent.com";

export const MONTHS = [
  {
    long: "January",
    short: "Jan",
  },
  {
    long: "February",
    short: "Feb",
  },
  {
    long: "March",
    short: "Mar",
  },
  {
    long: "April",
    short: "Apr",
  },
  {
    long: "May",
    short: "May",
  },
  {
    long: "June",
    short: "Jun",
  },
  {
    long: "July",
    short: "Jul",
  },
  {
    long: "August",
    short: "Aug",
  },
  {
    long: "September",
    short: "Sep",
  },
  {
    long: "October",
    short: "Oct",
  },
  {
    long: "November",
    short: "Nov",
  },
  {
    long: "December",
    short: "Dec",
  },
];
