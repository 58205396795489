function Information({ site }) {
  return (
    <div className="flex flex-col gap-y-2">
      <div className="font-montserrat font-bold text-xl">Information</div>
      <div className="flex items-center w-full my-2">
        <div className="w-full border-t border-my-gray-102"></div>
      </div>
      {site && (
        <div className="text-black grid grid-cols-3 gap-x-4 gap-y-4">
          {Object.keys(site)
            .filter(
              (key) =>
                key !== "site_media" &&
                key !== "site_tag" &&
                key !== "tree_data"
            )
            .map((key) => (
              <div key={key} className="col-span-1 flex flex-col">
                <span className="text-xs text-my-blue-900 capitalize">
                  {key.replace(/_/g, " ")}
                </span>
                <span className="text-xs text-my-gray-900 font-bold">
                  {site[key] ? site[key] : "N/A"}
                </span>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default Information;
