// Import Authorized restorationDataPerUser actions
import { GET_RESTORATION_DATA_PER_USER } from "../actions/restorationDataPerUser";

// Authorized restorationDataPerUser reducer
export default function restorationDataPerUser(state = {}, action) {
  switch (action.type) {
    case GET_RESTORATION_DATA_PER_USER:
      return {
        ...state,
        [action.restorationData.userId]: {
          ...action.restorationData,
        },
      };
    default:
      return state;
  }
}
