import classnames from "classnames";

import useWindowDimensions from "../../main/useWindowDimensions";

function MonthlyEscrowReleaseTopBar({ selectedView, setSelectedView }) {
  const { isSmScreen } = useWindowDimensions();

  return (
    <div className="flex justify-between items-center gap-x-4 w-full">
      <div className="font-montserrat font-bold text-xl">
        Monthly Escrow Release
      </div>

      <div className="flex items-center bg-my-gray-101 rounded">
        <span
          onClick={() => {
            if (selectedView !== "graph") {
              setSelectedView("graph");
            }
          }}
          className={classnames(
            "cursor-pointer flex justify-center items-center rounded h-full p-2",
            { "cursor-default bg-my-green-101": selectedView === "graph" }
          )}
        >
          <svg
            style={{
              height: isSmScreen ? 15 : 20,
              width: isSmScreen ? 15 : 22,
            }}
            width="31"
            height="18"
            viewBox="0 0 31 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 17L8.25 6.33333L15.5 9.88889L22.75 1L30 8.11111"
              stroke={selectedView === "graph" ? "#FFFFFF" : "#8F92A1"}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        <span
          onClick={() => {
            if (selectedView !== "table") {
              setSelectedView("table");
            }
          }}
          className={classnames(
            "cursor-pointer flex justify-center items-center rounded h-full p-2",
            { "cursor-default bg-my-green-101": selectedView === "table" }
          )}
        >
          <svg
            style={{
              height: isSmScreen ? 15 : 20,
              width: isSmScreen ? 15 : 22,
            }}
            width="21"
            height="22"
            viewBox="0 0 21 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 20.4092H19.4903"
              stroke={selectedView === "table" ? "#FFFFFF" : "#8F92A1"}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1 14.1367H19.4903"
              stroke={selectedView === "table" ? "#FFFFFF" : "#8F92A1"}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1 1.59082H19.4903"
              stroke={selectedView === "table" ? "#FFFFFF" : "#8F92A1"}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1 7.86328H19.4903"
              stroke={selectedView === "table" ? "#FFFFFF" : "#8F92A1"}
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </div>
    </div>
  );
}

export default MonthlyEscrowReleaseTopBar;
