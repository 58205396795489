import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import "../../App.css";

import { IoClose } from "react-icons/io5";

import passwordOpenedEyeIcon from "../../assets/icons/signup/password_opened_eye.svg";
import passwordClosedEyeIcon from "../../assets/icons/signup/password_closed_eye.svg";

import { handleCreateUserProfileData } from "../../actions/adminPanel";

import ModalSelect from "../common/ModalSelect";

function CreateUserProfileModal({
  open,
  closeModal,
  cb_handleCloseModal,
  dispatch,
  adminPanel,
  companyId,
}) {
  const cancelButtonRef = useRef();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isShowPassword, setIsShowPassword] = useState(false);

  const [selectedCompany, setSelectedCompany] = useState({
    id: 0,
    name: "Empty",
  });

  useEffect(() => {
    const company =
      companyId &&
      adminPanel?.companies &&
      adminPanel?.companies.results.length > 0
        ? adminPanel?.companies.results.filter(
            (companyObj) => companyObj.id === companyId
          )
        : null;
    if (company && company.length > 0) {
      setSelectedCompany(company[0]);
    }
  }, [open]);

  const handleCloseModal = () => {
    cb_handleCloseModal();
    closeModal();
  };

  const handleCreateUserProfileSuccess = () => {
    handleCloseModal();
  };

  const handleCreateUserProfile = () => {
    dispatch(
      handleCreateUserProfileData(
        {
          email,
          first_name: firstName,
          last_name: lastName,
          company_id: selectedCompany.id === 0 ? null : selectedCompany.id,
        },
        handleCreateUserProfileSuccess
      )
    );
  };

  return (
    <>
      <Transition show={open} as={Fragment}>
        <Dialog
          as="div"
          className="text-sm font-inter bg-black bg-opacity-50 fixed inset-0 z-20 overflow-y-auto"
          initialFocus={cancelButtonRef}
          static
          open={open}
          onClose={handleCloseModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full sm:w-2/3 md:w-3/5 xl:w-6/12 2xl:w-5/12 transition transition-width ease-in duration-700 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="z-10 h-0 absolute top-0 left-0 bg-transparent py-4">
                  <input
                    className="z-10 h-0 cursor-auto bg-my-gray-300 placeholder-my-gray-300 "
                    placeholder=""
                  />
                </div>
                <div className="relative flex justify-between items-center w-full h-20 rounded-tl-xl rounded-tr-xl bg-my-green-400 px-8">
                  <p className="capitalize text-my-gray-900 font-bold text-lg">
                    create user profile
                  </p>
                  <div>
                    <span
                      ref={cancelButtonRef}
                      onClick={handleCloseModal}
                      className="cursor-pointer font-medium text-white text-xs hover:text-opacity-75"
                    >
                      <IoClose
                        size={25}
                        className="text-my-gray-400 hover:text-my-red-100"
                      />
                    </span>
                  </div>
                </div>
                <div className="text-my-gray-900 px-16 mt-10 pb-10">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-12 md:col-span-6 flex flex-col justify-start">
                      <p className="font-medium tracking-wider">First Name:</p>
                      <input
                        className="h-10 w-full font-bold outline-none bg-my-gray-201 disabled:bg-my-gray-101 rounded-md px-6 mt-1"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                    <div className="col-span-12 md:col-span-6 flex flex-col justify-start">
                      <p className="font-medium tracking-wider">Last Name:</p>
                      <input
                        className="h-10 w-full font-bold outline-none bg-my-gray-201 disabled:bg-my-gray-101 rounded-md px-6 mt-1"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>

                    <div className="col-span-12 md:col-span-6 flex flex-col justify-start">
                      <p className="font-medium tracking-wider">Email:</p>
                      <input
                        className="h-10 w-full font-bold outline-none bg-my-gray-201 disabled:bg-my-gray-101 rounded-md px-6 mt-1"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="col-span-12 md:col-span-6 flex gap-y-1 flex-col justify-start">
                      <p className="font-medium tracking-wider">Company:</p>
                      <div className="z-10 h-10">
                        <ModalSelect
                          isDisabled={companyId}
                          options={
                            adminPanel?.companies
                              ? [
                                  { id: 0, name: "Empty" },
                                  ...adminPanel?.companies?.results,
                                ]
                              : [{ id: 0, name: "Empty" }]
                          }
                          selected={selectedCompany}
                          setSelected={setSelectedCompany}
                        />
                      </div>
                    </div>
                    {/* <div className="flex flex-col justify-start">
                      <p className="font-medium tracking-wider">
                        Create Password:
                      </p>
                      <div className="relative w-full">
                        <input
                          className="h-10 w-full font-bold outline-none bg-my-gray-201 disabled:bg-my-gray-101 rounded-md px-6 mt-1"
                          name="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          type={isShowPassword ? "text" : "password"}
                        />
                        <img
                          onClick={() => setIsShowPassword(!isShowPassword)}
                          className="cursor-pointer absolute top-1/3 right-4 mb-2"
                          src={
                            isShowPassword
                              ? passwordOpenedEyeIcon
                              : passwordClosedEyeIcon
                          }
                          alt="passwordEye"
                        />
                      </div>
                    </div> */}
                  </div>
                </div>

                <div className="relative flex justify-end items-center w-full h-20 rounded-bl-xl rounded-br-xl bg-my-green-400">
                  <div
                    onClick={handleCloseModal}
                    className="cursor-pointer flex justify-center items-center w-42 lg:w-52 h-12 tracking-widest text-my-sky-blue-200 font-bold rounded-md border-2 border-my-sky-blue-200 bg-white my-4 mr-4"
                  >
                    <p className="uppercase mx-3">cancel</p>
                  </div>
                  <div
                    onClick={handleCreateUserProfile}
                    className="cursor-pointer flex justify-center items-center w-62 lg:w-72 h-12 tracking-widest text-white font-bold rounded-md bg-gradient-to-b from-my-sky-blue-100 to-my-sky-blue-200 my-4 mr-4"
                  >
                    <p className="uppercase mx-3">add profile</p>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

const mapStateToProps = ({ adminPanel }) => ({
  adminPanel,
});

export default connect(mapStateToProps)(CreateUserProfileModal);
