import { useEffect } from "react";
import { connect } from "react-redux";

import MyAdminTable from "../common/MyAdminTable";

import exportIcon from "../../assets/icons/dashboard/table/export.svg";

import { handleGetRestorationDataPerUser } from "../../actions/restorationDataPerUser";
import {
  handleGetUsersInAdminPanel,
  handleGetAdminComapniesData,
  handleGetComapniesData,
  handleGetRestorationSitesData,
  handleDownloadCompaniesReport,
} from "../../actions/adminPanel";

import useWindowDimensions from "../main/useWindowDimensions";

const columnsInit = [
  {
    id: "sn",
    content: "SN",
    isVisible: true,
    doFormat: true,
    type: "number",
  },
  {
    id: "id",
    content: "ID",
    isVisible: true,
    doFormat: false,
    type: "number",
  },
  {
    id: "date",
    content: "Date",
    isVisible: true,
    doFormat: true,
    type: "datetime",
  },
  {
    id: "customer_id_number",
    content: "Customer ID / Number",
    isVisible: true,
    doFormat: true,
    type: "text",
  },
  {
    id: "print_order_number",
    content: "Print Order Number",
    isVisible: true,
    doFormat: true,
    type: "text",
  },
  {
    id: "invoice_number",
    content: "Invoice Number",
    isVisible: true,
    doFormat: true,
    type: "text",
  },
  {
    id: "print_order_type",
    content: "Print Order Type",
    isVisible: true,
    doFormat: true,
    type: "text",
  },
  {
    id: "service_provider",
    content: "Service Provider",
    isVisible: true,
    doFormat: true,
    type: "text",
  },
  {
    id: "facility",
    content: "Facility",
    isVisible: true,
    doFormat: true,
    type: "text",
  },
  {
    id: "certificate_number",
    content: "Certificate Number",
    isVisible: true,
    doFormat: true,
    type: "text",
  },
];

const columnsCompaniesInit = [
  {
    id: "sn",
    content: "SN",
    isVisible: true,
    type: "number",
  },
  {
    id: "id",
    content: "ID",
    isVisible: true,
    type: "number",
  },
  {
    id: "name",
    content: "Name",
    isVisible: true,
    type: "text",
  },
  {
    id: "number_of_trees_committed",
    content: "Number Of Trees Committed",
    isVisible: true,
    type: "number",
  },
  {
    id: "MtCO2e_to_be_sequestered",
    content: "MtCO2e To Be Sequestered",
    isVisible: true,
    type: "number",
  },
  {
    id: "trees_released_from_escrow",
    content: "Trees Released From Escrow",
    isVisible: true,
    type: "number",
  },
  {
    id: "tree_in_escrow",
    content: "Trees In Escrow",
    isVisible: true,
    type: "number",
  },
  {
    id: "trees_planted",
    content: "Trees Planted",
    isVisible: true,
    type: "number",
  },
  {
    id: "MtCO2e_sequestered",
    content: "MtCO2e Sequestered",
    isVisible: true,
    type: "number",
  },
  {
    id: "work_hours_created",
    content: "Work Hours Created",
    isVisible: true,
    type: "number",
  },
  {
    id: "employees_employed",
    content: "Employees Employed",
    isVisible: true,
    type: "number",
  },
];

const columnsClientsInit = [
  {
    id: "sn",
    content: "SN",
    isVisible: true,
    type: "number",
  },
  {
    id: "id",
    content: "ID",
    isVisible: true,
    type: "number",
  },
  {
    id: "MtCO2e_sequestered",
    content: "MtCO2e Sequestered",
    isVisible: true,
    type: "number",
  },
  {
    id: "MtCO2e_to_be_sequestered",
    content: "MtCO2e To Be Sequestered",
    isVisible: true,
    type: "number",
  },
  {
    id: "client_name",
    content: "Client Name",
    isVisible: true,
    type: "text",
  },
  {
    id: "number_of_tree_committed",
    content: "Number of Tree Committed",
    isVisible: true,
    type: "number",
  },
  {
    id: "trees_in_escrow",
    content: "Trees in Escrow",
    isVisible: true,
    type: "number",
  },
  {
    id: "trees_released_from_escrow",
    content: "Trees Released From Escrow",
    isVisible: true,
    type: "number",
  },
  {
    id: "trees_planted",
    content: "Trees Planted",
    isVisible: true,
    type: "number",
  },
  {
    id: "work_hours_created",
    content: "Work Hours Created",
    isVisible: true,
    type: "number",
  },
];

const limit = 100;
const offsetInit = 0;

function PanelTable({
  openCustomizeTableColumnsModal,
  restorationData,
  adminPanel,
  openCertificateModal,
  openAddNewUserModal,
  dispatch,
  dropDownData,
  openAddNewCompanyModal,
}) {
  const { isSmScreen } = useWindowDimensions();

  useEffect(() => {
    // dispatch(handleGetUsersInAdminPanel());

    dispatch(handleGetAdminComapniesData());
    dispatch(handleGetComapniesData());
    dispatch(handleGetRestorationSitesData());
  }, []);
  return (
    <div className="col-start-1 col-end-17 row-start-3 row-end-17 w-full px-2 md:px-8">
      <div className="relative grid grid-rows-24 gap-y-1 w-full h-full rounded-xl bg-white p-2 md:p-8">
        <span
          onClick={() => dispatch(handleDownloadCompaniesReport())}
          className="z-30 md:z-0 absolute -top-3 -right-3 cursor-pointer rounded-full bg-white border border-my-green-102 p-2"
        >
          <img
            style={{
              height: isSmScreen ? 15 : 20,
              width: isSmScreen ? 15 : 20,
            }}
            className=""
            src={exportIcon}
            alt="export"
          />
        </span>
        <MyAdminTable
          columnsInit={columnsInit}
          columnsClientsInit={columnsClientsInit}
          columnsCompaniesInit={columnsCompaniesInit}
          limit={limit}
          offsetInit={offsetInit}
          openCustomizeTableColumnsModal={openCustomizeTableColumnsModal}
          openCertificateModal={openCertificateModal}
          openAddNewUserModal={openAddNewUserModal}
          openAddNewCompanyModal={openAddNewCompanyModal}
          tableData={adminPanel?.adminCompanies}
          clientsTableData={adminPanel?.users}
          restorationData={restorationData}
          handleGetData={handleGetRestorationDataPerUser}
          handleGetClientData={handleGetUsersInAdminPanel}
          dropDownData={dropDownData}
        />
      </div>
    </div>
  );
}

const mapStateToProps = ({ restorationData, adminPanel }) => ({
  restorationData,
  adminPanel,
});

export default connect(mapStateToProps)(PanelTable);
