import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
// import "../App.css";
import { Link } from "react-router-dom";
import {
  handleSignIn,
  handleGoogleSignIn,
  signIn,
} from "../../actions/authedUser";
import { setErrorMessage } from "../../actions/shared";
import goolgeIcon from "../../assets/icons/signin/google.svg";
// import linkedInIcon from "../../assets/icons/signin/linkedIn.svg";
// import userIcon from "../../assets/icons/signin/user.svg";
import logoImage from "../../assets/images/logo_white.svg";
import passwordOpenedEyeIcon from "../../assets/icons/signup/password_opened_eye.svg";
import passwordClosedEyeIcon from "../../assets/icons/signup/password_closed_eye.svg";

import GoogleLogin from "react-google-login";
import { GOOGLE_CLIENT_ID } from "../../utils/constants";
// import { LinkedIn } from "react-linkedin-login-oauth2";

class Signin extends Component {
  state = {
    email: "",
    password: "",
    loading: false,
    code: "",
    errorMessage: "",
    isShowErrorMsg: false,
    isShowPassword: false,
  };

  handleSignInClick = () => {
    const { dispatch } = this.props;
    const { email, password } = this.state;
    this.setState(() => ({
      loading: true,
      isShowErrorMsg: false,
    }));
    dispatch(handleSignIn({ email, password }, this.handleLoginFailure));
    this.setState(() => ({
      loading: false,
    }));
  };

  handleChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    this.setState(() => ({
      [key]: value,
    }));
  };

  responseGoogleSuccess = (response) => {
    const { dispatch } = this.props;
    // dispatch(signIn({ ...response, isGoogle: true }));
    dispatch(handleGoogleSignIn({ ...response, isGoogle: true }));

    console.log(response);
  };

  responseGoogleFailed = () => {
    const { dispatch } = this.props;
    // dispatch(setErrorMessage({ message: "Failed to Login", type: "error" }));
    // setTimeout(() => {
    //   dispatch(setErrorMessage({}));
    // }, 3000);
  };

  handleLoginFailure = () => {
    this.setState(() => ({
      isShowErrorMsg: true,
    }));
  };

  handleShowHidePassword = () => {
    this.setState((currentState) => ({
      isShowPassword: !currentState.isShowPassword,
    }));
  };

  // handleLinkedInSuccess = (data) => {
  //   this.setState({
  //     code: data.code,
  //     errorMessage: "",
  //   });
  // };

  // handleLinkedInFailure = (error) => {
  //   alert("Failed to login using LinkedIn");
  //   this.setState({
  //     code: "",
  //     errorMessage: error.errorMessage,
  //   });
  // };

  render() {
    const { openResetPasswordModal } = this.props;
    const { email, password, isShowErrorMsg, isShowPassword } = this.state;
    return (
      <div className="flex flex-col w-screen h-screen bg-my-blue-100 overflow-x-hidden">
        <span
          className="flex-grow inline-block h-max align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div className="flex justify-center items-center w-full h-42rem">
          <div className="flex flex-col h-full lg:flex-row min-1/4 w-4/5 md:w-2/3 xl:w-3/5 2xl:w-3/5 transition transition-width ease-in duration-700 border shadow-2xl rounded-xl bg-white">
            <div className="hidden lg:inline-block w-1/2 2xl:w-2/5 h-full rounded-br-xl lg:rounded-br-none lg:rounded-tl-xl rounded-bl-xl bg-auth1 bg-no-repeat bg-cover">
              <div className="relative flex flex-col justify-center items-center w-full h-full rounded-br-xl lg:rounded-br-none lg:rounded-tl-xl rounded-bl-xl bg-gradient-to-b from-my-blue-200-75 to-my-blue-300-75">
                <div className="w-3/5">
                  <p className="font-bold tracking-widest text-white text-4xl mb-4">
                    Did you know?
                  </p>
                  <p className="font-extralight text-my-gray-200">
                    Almost half of all temperate grasslands and 16 percent of
                    tropical grasslands have been converted to agricultural or
                    industrial uses and only one percent of the original
                    tallgrass prairie exists today.
                  </p>
                </div>
                <div className="absolute bottom-10 flex flex-col items-center text-white text-xs">
                  <img className="mb-2" src={logoImage} alt="logo" />
                  <p>Powered by</p>
                  <p className="font-bold">Reduce. Reuse. Grow. Inc.</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col lg:w-1/2 xl:w-full 2xl:w-3/5 justify-center items-center py-6">
              <div className="flex flex-col justify-center items-center w-4/5 xl:w-3/5 2xl:w-3/5 text-xs text-my-gray-100">
                <p className="font-bold tracking-widest text-my-gray-901 text-4xl mb-4">
                  Sign In
                </p>
                <div className="w-full text-my-blue-900">
                  <GoogleLogin
                    clientId={GOOGLE_CLIENT_ID}
                    // buttonText="Login"
                    render={(renderProps) => (
                      <div
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                        className="cursor-pointer flex justify-center items-center w-full h-12 tracking-widest font-bold border border-my-blue-900 rounded-md my-4"
                      >
                        <img src={goolgeIcon} alt="google" />
                        <p className="uppercase mx-3">sign in with google</p>
                      </div>
                    )}
                    onSuccess={this.responseGoogleSuccess}
                    onFailure={this.responseGoogleFailed}
                    cookiePolicy={"single_host_origin"}
                    isSignedIn={true}
                  />
                  {/* <LinkedIn
                  className="w-full"
                  clientId="81lx5we2omq9xh"
                  onFailure={this.handleLinkedInFailure}
                  onSuccess={this.handleLinkedInSuccess}
                  redirectUri="http://localhost:3000/linkedin"
                >
                  <div className="cursor-pointer flex justify-center items-center w-full h-12 tracking-widest font-bold border border-my-blue-900 rounded-md">
                    <img src={linkedInIcon} alt="linkedIn" />
                    <p className="uppercase mx-3">sign in with linkedin</p>
                  </div>
                </LinkedIn> */}

                  {/* <div className="cursor-pointer flex justify-center items-center w-full h-12 tracking-widest font-bold border border-my-blue-900 rounded-md my-4">
                  <img src={userIcon} alt="user" />
                  <p className="uppercase mx-3">sign in as guest</p>
                </div> */}
                </div>
                <div className="flex items-center w-full mt-8 mb-4">
                  <div className="w-full border-t"></div>
                  <p className="uppercase mx-3">or</p>
                  <div className="w-full border-t"></div>
                </div>
                <div className="flex flex-col justify-start w-full">
                  <p className="uppercase font-medium tracking-wider">email</p>
                  <input
                    className="h-10 outline-none text-my-gray-901 border rounded-md px-4 mt-1"
                    name="email"
                    type="email"
                    value={email}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="flex flex-col justify-start w-full mt-4">
                  <p className="uppercase font-medium tracking-wider">
                    password
                  </p>
                  <div className="relative w-full">
                    <input
                      className="h-12 w-full outline-none text-my-gray-901 border rounded-md px-4 mt-1"
                      name="password"
                      value={password}
                      onChange={this.handleChange}
                      type={isShowPassword ? "text" : "password"}
                    />
                    <img
                      onClick={this.handleShowHidePassword}
                      className="cursor-pointer absolute top-1/3 right-4 mb-2"
                      src={
                        isShowPassword
                          ? passwordOpenedEyeIcon
                          : passwordClosedEyeIcon
                      }
                      alt="passwordEye"
                    />
                  </div>
                </div>
                {isShowErrorMsg && (
                  <div className="w-full text-my-red-100 mt-4">
                    Invalid Email or Password
                  </div>
                )}
                <div className="flex items-center w-full my-4">
                  <span>Forgot Your Password?</span>
                  <span
                    onClick={openResetPasswordModal}
                    className="cursor-pointer text-my-sky-blue-101 ml-1"
                  >
                    Reset password
                  </span>
                </div>
                <div
                  onClick={this.handleSignInClick}
                  className="cursor-pointer flex justify-center items-center w-full h-12 tracking-widest text-white font-bold rounded-md bg-gradient-to-b from-my-sky-blue-100 to-my-sky-blue-200 mt-4"
                >
                  <p className="uppercase mx-3">sign in</p>
                </div>
                <div className="flex items-center w-full my-8">
                  <div className="w-full border-t"></div>
                </div>
                <div>
                  <span>
                    Don't have an account?{" "}
                    <Link
                      to="/signup"
                      className="cursor-pointer text-my-sky-blue-101 ml-1"
                    >
                      Sign Up
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span
          className="flex-grow inline-block h-max align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>
      </div>
    );
  }
}

export default connect()(Signin);
