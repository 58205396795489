import Map from "../Maps/Map";

function MapOfProjectLocation({ site }) {
  return (
    <div className="h-full flex flex-col gap-y-4">
      <div className="font-montserrat font-bold text-xl">
        Map of Project Location
      </div>
      <Map site={site} />
    </div>
  );
}

export default MapOfProjectLocation;
