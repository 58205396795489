import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import Dashboard from "../dashboard/Dashboard";
import Topbar from "../main/TopBar";
import Bottombar from "../main/BottomBar";
import LeftBar from "../main/LeftBar";
import NoMatch from "../main/NoMatch";
import UploadCsvModal from "../dashboard/table/UploadCsvModal";
import UploadCsvStatusModal from "../dashboard/table/UploadCsvStatusModal";
import UploadCsvExampleModal from "../dashboard/table/UploadCsvExampleModal";
import useWindowDimensions from "./useWindowDimensions";

import { IoClose } from "react-icons/io5";

import { handleStopImpersonation } from "../../actions/adminPanel";
import { handleGetUploadingStatusOfCsv } from "../../actions/dashboard";

import RestorationSites from "../RestorationSites/RestorationSites";
import RestorationSite from "../RestorationSites/RestorationSite/RestorationSite";
import MarketingPortal from "../MarketingPortal/MarketingPortal";

function Home({
  match,
  authedUser,
  openProfileModal,
  openCustomizeTableColumnsModal,
  openCertificateModal,
  openFeedbackModal,
  openChangePasswordModal,
  openSignoutModal,
  adminPanel,
  dashboard,
  dispatch,
  dropDownData,
  openFilterModal,
  tableFilter,
}) {
  const { isSmScreen } = useWindowDimensions();

  const [loggedIn, setLoggedIn] = useState(false);
  const [isLeftBarOpen, setIsLeftBarOpen] = useState(false);

  const [isUploadCsvModalOpen, setIsUploadCsvModalOpen] = useState(false);
  const [isUploadCsvStatusModalOpen, setIsUploadCsvStatusModalOpen] =
    useState(false);
  const [isUploadCsvExampleModalOpen, setIsUploadCsvExampleModalOpen] =
    useState(false);

  const [
    cb_handleCloseUploadCsvExampleModal,
    setCbHandleCloseUploadCsvExampleModal,
  ] = useState(() => {});
  const [currentCsvUploadStatus, setCurrentCsvUploadStatus] = useState(null);
  const [isUploadingCsv, setIsUploadingCsv] = useState(false);
  const [keepFetchingData, setKeepFetchingData] = useState(false);
  const [fetchGraphData, setFetchGraphData] = useState(false);

  useEffect(() => {
    dispatch(handleGetUploadingStatusOfCsv());
  }, []);

  useEffect(() => {
    if (isLeftBarOpen) {
      setIsLeftBarOpen(false);
    }
  }, [match]);

  useEffect(() => {
    const loggedIn = Object.keys(authedUser).length !== 0;
    setLoggedIn(loggedIn);
  }, [authedUser]);

  useEffect(() => {
    handleResize();
  }, [isLeftBarOpen, isSmScreen]);

  useEffect(() => {
    let timer = null;
    if (
      !adminPanel.isImpersonating &&
      dashboard.csvStatus &&
      dashboard.csvStatus.results.length > 0
    ) {
      const statuses = dashboard.csvStatus.results.map(
        (record) => record.status
      );
      const uploadingStatuses = statuses.filter(
        (record) => record === "uploading"
      );
      const status = uploadingStatuses.length > 0 ? "uploading" : "uploaded";

      if (status !== "uploaded") {
        setIsUploadingCsv(true);
        setCurrentCsvUploadStatus(status);
        timer = setInterval(() => {
          dispatch(handleGetUploadingStatusOfCsv(false));
        }, 30000);

        setKeepFetchingData(true);
        setTimeout(() => {
          setKeepFetchingData(false);
        }, 1000);
      } else if (
        currentCsvUploadStatus !== null &&
        currentCsvUploadStatus !== status
      ) {
        setIsUploadingCsv(false);
        clearInterval(timer);
        setIsUploadCsvStatusModalOpen(true);

        setKeepFetchingData(true);
        setFetchGraphData(true);
        setTimeout(() => {
          setKeepFetchingData(false);
          setFetchGraphData(false);
          setTimeout(() => {
            setKeepFetchingData(true);
            setFetchGraphData(true);
            setTimeout(() => {
              setKeepFetchingData(false);
              setFetchGraphData(false);
            }, 2000);
          }, 2000);
        }, 2000);
      }
    }

    return () => clearInterval(timer);
  }, [adminPanel.isImpersonating, dashboard.csvStatus]);

  const handleResize = () => {
    if (!isSmScreen && isLeftBarOpen) {
      setIsLeftBarOpen(false);
    }
  };

  const openUploadCsvModal = () => {
    setIsUploadCsvModalOpen(true);
  };

  const closeUploadCsvModal = () => {
    setIsUploadCsvModalOpen(false);
  };

  const openUploadCsvExampleModal = (cb_handleCloseUploadCsvExampleModal) => {
    setIsUploadCsvExampleModalOpen(true);
    setCbHandleCloseUploadCsvExampleModal(cb_handleCloseUploadCsvExampleModal);
  };

  const closeUploadCsvExampleModal = () => {
    setIsUploadCsvExampleModalOpen(false);
  };

  const openUploadCsvStatusModal = () => {
    setIsUploadCsvStatusModalOpen(true);
  };

  const closeUploadCsvStatusModal = () => {
    setIsUploadCsvStatusModalOpen(false);
  };

  return (
    <div className="absolute fixed overflow-y-auto top-0 bottom-0 left-0 right-0 relative grid grid-cols-16 grid-rows-24 gap-x-2 lg:gap-x-4 w-full min-h-50em h-full bg-my-blue-100">
      {adminPanel?.isImpersonating && (
        <div className="absolute transition transition-scale ease-in duration-700 top-0 right-0 left-0 z-50 flex justify-center items-center">
          <div className="relative w-60 md:w-80 lg:w-96 trapezium flex justify-center items-center transform ease-in-out transition-width duration-1000">
            <span
              onClick={() => dispatch(handleStopImpersonation())}
              className="absolute -top-4 md:-top-5 right-2 cursor-pointer font-medium text-white text-xs hover:text-opacity-75"
            >
              <IoClose
                size={15}
                className="text-white hover:text-my-red-100 p-0.5 md:p-0"
              />
            </span>
          </div>
          <p className="absolute top-0.5 md:top-1 text-white text-xxs md:text-xs">
            Impersonation Mode
          </p>
        </div>
      )}
      <Topbar
        openProfileModal={openProfileModal}
        openFeedbackModal={openFeedbackModal}
        openChangePasswordModal={openChangePasswordModal}
        openSignoutModal={openSignoutModal}
        openChangePasswordModal={openChangePasswordModal}
        openUploadCsvModal={openUploadCsvModal}
        setIsLeftBarOpen={setIsLeftBarOpen}
        isLeftBarOpen={isLeftBarOpen}
        isUploadingCsv={isUploadingCsv}
      />
      <LeftBar isLeftBarOpen={isLeftBarOpen} />
      <Switch>
        <Route
          path={`${match.path}/dashboard`}
          render={(props) => (
            <Dashboard
              {...props}
              openCustomizeTableColumnsModal={openCustomizeTableColumnsModal}
              openCertificateModal={openCertificateModal}
              dropDownData={dropDownData}
              openFilterModal={openFilterModal}
              tableFilter={tableFilter}
              keepFetchingData={keepFetchingData}
              fetchGraphData={fetchGraphData}
            />
          )}
        ></Route>
        <Route
          exact
          path={`/home/restoration-sites`}
          render={(props) => (
            <div className="col-start-1 md:col-start-2 lg:col-start-3 col-end-17 row-start-3 row-end-24">
              <RestorationSites {...props} />
            </div>
          )}
        />
        <Route
          exact
          path={`/home/restoration-sites/map-view`}
          render={(props) => (
            <div className="col-start-1 md:col-start-2 lg:col-start-3 col-end-17 row-start-3 row-end-24">
              <RestorationSites {...props} />
            </div>
          )}
        />
        <Route
          exact
          path={`/home/restoration-sites/globe-view`}
          render={(props) => (
            <div className="col-start-1 md:col-start-2 lg:col-start-3 col-end-17 row-start-3 row-end-24">
              <RestorationSites {...props} />
            </div>
          )}
        />
        <Route
          path={`/home/restoration-sites/:site_id`}
          render={(props) => (
            <div className="col-start-1 md:col-start-2 lg:col-start-3 col-end-17 row-start-3 row-end-24">
              <RestorationSite {...props} />
            </div>
          )}
        />
        {/* <Route
          exact
          path={`/home/marketing-portal`}
          render={(props) => (
            <div className="col-start-1 md:col-start-2 lg:col-start-3 col-end-17 row-start-3 row-end-24">
              <MarketingPortal {...props} />
            </div>
          )}
        /> */}
        <Route
          exact
          path="/home"
          render={() => <Redirect to="/home/dashboard/barChart" />}
        />
        <Route
          path="*"
          exact
          render={(props) => <NoMatch {...props} />}
        ></Route>
      </Switch>

      <Bottombar />

      {isUploadCsvModalOpen && (
        <UploadCsvModal
          closeModal={closeUploadCsvModal}
          open={isUploadCsvModalOpen}
          openUploadCsvExampleModal={openUploadCsvExampleModal}
          isUploadingCsv={isUploadingCsv}
        />
      )}
      {isUploadCsvExampleModalOpen && (
        <UploadCsvExampleModal
          closeModal={closeUploadCsvExampleModal}
          open={isUploadCsvExampleModalOpen}
          cb_handleCloseModal={cb_handleCloseUploadCsvExampleModal}
        />
      )}
      {isUploadCsvStatusModalOpen && (
        <UploadCsvStatusModal
          closeModal={closeUploadCsvStatusModal}
          open={isUploadCsvStatusModalOpen}
        />
      )}
    </div>
  );
}

const mapStateToProps = ({ authedUser, shared, adminPanel, dashboard }) => ({
  authedUser,
  shared,
  adminPanel,
  dashboard,
});

export default withRouter(connect(mapStateToProps)(Home));
