import { useState } from "react";
import { connect } from "react-redux";

import PanelTopBar from "./PanelTopBar";
import PanelTable from "./PanelTable";
import AddNewUserModal from "./AddNewUserModal";
import CreateUserProfileModal from "./CreateUserProfileModal";
import InviteUserModal from "./InviteUserModal";
import ReleaseTreesFromEscrowModal from "./ReleaseTreesFromEscrowModal";
import LoadingModal from "../common/LoadingModal";

function Panel({
  openProfileModal,
  openCustomizeTableColumnsModal,
  openCertificateModal,
  openFeedbackModal,
  openChangePasswordModal,
  openSignoutModal,
  openAuthorizedUsersModal,
  openAddNewCompanyModal,
  dropDownData,
}) {
  const [isAddNewUserModalOpen, setIsAddNewUserModalOpen] = useState(false);
  const [isCreateUserProfileModalOpen, setIsCreateUserProfileModalOpen] =
    useState(false);
  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);
  const [
    isReleaseTreesFromEscrowModalOpen,
    setIsReleaseTreesFromEscrowModalOpen,
  ] = useState(false);
  const [isLoadingModalOpen, setIsLoadingModalOpen] = useState(false);

  const [
    cb_handleCloseCreateUserProfileModal,
    setCbHandleCloseCreateUserProfileModal,
  ] = useState(() => {});
  const [cb_handleCloseInviteUserModal, setCbHandleCloseInviteUserModal] =
    useState(() => {});
  const [companyId, setCompanyId] = useState(null);

  const openAddNewUserModal = (companyId) => {
    setCompanyId(companyId);
    setIsAddNewUserModalOpen(true);
  };

  const closeAddNewUserModal = () => {
    setCompanyId(null);
    setIsAddNewUserModalOpen(false);
  };

  const openCreateUserProfileModal = (cb_handleCloseCreateUserProfileModal) => {
    setIsCreateUserProfileModalOpen(true);

    setCbHandleCloseCreateUserProfileModal(
      cb_handleCloseCreateUserProfileModal
    );
  };

  const closeCreateUserProfileModal = () => {
    setIsCreateUserProfileModalOpen(false);
  };

  const openInviteUserModal = (cb_handleCloseInviteUserModal) => {
    setIsInviteUserModalOpen(true);
    setCbHandleCloseInviteUserModal(cb_handleCloseInviteUserModal);
  };

  const closeInviteUserModal = () => {
    setIsInviteUserModalOpen(false);
  };

  const openReleaseTreesFromEscrowModal = () => {
    setIsReleaseTreesFromEscrowModalOpen(true);
  };

  const closeReleaseTreesFromEscrowModal = () => {
    setIsReleaseTreesFromEscrowModalOpen(false);
  };

  const openLoadingModal = () => {
    setIsLoadingModalOpen(true);
  };

  const closeLoadingModal = () => {
    setIsLoadingModalOpen(false);
  };

  return (
    <div className="grid grid-cols-16 grid-rows-16 gap-x-4 w-full h-full bg-my-blue-100">
      <PanelTopBar
        openProfileModal={openProfileModal}
        openFeedbackModal={openFeedbackModal}
        openChangePasswordModal={openChangePasswordModal}
        openSignoutModal={openSignoutModal}
        openAuthorizedUsersModal={openAuthorizedUsersModal}
        openReleaseTreesFromEscrowModal={openReleaseTreesFromEscrowModal}
      />
      <PanelTable
        openCustomizeTableColumnsModal={openCustomizeTableColumnsModal}
        openCertificateModal={openCertificateModal}
        openAddNewUserModal={openAddNewUserModal}
        openAddNewCompanyModal={openAddNewCompanyModal}
        dropDownData={dropDownData}
      />
      {isAddNewUserModalOpen && (
        <AddNewUserModal
          closeModal={closeAddNewUserModal}
          open={isAddNewUserModalOpen}
          openCreateUserProfileModal={openCreateUserProfileModal}
          openInviteUserModal={openInviteUserModal}
        />
      )}
      {isCreateUserProfileModalOpen && (
        <CreateUserProfileModal
          closeModal={closeCreateUserProfileModal}
          open={isCreateUserProfileModalOpen}
          cb_handleCloseModal={cb_handleCloseCreateUserProfileModal}
          companyId={companyId}
        />
      )}
      {isInviteUserModalOpen && (
        <InviteUserModal
          closeModal={closeInviteUserModal}
          open={isInviteUserModalOpen}
          cb_handleCloseModal={cb_handleCloseInviteUserModal}
          companyId={companyId}
        />
      )}
      {isReleaseTreesFromEscrowModalOpen && (
        <ReleaseTreesFromEscrowModal
          closeModal={closeReleaseTreesFromEscrowModal}
          open={isReleaseTreesFromEscrowModalOpen}
          companyId={companyId}
          openLoadingModal={openLoadingModal}
          closeLoadingModal={closeLoadingModal}
        />
      )}
      {isLoadingModalOpen && <LoadingModal open={isLoadingModalOpen} />}
    </div>
  );
}

const mapStateToProps = ({ authedUser, shared }) => ({
  authedUser,
  shared,
});

export default connect(mapStateToProps)(Panel);
