// Import admin panel actions
import {
  OPEN_MODAL,
  CLOSE_MODAL,
  ADD_PARAM,
  ADD_PARAMS,
  REMOVE_PARAM,
} from "../actions/modals";

// Modals reducer
export default function modals(state = {}, action) {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        [action.payload.id]: true,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        [action.payload.id]: false,
      };
    case ADD_PARAM:
      return {
        ...state,
        params: {
          ...state.params,
          [action.payload.key]: action.payload.value,
        },
      };
    case ADD_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.payload.array,
        },
      };
    case REMOVE_PARAM:
      return {
        ...state,
        params: {
          ...state.params,
          [action.payload.key]: undefined,
        },
      };
    default:
      return state;
  }
}
