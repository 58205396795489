import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef, useState } from "react";
import { connect } from "react-redux";

import { handleResetPassword } from "../../actions/authedUser";

import { IoClose } from "react-icons/io5";

function ResetPasswordModal({ open, closeModal, dispatch }) {
  const cancelButtonRef = useRef();
  const [email, setEmail] = useState("");

  const handleCloseModal = () => {
    closeModal();
  };

  const handleResetPasswordSuccess = () => {
    closeModal();
  };

  return (
    <>
      <Transition show={open} as={Fragment}>
        <Dialog
          as="div"
          className="text-sm font-inter bg-black bg-opacity-50 fixed inset-0 z-20 overflow-y-auto"
          initialFocus={cancelButtonRef}
          static
          open={open}
          onClose={handleCloseModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full sm:w-2/3 md:w-3/5 xl:w-6/12 2xl:w-5/12 transition transition-width ease-in duration-700 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="z-10 h-0 absolute top-0 left-0 bg-transparent py-4">
                  <input
                    className="z-10 h-0 cursor-auto bg-my-gray-300 placeholder-my-gray-300 "
                    placeholder=""
                  />
                </div>
                <div className="relative flex justify-between items-center w-full h-20 rounded-tl-xl rounded-tr-xl bg-my-green-400 px-8">
                  <p className="text-my-gray-900 font-bold text-lg">
                    Reset Password
                  </p>
                  <div>
                    <span
                      ref={cancelButtonRef}
                      onClick={handleCloseModal}
                      className="cursor-pointer font-medium text-white text-xs hover:text-opacity-75"
                    >
                      <IoClose
                        size={25}
                        className="text-my-gray-400 hover:text-my-red-100"
                      />
                    </span>
                  </div>
                </div>
                <div className="w-full h-full px-16 my-16 text-my-gray-400">
                  <div className="flex flex-col justify-start w-full mt-4">
                    <p className="font-medium tracking-wider">Email:</p>
                    <div className="relative w-full">
                      <input
                        className="h-12 w-full bg-my-gray-201 outline-none rounded-md px-4 mt-1"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="relative flex justify-end items-center w-full h-20 rounded-bl-xl rounded-br-xl bg-my-green-400">
                  <div
                    onClick={handleCloseModal}
                    className="cursor-pointer flex justify-center items-center w-42 lg:w-52 h-12 tracking-widest text-my-sky-blue-200 font-bold rounded-md border-2 border-my-sky-blue-200 bg-white my-4 mr-4"
                  >
                    <p className="uppercase mx-3">cancel</p>
                  </div>
                  <div
                    onClick={() =>
                      dispatch(
                        handleResetPassword(
                          email,
                          () => () => handleResetPasswordSuccess()
                        )
                      )
                    }
                    className="cursor-pointer flex justify-center items-center w-62 lg:w-72 h-12 tracking-widest text-white font-bold rounded-md bg-gradient-to-b from-my-sky-blue-100 to-my-sky-blue-200 my-4 mr-4"
                  >
                    <p className="uppercase mx-3">Reset Password</p>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default connect()(ResetPasswordModal);
