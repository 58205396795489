import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef, useState, useEffect } from "react";
import { connect } from "react-redux";

import { IoClose } from "react-icons/io5";

import { GrPowerReset } from "react-icons/gr";

import sortArrowDownIcon from "../../../assets/icons/dashboard/table/sortArrowDown.svg";
import sortArrowUpIcon from "../../../assets/icons/dashboard/table/sortArrowUp.svg";

function AdvancedFilterModal({
  open,
  closeModal,
  singleObject = {},
  tableFilter,
  setTableFilter,
  searchValues,
  setSearchValues,
  mySingleObject,
  setMySingleObject,
}) {
  const cancelButtonRef = useRef();

  useEffect(() => {
    if (
      Object.keys(searchValues).length === 0 &&
      Object.keys(singleObject).length > 0
    ) {
      let values = {};
      Object.keys(singleObject?.transaction).forEach((key) => {
        values[`transaction__${key}`] = "";
      });

      Object.keys(singleObject?.transaction?.tree).forEach((key) => {
        values[`transaction__tree__${key}`] = "";
      });

      Object.keys(singleObject?.site).forEach((key) => {
        values[`site__${key}`] = "";
      });

      setSearchValues(values);
    }

    if (Object.keys(singleObject).length > 0) {
      setMySingleObject(singleObject);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(singleObject).length > 0) {
      setMySingleObject(singleObject);
    }
  }, [singleObject]);

  const handleSetSearchValues = (key, value) => {
    if (value.trim() === "") {
      let clone = Object.assign({}, searchValues);
      delete clone[key];
      setSearchValues(clone);
    } else {
      setSearchValues({ ...searchValues, [key]: value });
    }
  };

  const handleSearch = () => {
    let filterString = Object.keys(searchValues).reduce(
      (previousValue, currentValue) => {
        if (searchValues[currentValue] === "") {
          return previousValue;
        } else {
          if (previousValue === "") {
            return `${currentValue}=${searchValues[currentValue]}`;
          } else {
            return `${previousValue}&${currentValue}=${searchValues[currentValue]}`;
          }
        }
      },
      ""
    );

    if (filterString === "") {
      filterString = undefined;
    }
    setTableFilter(filterString);
    closeModal();
  };

  const handleCloseModal = () => {
    closeModal();
  };

  const handleResetClick = () => {
    let searchValuesNew = {};
    Object.keys(searchValues).forEach((key) => {
      searchValuesNew[key] = "";
    });

    setSearchValues(searchValuesNew);
  };

  return (
    <>
      <Transition show={open} as={Fragment}>
        <Dialog
          as="div"
          className="text-sm font-inter bg-black bg-opacity-50 fixed inset-0 z-20 overflow-y-auto"
          initialFocus={cancelButtonRef}
          static
          open={open}
          onClose={handleCloseModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full sm:w-2/3 md:w-3/5 xl:w-6/12 2xl:w-5/12 transition transition-width ease-in duration-700 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="z-10 h-0 absolute top-0 left-0 bg-transparent py-4">
                  <input
                    className="z-10 h-0 cursor-auto bg-my-gray-300 placeholder-my-gray-300 "
                    placeholder=""
                  />
                </div>
                <div className="relative flex justify-between items-center w-full h-20 rounded-tl-xl rounded-tr-xl bg-my-green-400 px-8">
                  <p className="text-my-gray-900 font-bold text-lg">
                    Advanced Data Filters
                  </p>
                  <div>
                    <span
                      ref={cancelButtonRef}
                      onClick={closeModal}
                      className="cursor-pointer font-medium text-white hover:text-opacity-75"
                    >
                      <IoClose
                        size={25}
                        className="text-my-gray-400 hover:text-my-red-100"
                      />
                    </span>
                  </div>
                </div>
                <div className="w-full h-full bg-white rounded-lg px-16 mt-12">
                  {Object.keys(mySingleObject).length > 0 || tableFilter ? (
                    <div className="my-16">
                      <MyCollapsePane
                        title="Transaction Information"
                        data={mySingleObject?.transaction}
                        exclusion={["tree", "created_date"]}
                        prefix="transaction__"
                        searchValues={searchValues}
                        handleSetSearchValues={handleSetSearchValues}
                      />

                      <div className="flex items-center w-full my-8">
                        <div className="w-full border-t border-my-gray-201"></div>
                      </div>

                      <MyCollapsePane
                        title="Transaction Tree Information"
                        data={mySingleObject?.transaction?.tree}
                        exclusion={[]}
                        prefix="transaction__tree__"
                        searchValues={searchValues}
                        handleSetSearchValues={handleSetSearchValues}
                      />

                      <div className="flex items-center w-full my-8">
                        <div className="w-full border-t border-my-gray-201"></div>
                      </div>

                      <MyCollapsePane
                        title="Site Information"
                        data={mySingleObject?.site}
                        exclusion={["created_date"]}
                        prefix="site__"
                        searchValues={searchValues}
                        handleSetSearchValues={handleSetSearchValues}
                      />
                    </div>
                  ) : (
                    <div className="col-start-1 col-end-15 row-start-3 row-end-17 flex justify-center items-center mb-12">
                      <p className="font-bold tracking-widest text-center text-xl">{`-No Data To Be Filtered-`}</p>
                    </div>
                  )}
                </div>
                {(Object.keys(mySingleObject).length > 0 || tableFilter) && (
                  <div className="relative flex justify-between items-center w-full h-20 rounded-bl-xl rounded-br-xl bg-my-green-400 px-4">
                    <span
                      onClick={handleResetClick}
                      className="cursor-pointer shadow-xl h-10 w-10 text-my-green-300 hover:text-my-green-100 bg-white rounded-lg p-2"
                    >
                      <GrPowerReset size={25} color="#80BF55" className="" />
                    </span>
                    <div className="flex  ">
                      <div
                        onClick={closeModal}
                        className="cursor-pointer flex justify-center items-center w-42 lg:w-52 h-12 tracking-widest text-my-sky-blue-200 font-bold rounded-md border-2 border-my-sky-blue-200 bg-white my-4 mr-4"
                      >
                        <p className="uppercase mx-3">cancel</p>
                      </div>
                      <div
                        onClick={handleSearch}
                        className="cursor-pointer flex justify-center items-center w-62 lg:w-72 h-12 tracking-widest text-white font-bold rounded-md bg-gradient-to-b from-my-sky-blue-100 to-my-sky-blue-200 my-4"
                      >
                        <p className="uppercase mx-3">Search</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

const mapStateToProps = ({
  adminPanel,
  restorationData,
  restorationDataPerUser,
}) => {
  const firstUserId = adminPanel?.isImpersonating
    ? Object.keys(restorationDataPerUser)[0]
    : -1;
  const array = adminPanel?.isImpersonating
    ? restorationDataPerUser[firstUserId].results
    : restorationData.results;
  const singleObject = array.length > 0 ? array[0] : {};

  return { singleObject };
};

export default connect(mapStateToProps)(AdvancedFilterModal);

function MyCollapsePane({
  title,
  data,
  exclusion,
  prefix,
  searchValues,
  handleSetSearchValues,
}) {
  const [isCollapsed, setIsCollapsed] = useState(true);

  useState(() => {
    Object.keys(data)
      .filter((key) => !exclusion.includes(key))
      .forEach((key) => {
        if (searchValues[`${prefix}${key}`] !== "") {
          setIsCollapsed(false);
        }
      });
  }, []);

  return (
    <div className="w-full">
      <div
        onClick={() => setIsCollapsed(!isCollapsed)}
        className="cursor-pointer flex justify-between items-center w-full"
      >
        <span className="font-bold tracking-wide text-my-gray-900 text-lg">
          {title}
        </span>
        {isCollapsed ? (
          <img src={sortArrowDownIcon} alt="sortArrowDown" />
        ) : (
          <img src={sortArrowUpIcon} alt="sortArrowUp" />
        )}
      </div>
      {!isCollapsed && data && (
        <div className="grid grid-cols-12 gap-4 w-full text-my-gray-900 mt-4">
          {Object.keys(data)
            .filter((key) => !exclusion.includes(key))
            .map((key, index) => (
              <div
                key={key}
                className="col-span-12 md:col-span-6 flex flex-col justify-start text-xxs md:text-xs lg:text-sm"
              >
                <p className="capitalize truncate font-medium text-my-gray-400 tracking-wider">
                  {`${key.replace(/_/g, " ")}:`}
                </p>
                <input
                  className="h-8 w-full text-my-gray-400 font-bold bg-my-gray-201 outline-none rounded-md px-4 mt-1"
                  value={searchValues[`${prefix}${key}`]}
                  onChange={(e) =>
                    handleSetSearchValues(`${prefix}${key}`, e.target.value)
                  }
                />
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
