import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import classnames from "classnames";
// import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { NavLink, withRouter } from "react-router-dom";

import { connect } from "react-redux";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import PeriodSelect from "./PeriodSelect";
import FirstGraphSelect from "./FirstGraphSelect";
import SecondGraphSelect from "./SecondGraphSelect";

import useWindowDimensions from "../../main/useWindowDimensions";

import CustomLuxonUtils from "../../common/CustomLuxonUtils";

import BasicDateRangePicker from "./BasicDateRangePicker";

import areaIcon from "../../../assets/icons/dashboard/chart/area.svg";
import scatterIcon from "../../../assets/icons/dashboard/chart/scatter.svg";
import stackedIcon from "../../../assets/icons/dashboard/chart/stacked.svg";

function ChartTopBar({
  match,
  location,
  periods,
  graphOptions,
  selectedChart,
  setSelectedChart,
  selectedPeriod,
  setSelectedPeriod,
  selectedFirstGraph,
  setSelectedFirstGraph,
  selectedSecondGraph,
  setSelectedSecondGraph,
  selectedDate,
  handleDateChange,
  selectedRangeDate,
  setSelectedRangeDate,
  isChart = true,
}) {
  const { isSmScreen } = useWindowDimensions();

  return (
    <div className="flex justify-between w-full">
      <div className="h-8 flex items-center">
        {isChart && (
          <div className="flex items-center bg-my-gray-101 h-full rounded">
            <NavLink
              exact
              to={`${match.url}/barChart`}
              className="cursor-pointer flex justify-center items-center rounded h-full p-2"
              activeClassName="cursor-default bg-my-green-101"
            >
              <svg
                style={{
                  height: isSmScreen ? 15 : 20,
                  width: isSmScreen ? 15 : 22,
                }}
                width="22"
                height="100%"
                viewBox="0 0 22 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.42444 10.1079H2.00286C1.39236 10.1079 0.897461 10.6028 0.897461 11.2133V17.8457C0.897461 18.4562 1.39236 18.9511 2.00286 18.9511H6.42444C7.03493 18.9511 7.52984 18.4562 7.52984 17.8457V11.2133C7.52984 10.6028 7.03493 10.1079 6.42444 10.1079Z"
                  stroke={
                    location.pathname.includes("barChart")
                      ? "#FFFFFF"
                      : "#8F92A1"
                  }
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.0563 5.68652H8.63469C8.0242 5.68652 7.5293 6.18143 7.5293 6.79192V17.8459C7.5293 18.4564 8.0242 18.9513 8.63469 18.9513H13.0563C13.6668 18.9513 14.1617 18.4564 14.1617 17.8459V6.79192C14.1617 6.18143 13.6668 5.68652 13.0563 5.68652Z"
                  stroke={
                    location.pathname.includes("barChart")
                      ? "#FFFFFF"
                      : "#8F92A1"
                  }
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.6891 1.26489H15.2675C14.657 1.26489 14.1621 1.7598 14.1621 2.37029V17.8458C14.1621 18.4563 14.657 18.9512 15.2675 18.9512H19.6891C20.2996 18.9512 20.7945 18.4563 20.7945 17.8458V2.37029C20.7945 1.7598 20.2996 1.26489 19.6891 1.26489Z"
                  stroke={
                    location.pathname.includes("barChart")
                      ? "#FFFFFF"
                      : "#8F92A1"
                  }
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.00293 18.9512H17.4785"
                  stroke={
                    location.pathname.includes("barChart")
                      ? "#FFFFFF"
                      : "#8F92A1"
                  }
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </NavLink>
            <NavLink
              exact
              to={`${match.url}/areaChart`}
              className="cursor-pointer flex justify-center items-center h-full rounded p-2"
              activeClassName="cursor-default bg-my-green-101"
            >
              <svg
                style={{
                  height: isSmScreen ? 15 : 20,
                  width: isSmScreen ? 15 : 22,
                }}
                width="21"
                height="100%"
                viewBox="0 0 21 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.63989 15.8457H19.3262"
                  stroke={
                    location.pathname.includes("areaChart")
                      ? "#FFFFFF"
                      : "#8F92A1"
                  }
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.63989 11.4241L6.06148 4.79177L10.4831 7.00256L14.9046 1.47559L19.3262 5.89717"
                  stroke={
                    location.pathname.includes("areaChart")
                      ? "#FFFFFF"
                      : "#8F92A1"
                  }
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </NavLink>
            <NavLink
              exact
              to={`${match.url}/candleChart`}
              className="cursor-pointer flex justify-center items-center rounded h-full p-2"
              activeClassName="cursor-default bg-my-green-101"
            >
              <svg
                style={{
                  height: isSmScreen ? 15 : 20,
                  width: isSmScreen ? 15 : 22,
                }}
                width="24"
                height="100%"
                viewBox="0 0 24 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.73668 15.838V15.763H2.66168H1.73546C0.858948 15.763 0.141406 15.0228 0.141406 14.1078V4.30339C0.141406 3.38833 0.858948 2.6482 1.73546 2.6482H2.66168H2.73668V2.5732V1.41975C2.73668 1.14281 2.96148 0.918018 3.23841 0.918018C3.51534 0.918018 3.74014 1.14281 3.74014 1.41975V2.5732V2.6482H3.81514H4.74079C5.61789 2.6482 6.33542 3.38834 6.33542 4.30339V14.1078C6.33542 15.0228 5.61733 15.763 4.74079 15.763H3.81514H3.74014V15.838V16.9914C3.74014 17.2683 3.51534 17.4931 3.23841 17.4931C2.96148 17.4931 2.73668 17.2683 2.73668 16.9914V15.838ZM1.73546 3.65166C1.40201 3.65166 1.14486 3.95234 1.14486 4.30339V14.1078C1.14486 14.4588 1.40201 14.7595 1.73546 14.7595H4.74079C5.07476 14.7595 5.33196 14.4589 5.33196 14.1078V4.30339C5.33196 3.95222 5.07414 3.65166 4.74079 3.65166H1.73546Z"
                  fill={
                    location.pathname.includes("candleChart")
                      ? "#FFFFFF"
                      : "#8F92A1"
                  }
                  stroke={
                    location.pathname.includes("candleChart")
                      ? "#FFFFFF"
                      : "#8F92A1"
                  }
                  strokeWidth="0.15"
                />
                <path
                  d="M10.0978 25.6424H11.024V26.7959C11.024 27.1142 11.2818 27.3726 11.6007 27.3726C11.9196 27.3726 12.1774 27.1142 12.1774 26.7959V25.6424H13.1031C14.0236 25.6424 14.7727 24.8661 14.7727 23.9122V14.1078C14.7727 13.1539 14.0236 12.3776 13.1031 12.3776H12.1774V11.2242C12.1774 10.9058 11.9196 10.6475 11.6007 10.6475C11.2818 10.6475 11.024 10.9058 11.024 11.2242V12.3776H10.0978C9.1773 12.3776 8.42871 13.1539 8.42871 14.1078V23.9122C8.42871 24.8661 9.1773 25.6424 10.0978 25.6424ZM9.58217 14.1078C9.58217 13.7901 9.81343 13.5311 10.0978 13.5311H13.1031C13.388 13.5311 13.6193 13.7901 13.6193 14.1078V23.9122C13.6193 24.23 13.388 24.4889 13.1031 24.4889H10.0978C9.81343 24.4889 9.58217 24.23 9.58217 23.9122V14.1078Z"
                  fill={
                    location.pathname.includes("candleChart")
                      ? "#FFFFFF"
                      : "#8F92A1"
                  }
                  stroke={
                    location.pathname.includes("candleChart")
                      ? "#FFFFFF"
                      : "#8F92A1"
                  }
                  strokeWidth="0.15"
                />
                <path
                  d="M19.4618 16.9913V16.9163H19.3868H18.4606C17.5846 16.9163 16.8665 16.1761 16.8665 15.2611V5.45671C16.8665 4.54165 17.584 3.80152 18.4606 3.80152H19.3868H19.4618V3.72652V2.57307C19.4618 2.29608 19.686 2.07134 19.9635 2.07134C20.241 2.07134 20.4652 2.29608 20.4652 2.57307V3.72652V3.80152H20.5402H21.4659C22.3424 3.80152 23.0605 4.54168 23.0605 5.45671V15.2611C23.0605 16.1761 22.3424 16.9163 21.4659 16.9163H20.5402H20.4652V16.9913V18.1447C20.4652 18.4217 20.241 18.6465 19.9635 18.6465C19.686 18.6465 19.4618 18.4217 19.4618 18.1447V16.9913ZM18.4606 4.80498C18.1271 4.80498 17.87 5.10566 17.87 5.45671V15.2611C17.87 15.6121 18.1271 15.9128 18.4606 15.9128H21.4659C21.7999 15.9128 22.0571 15.6122 22.0571 15.2611V5.45671C22.0571 5.10554 21.7992 4.80498 21.4659 4.80498H18.4606Z"
                  fill={
                    location.pathname.includes("candleChart")
                      ? "#FFFFFF"
                      : "#8F92A1"
                  }
                  stroke={
                    location.pathname.includes("candleChart")
                      ? "#FFFFFF"
                      : "#8F92A1"
                  }
                  strokeWidth="0.15"
                />
              </svg>
            </NavLink>
          </div>
        )}

        <PeriodSelect
          periods={periods}
          selectedPeriod={selectedPeriod}
          setSelectedPeriod={setSelectedPeriod}
        />
        <div className="flex justify-center items-center">
          {selectedPeriod.id === "week" ? (
            <BasicDateRangePicker
              selectedRangeDate={selectedRangeDate}
              setSelectedRangeDate={setSelectedRangeDate}
            />
          ) : (
            <MuiPickersUtilsProvider utils={CustomLuxonUtils}>
              <KeyboardDatePicker
                className="mui-textfield"
                PopoverProps={{
                  PaperProps: {
                    style: {
                      backgroundColor: "#F9FAFA",
                      fontFamily: "Inter",
                      borderWidth: "0px",
                    },
                  },
                }}
                SelectProps={{
                  backgroundColor: "#87C55C",
                }}
                inputProps={{
                  style: {
                    color: "#1A202C",
                    borderColor: "transparent",
                    fontFamily: "Inter",
                    borderStyle: "none",
                    borderWidth: "0px",
                    // borderRadius: radii[3],
                    outline: "none",
                    // fontSize: fontSizes[3],
                    padding: `2px 2px`,
                  },
                }}
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                value={selectedDate}
                onChange={handleDateChange}
              />
            </MuiPickersUtilsProvider>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-y-0.5 lg:gap-0 lg:flex-row lg:justify-stretch items-center text-xs">
        <div className="z-10 h-full flex items-center rounded bg-my-gray-101 ml-4 px-2">
          <FirstGraphSelect
            graphOptions={graphOptions}
            selectedFirstGraph={selectedFirstGraph}
            setSelectedFirstGraph={setSelectedFirstGraph}
          />
        </div>
        <div className="z-10 h-full flex items-center rounded bg-my-gray-101 ml-4 px-2">
          <SecondGraphSelect
            graphOptions={graphOptions}
            selectedSecondGraph={selectedSecondGraph}
            setSelectedSecondGraph={setSelectedSecondGraph}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ authedUser }) => ({
  authedUser,
});

export default withRouter(connect(mapStateToProps)(ChartTopBar));
