import classnames from "classnames";

function MonthlyEscrowReleaseBody({ keys, data }) {
  
  return (
    <div className="w-full h-full">
      {(keys || data) && (
        <div className="row-start-1 row-end-23 w-full max-h-96 h-max rounded-xl overflow-y-auto scrollbar scrollbar-thumb-transparent scrollbar-track-transparent">
          <table className="min-w-full text-xxs md:text-xs">
            <thead className="sticky top-0 border-b">
              <tr className="py-4">
                <th
                  scope="col"
                  className="pl-3 pr-2 py-4 text-left font-medium tracking-wider"
                >
                  Type
                </th>
                {data.map((record, index) => (
                  <th
                    key={index}
                    scope="col"
                    className="pl-3 pr-2 py-4 text-left font-medium tracking-wider"
                  >
                    {record.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="font-medium">
              {keys.map((key, keyIndex) => (
                <tr
                  key={key}
                  className={classnames({
                    "border-b": keyIndex !== keys.length - 1,
                  })}
                >
                  <td
                    scope="col"
                    className="pl-3 pr-2 py-4 text-left font-medium tracking-wider"
                  >
                    {key.replace(/_/g, " ")}
                  </td>
                  {data.map((record, recordIndex) => (
                    <td
                      key={recordIndex}
                      scope="col"
                      className="pl-3 pr-2 py-4 text-left font-medium tracking-wider"
                    >
                      {new Intl.NumberFormat().format(record?.[key])}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default MonthlyEscrowReleaseBody;
