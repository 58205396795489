import { useState, Fragment } from "react";
import useWindowDimensions from "../../main/useWindowDimensions";
import Map from "../Maps/Map";
import { NavLink, withRouter } from "react-router-dom";
import classnames from "classnames";
import { HandleGetRestorationSiteData } from "../../../actions/restorationSites";
import { Spinner } from "../../common/Icons";

function RsSmall({ match, site, showMap = true }) {
  const { isSmScreen } = useWindowDimensions();
  const [expandedRecords, setExpandedRecords] = useState([]);

  const handleClickedRow = (id) => {
    if (expandedRecords.includes(id)) {
      setExpandedRecords(expandedRecords.filter((expId) => expId !== id));
    } else {
      setExpandedRecords([...expandedRecords, id]);
    }
  };

  const {
    data: rsData,
    isLoading: rsIsLoading,
    isError: rsIsError,
  } = HandleGetRestorationSiteData(
    expandedRecords.includes(site?.id) ? site?.id : null
  );

  return (
    <div
      className={classnames(
        "col-span-1 flex flex-col w-full bg-my-gray-101 border rounded border-my-gray-201 p-4",
        {
          "row-span-1": !expandedRecords.includes(site?.id),
          "row-span-6": expandedRecords.includes(site?.id),
        }
      )}
    >
      <div className="flex">
        <div className="truncate flex-grow flex flex-col">
          <NavLink
            to={`/home/restoration-sites/${site?.id}`}
            className="truncate font-bold text-xl"
          >
            {site.name}
          </NavLink>
          <span className="text-sm">{site.region} </span>
        </div>
        <span
          className="cursor-pointer text-my-green-103 my-auto pr-10"
          onClick={() => handleClickedRow(site?.id)}
        >
          {expandedRecords.includes(site?.id) ? (
            <svg
              style={{
                height: isSmScreen ? 15 : 20,
                width: isSmScreen ? 15 : 20,
              }}
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M 1 5 L 5 1 L9 5"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              style={{
                height: isSmScreen ? 15 : 20,
                width: isSmScreen ? 15 : 20,
              }}
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L5 5L9 1"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </span>
      </div>

      {expandedRecords.includes(site?.id) && (
        <div className="h-full w-full">
          {!rsData || rsIsLoading ? (
            <div className="h-full flex justify-center items-center">
              <Spinner />
            </div>
          ) : (
            <Fragment>
              <div className="flex items-center w-full my-4">
                <div className="w-full border-t border-my-gray-102"></div>
              </div>
              <div className="grid grid-cols-2 gap-3">
                <div className="flex flex-col gap-y-1 border border-my-gray-201 rounded-md bg-white p-3">
                  <span className="tracking-wider text-my-dark-blue-100 text-sm font-bold">
                    {rsData?.plant_volume}
                  </span>
                  <span className="text-xxs">
                    Total site’s volume of plants
                  </span>
                </div>
                <div className="flex flex-col gap-y-1 border border-my-gray-201 rounded-md bg-white p-3">
                  <span className="tracking-wider text-my-dark-blue-100 text-sm font-bold">
                    {rsData?.plants_committed}
                  </span>
                  <span className="text-xxs">Plants committed to date</span>
                </div>
                <div className="flex flex-col gap-y-1 border border-my-gray-201 rounded-md bg-white p-3">
                  <span className="tracking-wider text-my-dark-blue-100 text-sm font-bold">
                    {rsData?.trees_released_from_escrow}
                  </span>
                  <span className="text-xxs">Plants released from escrow</span>
                </div>
                <div className="flex flex-col gap-y-1 border border-my-gray-201 rounded-md bg-white p-3">
                  <span className="tracking-wider text-my-dark-blue-100 text-sm font-bold">
                    {rsData?.tree_in_escrow}
                  </span>
                  <span className="text-xxs">Plants still in escrow</span>
                </div>
                <div className="flex flex-col gap-y-1 border border-my-gray-201 rounded-md bg-white p-3">
                  <span className="tracking-wider text-my-dark-blue-100 text-sm font-bold">
                    {rsData?.plant_date}
                  </span>
                  <span className="text-xxs">Projected Planting Date</span>
                </div>
                <div className="flex flex-col gap-y-1 border border-my-gray-201 rounded-md bg-white p-3">
                  <span className="tracking-wider text-my-dark-blue-100 text-sm font-bold">
                    {rsData?.trees_planted}
                  </span>
                  <span className="text-xxs">Plants planted to date</span>
                </div>
                {showMap && (
                  <div className="col-span-2 h-56 rounded-md">
                    {site?.longitude && site?.latitude ? (
                      <Map site={site} />
                    ) : (
                      <div className="flex justify-center items-center h-full">
                        <span className="font-bold text-3xl">
                          No Coordinates found
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Fragment>
          )}
        </div>
      )}
    </div>
  );
}

export default withRouter(RsSmall);
