import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";

import { IoCheckmark } from "react-icons/io5";

import arrowDownIcon from "../../../assets/icons/dashboard/chart/arrowDown.svg";
import arrowUpIcon from "../../../assets/icons/dashboard/chart/arrowUp.svg";

import useWindowDimensions from "../../main/useWindowDimensions";

function FirstGraphSelect({ periods, selectedPeriod, setSelectedPeriod }) {
  const { isSmScreen } = useWindowDimensions();

  return (
    <div className="z-10 h-full flex items-center text-xxs md:text-xs rounded bg-my-gray-101 mx-4 px-1 md:px-4">
      <span className="hidden md:block text-my-gray-301">Show:</span>
      <Listbox value={selectedPeriod} onChange={setSelectedPeriod}>
        {({ open }) => (
          <div className="w-20 md:w-32 fixed relative">
            <Listbox.Button className="relative w-full pl-0.5 md:pl-3 pr-10 text-left rounded-lg cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500">
              <span className="block truncate text-center">
                {selectedPeriod.name}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                {/* <SelectorIcon
                    className="w-5 h-5 text-gray-400"
                    aria-hidden="true"
                  /> */}
                {open ? (
                  <img
                    style={{
                      height: isSmScreen ? 7 : 10,
                      width: isSmScreen ? 7 : 10
                    }}
                    src={arrowUpIcon}
                    alt="arrow Up"
                  />
                ) : (
                  <img
                    style={{
                      height: isSmScreen ? 7 : 10,
                      width: isSmScreen ? 7 : 10
                    }}
                    src={arrowDownIcon}
                    alt="arrow Down"
                  />
                )}
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute w-full overflow-auto text-center bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {periods.map((option) => (
                  <Listbox.Option
                    disabled={!option.available}
                    key={option.id}
                    className={({ active }) =>
                      `${
                        active
                          ? "bg-my-gray-101 text-amber-900 bg-amber-100"
                          : "text-gray-900"
                      }${
                        !option.available
                          ? " cursor-not-allowed opacity-75 "
                          : " cursor-pointer "
                      }
                          w-full select-none relative text-right md:text-center py-2 pl-6 md:pl-10 md:pr-4`
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`${
                            selected ? "font-bold" : "font-normal"
                          } block truncate hover:whitespace-normal text-center`}
                        >
                          {option.name}
                        </span>
                        {selected ? (
                          <span
                            className={`${
                              active ? "text-amber-600" : "text-amber-600"
                            }
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                          >
                            <IoCheckmark
                              className="w-4 h-4"
                              aria-hidden="true"
                            />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    </div>
  );
}

export default FirstGraphSelect;
