import classnames from "classnames";

function AuthorizedList({ loading, authorizedUsers }) {
  return (
    <div className="w-full font-inter px-4 py-2">
      <div className="flex justify-center items-center w-full rounded-tl-xl rounded-tr-xl bg-my-green-400">
        {loading && (
          <svg
            className="animate-spin mr-3 h-5 w-5 text-my-sky-blue-200"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        )}
        {/* <span className="text-my-gray-900 font-bold tracking-widest text-lg py-4">
          Authorized Users List
          {authorizedUsers.length > 0 && (
            <span>{` (${authorizedUsers.length})`}</span>
          )}
        </span> */}
      </div>
      <div className="flex flex-col justify-stretch items-center w-full rounded-bl-xl rounded-br-xl">
        {authorizedUsers.length > 0 ? (
          authorizedUsers.map((user, index) => {
            return (
              <p
                key={user.id}
                className={classnames("w-full text-my-gray-900 py-2", {
                  "border-b border-my-gray-301 border-opacity-20":
                    index < authorizedUsers.length - 1,
                  "rounded-b-xl": authorizedUsers.length - 1 === index
                })}
              >
                {user.email}
              </p>
            );
          })
        ) : (
          <p className="w-full text-center text-my-gray-900 py-8">No Data</p>
        )}
      </div>
    </div>
  );
}

export default AuthorizedList;
