// Actions declaration
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const ADD_PARAM = "ADD_PARAM";
export const ADD_PARAMS = "ADD_PARAMS";
export const REMOVE_PARAM = "REMOVE_PARAM";

export const idImagePreviewer = "idImagePreviewer";
export const paramKeyImageUrl = "paramKeyImageUrl";

// Action functions for updating the store
export const openModal = (id) => {
  return { type: OPEN_MODAL, payload: { id } };
};
export const closeModal = (id) => {
  return { type: CLOSE_MODAL, payload: { id } };
};
export const addParam = ({ key, value }) => {
  return { type: ADD_PARAM, payload: { key, value } };
};
export const addParams = (array) => {
  return { type: ADD_PARAMS, payload: { array } };
};
export const removeParam = ({ key }) => {
  return { type: REMOVE_PARAM, payload: { key } };
};
