import { Fragment, useState, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { withRouter } from "react-router-dom";
import classnames from "classnames";

import moment from "moment";

import { css } from "@emotion/react";

import Tippy from "@tippyjs/react";

import "../../App.css";

import { connect } from "react-redux";

import settingsIcon from "../../assets/icons/dashboard/topbar/settings.svg";
import convaIcon from "../../assets/icons/dashboard/topbar/conva.svg";

import { IoMenu } from "react-icons/io5";

import { UNKNOWN_USER_PHOTO } from "../../utils/constants";

import { IoClose } from "react-icons/io5";
import { getImageUrlOfAwsToQuestionMark } from "../../utils/helper";

function TopBar({
  openProfileModal,
  openFeedbackModal,
  openSignoutModal,
  openChangePasswordModal,
  openUploadCsvModal,
  openAuthorizedUsersModal,
  authedUser,
  authorizedUsers,
  adminPanel,
  setIsLeftBarOpen,
  isLeftBarOpen,
  isUploadingCsv,
  dashboard,
}) {
  const options = [
    {
      id: 1,
      name: "Feedback",
      visible: !adminPanel?.isImpersonating,
      onClick: openFeedbackModal,
    },
    {
      id: 2,
      name: "Change Password",
      visible: !authedUser?.isGoogle && !adminPanel?.isImpersonating,
      onClick: openChangePasswordModal,
    },
    {
      id: 3,
      name: "Signout",
      visible: !adminPanel?.isImpersonating,
      onClick: openSignoutModal,
    },
  ];

  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [userName, setUserName] = useState(null);
  const [userImage, setUserImage] = useState(null);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [uploadingList, setUploadingList] = useState([]);

  useEffect(() => {
    const { first_name, profile_image, social_login_image, isGoogle } =
      authedUser;
    const userName = adminPanel?.isImpersonating
      ? adminPanel?.client?.first_name
      : first_name;
    const userImage = adminPanel?.isImpersonating
      ? !adminPanel?.client?.isGoogle
        ? adminPanel?.client?.profile_image
          ? getImageUrlOfAwsToQuestionMark(adminPanel?.client.profile_image)
          : UNKNOWN_USER_PHOTO
        : adminPanel?.client?.social_login_image
        ? adminPanel?.client?.social_login_image
        : UNKNOWN_USER_PHOTO
      : !isGoogle
      ? profile_image
        ? getImageUrlOfAwsToQuestionMark(profile_image)
        : UNKNOWN_USER_PHOTO
      : social_login_image
      ? social_login_image
      : UNKNOWN_USER_PHOTO;
    setUserName(userName);
    setUserImage(userImage);
  }, [adminPanel, authedUser]);

  const getNotificationTime = (created_date) => {
    const seconds = moment(new Date())
      .utc()
      .diff(moment(new Date(created_date)), "seconds");

    console.log(`Seconds - ${seconds}`);

    if (seconds < 30) {
      return "now";
    } else if (seconds < 60) {
      return `${seconds} sec(s) ago`;
    } else {
      const minutes = moment(new Date())
        .utc()
        .diff(moment(new Date(created_date)), "minutes");

      if (minutes < 60) {
        return `${minutes} min(s) ago`;
      } else {
        const hours = moment(new Date())
          .utc()
          .diff(moment(new Date(created_date)), "hours");
        if (hours < 60) {
          return `${hours} hr(s) ago`;
        } else {
          const days = moment(new Date())
            .utc()
            .diff(moment(new Date(created_date)), "days");
          return `${days} day(s) ago`;
        }
      }
    }
  };

  useEffect(() => {
    if (dashboard?.csvStatus && dashboard?.csvStatus?.results.length > 0) {
      const list = dashboard?.csvStatus?.results
        .filter((file) => file.status === "uploading")
        .sort((a, b) => new Date(b.start_time) - new Date(a.start_time));
      setUploadingList(list);
    }
  }, [dashboard]);

  return (
    <div className="col-start-1 col-end-17 row-span-2 grid grid-cols-16 px-3 lg:px-4">
      <div className="col-span-1 lg:col-span-2 flex items-center">
        <div
          className="cursor-pointer block md:hidden"
          onClick={() => setIsLeftBarOpen(!isLeftBarOpen)}
        >
          <IoMenu />
        </div>
        <img
          className="cursor-pointer hidden md:block w-8 lg:w-10 h-8 lg:h-10 rounded-full"
          src={convaIcon}
          alt="conva"
        />
      </div>
      <div className="col-start-2 lg:col-start-3 col-end-17 flex justify-between items-center">
        <div className="flex items-center">
          <img
            onClick={openProfileModal}
            className="cursor-pointer w-8 md:w-10 h-8 md:h-10 rounded-full shadow"
            src={userImage}
            alt="User avatar"
          />
          <div className="flex flex-col justify-center text-xs ml-3 md:ml-6">
            <p className="font-semibold text-sm mg:text-base">{`Hi ${userName}!`}</p>
            <span className="hidden md:block text-my-gray-300">
              Here's a bird's-eye view of what's going on in the{" "}
              <span className="font-bold tracking-wide text-my-green-101">
                Restoration Dashboard
              </span>
            </span>
          </div>
        </div>
        <div className="flex items-center justify-end h-max transition transition-width ease-in duration-700 text-sm">
          {!adminPanel?.isImpersonating && (
            <Tippy
              disabled={!isUploadingCsv}
              delay={200}
              hideDelay={200}
              animation="fade"
              theme="light"
              arrow={true}
              placement="left"
              content={uploadingList.map((file, index) => {
                return (
                  <div
                    className={classnames(
                      "w-full text-xxs lg:text-xs text-my-gray-900 py-2",
                      {
                        "border-b border-my-gray-301 border-opacity-20":
                          index < uploadingList.length - 1,
                        "rounded-b-xl": uploadingList.length - 1 === index,
                      }
                    )}
                    key={file.id}
                  >
                    <span>
                      {`${file.file_name}`}
                      <span className="text-purple-700">{` - ${getNotificationTime(
                        file.start_time
                      )}`}</span>
                    </span>
                  </div>
                );
              })}
            >
              <div
                onClick={openUploadCsvModal}
                className="cursor-pointer flex justify-center items-center w-26 md:w-36 h-6 md:h-8 tracking-widest text-white font-medium rounded bg-gradient-to-b from-my-sky-blue-100 to-my-sky-blue-200 md:mr-4 px-2"
              >
                {isUploadingCsv && (
                  <svg
                    className="animate-spin mr-3 h-3 w-3 md:h-5 md:w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}

                <p className="uppercase text-xxs md:text-xs">upload csv</p>
              </div>
            </Tippy>
          )}
          {!adminPanel?.isImpersonating && (
            <div>
              {isSettingsOpen ? (
                <div className="hidden lg:flex items-center text-my-sky-blue-200 transition transition-width ease-in duration-700">
                  {/* <Tippy
                  delay={200}
                  hideDelay={200}
                  animation="fade"
                  theme="light"
                  arrow={true}
                  placement="bottom"
                  trigger="click"
                  content={
                    <AuthorizedList
                      loading={loading}
                      authorizedUsers={authorizedUsers}
                    />
                  }
                >
                  <p
                    // onClick={openAuthorizedUsersModal}
                    className="cursor-pointer ml-6"
                  >
                    Authorized Users
                  </p>
                </Tippy> */}
                  {!adminPanel?.isImpersonating && (
                    <p
                      onClick={openFeedbackModal}
                      className="cursor-pointer ml-6"
                    >
                      Feedback
                    </p>
                  )}
                  {/* {((!isGoogle && !adminPanel?.isImpersonating) ||
                  (adminPanel?.isImpersonating &&
                    !adminPanel?.client.isGoogle)) && ( */}
                  {!authedUser?.isGoogle && !adminPanel?.isImpersonating && (
                    <p
                      onClick={openChangePasswordModal}
                      className="cursor-pointer ml-6"
                    >
                      Change Password
                    </p>
                  )}
                  {/* )} */}
                  {!adminPanel?.isImpersonating && (
                    <p
                      onClick={openSignoutModal}
                      className="cursor-pointer ml-6"
                    >
                      Sign Out
                    </p>
                  )}

                  <span
                    onClick={() => setIsSettingsOpen(false)}
                    className="cursor-pointer ml-6"
                  >
                    <IoClose
                      size={25}
                      className="text-my-gray-301 hover:text-my-gray-400"
                    />
                  </span>
                </div>
              ) : (
                <div
                  className="cursor-pointer hidden lg:flex items-center"
                  onClick={() => setIsSettingsOpen(true)}
                >
                  <img src={settingsIcon} alt="settings" />
                </div>
              )}
            </div>
          )}

          {!adminPanel?.isImpersonating && (
            <div className="z-10 cursor-pointer h-full flex lg:hidden items-center text-xs rounded ">
              <Listbox
                value={selectedOption}
                onChange={(value) => setSelectedOption(value)}
              >
                {({ open }) => (
                  <div className="fixed relative">
                    <Listbox.Button className="relative w-0 pl-3 pr-10 text-left rounded-lg cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500">
                      <span className="invisible block truncate text-center">
                        {selectedOption.name}
                      </span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <img src={settingsIcon} alt="settings" />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute right-0 w-max overflow-auto text-center bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none py-2 px-1">
                        {options
                          .filter((option) => option.visible)
                          .map((option, index) => (
                            <Listbox.Option key={option.id} value={option}>
                              {({ selected, active }) => (
                                <>
                                  <span
                                    onClick={option.onClick}
                                    className={`${
                                      active
                                        ? "bg-my-gray-101 text-amber-900 bg-amber-100"
                                        : "text-gray-900"
                                    }${
                                      selected ? "font-bold" : "font-normal"
                                    } block truncate text-center mx-2 px-1 py-2 rounded`}
                                  >
                                    {option.name}
                                  </span>

                                  {index !==
                                    options.filter((option) => option.visible)
                                      .length -
                                      1 && (
                                    <div className="flex items-center w-full px-2">
                                      <div className="w-full border-t"></div>
                                    </div>
                                  )}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                )}
              </Listbox>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({
  authedUser,
  authorizedUsers,
  adminPanel,
  dashboard,
}) => ({
  authedUser,
  authorizedUsers,
  adminPanel,
  dashboard,
});

export default withRouter(connect(mapStateToProps)(TopBar));
