import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Cell,
  ResponsiveContainer
} from "recharts";
import { connect } from "react-redux";
import Tippy from "@tippyjs/react";
import classnames from "classnames";

const rawData = [
  {
    name: "Jan",
    high: 7.18811,
    low: 7.18127,
    open: 7.18631,
    close: 7.183,
    ts: 1562790720
  },
  {
    name: "Feb",
    high: 7.21184,
    low: 7.20139,
    open: 7.20139,
    close: 7.21138,
    ts: 1562790780
  },
  {
    name: "Mar",
    high: 7.21808,
    low: 7.21524,
    open: 7.2168,
    close: 7.21675,
    ts: 1562790840
  },
  {
    name: "Apr",
    high: 7.19661,
    low: 7.19343,
    open: 7.19633,
    close: 7.1936,
    ts: 1562790900
  },
  {
    name: "May",
    high: 7.18131,
    low: 7.17473,
    open: 7.17819,
    close: 7.18131,
    ts: 1562790960
  },
  {
    name: "Jun",
    high: 7.17874,
    low: 7.17475,
    open: 7.17874,
    close: 7.17604,
    ts: 1562791020
  },
  {
    name: "Jul",
    high: 7.19077,
    low: 7.18493,
    open: 7.18684,
    close: 7.19077,
    ts: 1562791080
  },
  {
    name: "Aug",
    high: 7.1837,
    low: 7.17899,
    open: 7.1792,
    close: 7.18246,
    ts: 1562791140
  },
  {
    name: "Sep",
    high: 7.18788,
    low: 7.18098,
    open: 7.18338,
    close: 7.18788,
    ts: 1562791200
  },
  {
    name: "Oct",
    high: 7.20103,
    low: 7.19715,
    open: 7.19778,
    close: 7.19715,
    ts: 1562791260
  },
  {
    name: "Nov",
    high: 7.21353,
    low: 7.20752,
    open: 7.20873,
    close: 7.21116,
    ts: 1562791320
  },
  {
    name: "Dec",
    high: 7.19661,
    low: 7.19343,
    open: 7.19633,
    close: 7.1936,
    ts: 1562790900
  }
];

const prepareData = (data) => {
  return data.map(({ open, close, ...other }) => {
    return {
      ...other,
      openClose: [open, close]
    };
  });
};

function StackedCandleChart2({
  selectedFirstGraph,
  selectedSecondGraph,
  graphData
}) {
  const [keys, setKeys] = useState(["high", "low"]);
  const [activeIndex, setActiveIndex] = useState(-1);

  const getPath = (x, y, width, height) => {
    const xx = `
          M ${x},${y + 0}
          L ${x},${y + 0 + height}
          L ${x + width},${y + 0 + height}
          L ${x + width},${y + 0}
          L ${x},${y + 0}
        `;

    return xx;
  };

  const getPathGrow = (x, y, width, height) => {
    const xx = `M${x} ${y + 4 + 0}C${x} ${y + 1.79086 + 0} ${x + 1.79086} ${
      y + 0
    } ${x + 4} ${y + 0}H${x + 11}C${x + 13.2091} ${y + 0} ${x + 15} ${
      y + 1.79086 + 0
    } ${x + 15} ${y + 4 + 0}V${y + height - 2 - 2}C${x + 15} ${
      y + height - 2
    } ${x + 13.2091} ${y + height - 2 + 1.79086} ${x + 11} ${
      y + height - 2 + 1.79086
    }H${x + 4}C${x + 1.79086} ${y + height - 2 + 1.79086} ${x} ${
      y + height - 2
    } ${x} ${y + height - 2 - 2}V${y + height - 2 - 2}Z`;

    return xx;
  };

  const getPathDecay = (x, y, width, height) => {
    const xx = `M${x} ${y - 2}C${x} ${y - height / 5} ${x + 1.79086} ${y + 0} ${
      x + 4
    } ${y + 0}H${x + 11}C${x + 13.2091} ${y + 0} ${x + 15} ${y - height / 5} ${
      x + 15
    } ${y - 2}V${y + height - 2 - 2}C${x + 15} ${y + height - 2} ${
      x + 13.2091
    } ${y + height - 2 + 1.79086} ${x + 11} ${y + height - 2 + 1.79086}H${
      x + 4
    }C${x + 1.79086} ${y + height - 2 + 1.79086} ${x} ${y + height - 2} ${x} ${
      y + height - 2 - 2
    }V${y + height - 2 - 2}Z`;

    return xx;
  };

  const RectangleCandleBar = ({
    fill,
    placement,
    dataKey,
    payload,
    dataLabel,
    index,
    data,
    x,
    y,
    height,
    width,
    low,
    high,
    openClose: [open, close]
  }) => {
    const isGrowing = open < close;
    const color = isGrowing ? "green" : "red";
    const ratio = Math.abs(height / (open - close));

    const pastValue = index === 0 ? "NA" : data[index - 1][dataKey];
    const presentValue = payload[dataKey];
    const percentage =
      pastValue === "NA"
        ? 0
        : (presentValue - pastValue) /
          (pastValue === 0 && presentValue > 0
            ? pastValue
            : pastValue === 0
            ? 0.0000000000001
            : pastValue);

    return (
      <Tippy
        delay={500}
        hideDelay={500}
        duration={800}
        animation="fade"
        theme="light"
        arrow={true}
        visible={activeIndex === index}
        placement={placement}
        content={
          <div className="font-inter w-36 flex flex-col rounded-xl p-2">
            <span className="font-bold text-xl text-my-gray-901">
              {new Intl.NumberFormat().format(presentValue)}
            </span>
            <span className="capitalize font-light text-xs text-my-gray-900 tracking-wider mt-1">
              {dataLabel}
            </span>
            <span
              className={classnames("self-end text-xxs mt-2", {
                "text-my-red-100": Math.sign(percentage) === -1,
                "text-my-green-101": Math.sign(percentage) === 1
              })}
            >
              {new Intl.NumberFormat("en-US", {
                style: "percent",
                minimumFractionDigits: 1,
                maximumFractionDigits: 1
              }).format(percentage)}
            </span>
          </div>
        }
      >
        <g
          stroke={color}
          fill={color}
          onMouseEnter={() => {
            setActiveIndex(index);
          }}
          onMouseLeave={() => {
            setActiveIndex(-1);
          }}
          strokeWidth="2"
        >
          <path
            fill={isGrowing ? "#80BF54" : "#4DC0E2"}
            stroke={isGrowing ? "#80BF54" : "#4DC0E2"}
            d={
              isGrowing
                ? getPath(x, y, width, height)
                : getPath(x, y, width, height)
            }
          />
          {/* bottom line */}
          {isGrowing ? (
            <path
              stroke="rgba(128, 191, 84, .5)"
              d={`
            M ${x + width / 2}, ${y + height}
            v ${(open - low) * ratio}
          `}
            />
          ) : (
            <path
              stroke="rgba(77, 192, 226, .5)"
              d={`
            M ${x + width / 2}, ${y}
            v ${(close - low) * ratio}
          `}
            />
          )}
          {/* top line */}
          {isGrowing ? (
            <path
              stroke="rgba(128, 191, 84, .5)"
              d={`
            M ${x + width / 2}, ${y}
            v ${(close - high) * ratio}
          `}
            />
          ) : (
            <path
              stroke="rgba(77, 192, 226, .5)"
              d={`
            M ${x + width / 2}, ${y + height}
            v ${(open - high) * ratio}
          `}
            />
          )}
        </g>
      </Tippy>
    );
  };

  const data = prepareData(rawData);
  data.reduce((acc, item) => console.log(item), 0);
  const minValue = data.reduce(
    (minValue, { low, openClose: [open, close] }) => {
      const currentMin = Math.min(low, open, close);
      return minValue === null || currentMin < minValue ? currentMin : minValue;
    },
    null
  );
  const maxValue = data.reduce(
    (maxValue, { high, openClose: [open, close] }) => {
      const currentMax = Math.max(high, open, close);
      return currentMax > maxValue ? currentMax : maxValue;
    },
    minValue
  );

  return (
    <div className="col-start-1 col-end-15 row-start-3 row-end-17">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart barGap={14} data={data}>
          <defs>
            <linearGradient id="firstGraph" x1="0" y1="0" x2="0" y2="1">
              <stop offset="45%" stopColor="#4DC0E2" stopOpacity={0.8} />
              <stop offset="100%" stopColor="#4DC0E2" stopOpacity={0.2} />
            </linearGradient>
          </defs>
          <XAxis
            xAxisId={0}
            tickLine={false}
            tick={{ fontSize: 10, transform: "translate(0, 3)" }}
            dataKey="name"
          />
          <YAxis
            tickLine={false}
            tick={{ fontSize: 10, transform: "translate(-3, 0)" }}
            domain={[minValue, maxValue]}
          />
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          {/* <Legend /> */}
          {keys.map((key, index) => (
            <Bar
              key="openClose"
              barSize={25}
              xAxisId={0}
              dataKey="openClose"
              fill={`${index === 0 ? "#80BF54" : "#4DC0E2"}`}
              style={{ zIndex: index === 0 ? 99 : 1 }}
              radius={[3, 3, 3, 3]}
              reverseStackOrder={true}
              shape={
                <RectangleCandleBar
                  dataKey="openClose"
                  dataLabel={"openClose".replace(/_/g, " ")}
                  placement={`${index === 0 ? "left" : "right"}`}
                  data={data}
                />
              }
            >
              {data.map((entry, indexCell) => {
                return (
                  <Cell key={`cell-${indexCell}`} fill={`url(#firstGraph)`} />
                );
              })}
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

const mapStateToProps = ({ graphData }) => ({
  graphData
});

export default connect(mapStateToProps)(StackedCandleChart2);

class CustomizedDot extends React.Component {
  render() {
    const {
      cx,
      cy,
      fill,
      placement,
      dataKey,
      payload,
      dataLabel,
      index,
      data
    } = this.props;

    const pastValue = index === 0 ? "NA" : data[index - 1][dataKey];
    const presentValue = payload[dataKey];
    const percentage =
      pastValue === "NA"
        ? 0
        : (presentValue - pastValue) /
          (pastValue === 0 && presentValue > 0
            ? pastValue
            : pastValue === 0
            ? 0.0000000000001
            : pastValue);

    return (
      <Tippy
        delay={1000}
        hideDelay={1000}
        duration={800}
        animation="fade"
        theme="light"
        arrow={true}
        visible={true}
        placement={placement}
        content={
          <div className="font-inter w-36 flex flex-col rounded-xl p-2">
            <span className="font-bold text-xl text-my-gray-901">
              {new Intl.NumberFormat().format(presentValue)}
            </span>
            <span className="capitalize font-light text-xs text-my-gray-900 tracking-wider mt-1">
              {dataLabel}
            </span>
            <span
              className={classnames("self-end text-xxs mt-2", {
                "text-my-red-100": Math.sign(percentage) === -1,
                "text-my-green-101": Math.sign(percentage) === 1
              })}
            >
              {new Intl.NumberFormat("en-US", {
                style: "percent",
                minimumFractionDigits: 1,
                maximumFractionDigits: 1
              }).format(percentage)}
            </span>
          </div>
        }
      >
        <circle
          cx={cx}
          cy={cy}
          fill={fill}
          stroke="white"
          strokeWidth={2}
          r={5}
          cursor="pointer"
        >
          <span className="tooltiptext">Tooltip text</span>
        </circle>
      </Tippy>
    );
  }
}
