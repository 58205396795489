import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef, useState } from "react";
import { connect } from "react-redux";
import classnames from "classnames";

import logoImage from "../../../assets/icons/dashboard/topbar/conva.svg";

import { IoClose } from "react-icons/io5";

import moment from "moment";

import sortArrowDownIcon from "../../../assets/icons/dashboard/table/sortArrowDown.svg";
import sortArrowUpIcon from "../../../assets/icons/dashboard/table/sortArrowUp.svg";
import printerIcon from "../../../assets/icons/dashboard/certificate/printer.svg";
import downloadIcon from "../../../assets/icons/dashboard/certificate/download.svg";
import messageIcon from "../../../assets/icons/dashboard/certificate/message.svg";

import { handleDownloadRestorationDataRecordReport } from "../../../actions/restorationData";

import {
  checkIsNumberAndHasDecimal,
  aroundDecimalValueToOneDecimal,
  numberWithCommas,
  isValidDate,
} from "../../../utils/helper";

function CertificateModal({
  open,
  closeModal,
  restorationData,
  restorationDataPerUser,
  indexOfSelectedRecord,
  userIdOfSelectedRecord,
  setIndexOfSelectedRecord,
  setUserIdOfSelectedRecord,
  dispatch,
  authedUser,
  adminPanel,
}) {
  const cancelButtonRef = useRef();

  const handleCloseModal = () => {
    setIndexOfSelectedRecord(-1);
    setUserIdOfSelectedRecord(undefined);
    closeModal();
  };

  return (
    <>
      <Transition show={open} as={Fragment}>
        <Dialog
          as="div"
          className="text-sm font-inter bg-black bg-opacity-50 fixed inset-0 z-20 overflow-y-auto"
          initialFocus={cancelButtonRef}
          static
          open={open}
          onClose={handleCloseModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full sm:w-2/3 md:w-3/5 xl:w-6/12 2xl:w-5/12 transition transition-width ease-in duration-700 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="z-10 h-0 absolute top-0 left-0 bg-transparent py-4">
                  <input
                    className="z-10 h-0 cursor-auto bg-my-gray-300 placeholder-my-gray-300 "
                    placeholder=""
                  />
                </div>
                <div className="flex justify-between items-center h-60 rounded-t-2xl bg-auth3 bg-no-repeat bg-cover bg-top">
                  <div className="w-full h-full rounded-t-2xl bg-gradient-to-b from-my-green-100-75 via-my-green-200-75 to-my-green-300-75">
                    <div className="relative w-full h-full rounded-t-2xl bg-black bg-opacity-25">
                      <span
                        ref={cancelButtonRef}
                        onClick={handleCloseModal}
                        className="cursor-pointer absolute top-3 right-3 font-medium text-white text-xs hover:text-opacity-75"
                      >
                        <IoClose
                          size={25}
                          className="text-white hover:text-my-red-100"
                        />
                      </span>
                      <span className="absolute -bottom-12 w-full flex justify-center">
                        <span className="w-max rounded-full border-4 border-white">
                          <img
                            className="w-28 h-28"
                            src={logoImage}
                            alt="logo"
                          />
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="w-full h-full bg-white rounded-lg px-16 py-8 mt-12">
                  <p className="w-full capitalize text-center text-5xl text-my-green-500 font-merriweather">
                    tree certificate
                  </p>
                  <p className="w-full capitalize tracling-widest text-lg text-center text-my-gray-900 mt-4">
                    One Tree has been Planted for this Transaction.
                  </p>
                  <div className="my-16">
                    {(userIdOfSelectedRecord || indexOfSelectedRecord > -1) && (
                      <MyCollapsePane
                        title="Transaction Information"
                        data={
                          userIdOfSelectedRecord &&
                          (adminPanel?.isImpersonating || authedUser?.is_admin)
                            ? restorationDataPerUser[userIdOfSelectedRecord]
                                ?.results[indexOfSelectedRecord]?.transaction
                            : restorationData?.results[indexOfSelectedRecord]
                                ?.transaction
                        }
                        exclusion={["tree", "created_date"]}
                        formattingExclusion={["id"]}
                      />
                    )}
                    <div className="flex items-center w-full my-8">
                      <div className="w-full border-t border-my-gray-201"></div>
                    </div>
                    {(userIdOfSelectedRecord || indexOfSelectedRecord > -1) && (
                      <MyCollapsePane
                        title="Transaction Tree Information"
                        data={
                          userIdOfSelectedRecord &&
                          (adminPanel?.isImpersonating || authedUser?.is_admin)
                            ? restorationDataPerUser[userIdOfSelectedRecord]
                                ?.results[indexOfSelectedRecord]?.transaction
                                ?.tree
                            : restorationData?.results[indexOfSelectedRecord]
                                ?.transaction?.tree
                        }
                        exclusion={["tree_media"]}
                        formattingExclusion={[]}
                      />
                    )}
                    <div className="flex items-center w-full my-8">
                      <div className="w-full border-t border-my-gray-201"></div>
                    </div>
                    {(userIdOfSelectedRecord || indexOfSelectedRecord > -1) && (
                      <MyCollapsePane
                        title="Site Information"
                        data={
                          userIdOfSelectedRecord &&
                          (adminPanel?.isImpersonating || authedUser?.is_admin)
                            ? restorationDataPerUser[userIdOfSelectedRecord]
                                ?.results[indexOfSelectedRecord]?.site
                            : restorationData?.results[indexOfSelectedRecord]
                                ?.site
                        }
                        exclusion={["site_media", "site_tag", "tree_data"]}
                        formattingExclusion={[]}
                      />
                    )}
                  </div>
                </div>
                <div className="relative flex justify-between items-center w-full h-20 rounded-bl-xl rounded-br-xl bg-my-green-400 px-4">
                  <div className="flex gap-x-3 items-center">
                    <span className="hidden cursor-pointer shadow-xl h-10 w-10 bg-white rounded-lg p-2">
                      <img src={printerIcon} alt="printer" />
                    </span>
                    <span
                      onClick={() => {
                        dispatch(
                          handleDownloadRestorationDataRecordReport(
                            userIdOfSelectedRecord
                              ? restorationDataPerUser[userIdOfSelectedRecord]
                                  ?.results[indexOfSelectedRecord]?.id
                              : restorationData?.results[indexOfSelectedRecord]
                                  ?.id,
                            userIdOfSelectedRecord
                          )
                        );
                      }}
                      className="cursor-pointer shadow-xl h-10 w-10 bg-white rounded-lg p-2"
                    >
                      <img src={downloadIcon} alt="download" />
                    </span>
                    <span className="hidden cursor-pointer shadow-xl h-10 w-10 bg-white rounded-lg p-2">
                      <img src={messageIcon} alt="message" />
                    </span>
                  </div>
                  <div
                    onClick={handleCloseModal}
                    className="cursor-pointer flex justify-center items-center w-36 lg:w-48 h-12 tracking-widest text-white font-bold rounded-md bg-gradient-to-b from-my-sky-blue-100 to-my-sky-blue-200 my-4"
                  >
                    <p className="uppercase mx-3">close</p>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

const mapStateToProps = ({
  authedUser,
  adminPanel,
  restorationData,
  restorationDataPerUser,
}) => ({
  authedUser,
  adminPanel,
  restorationData,
  restorationDataPerUser,
});

export default connect(mapStateToProps)(CertificateModal);

function MyCollapsePane({ title, data, exclusion, formattingExclusion }) {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <div className="w-full">
      <div
        onClick={() => setIsCollapsed(!isCollapsed)}
        className="cursor-pointer flex justify-between items-center w-full"
      >
        <span className="font-bold tracking-wide text-my-gray-900 text-lg">
          {title}
        </span>
        {isCollapsed ? (
          <img src={sortArrowDownIcon} alt="sortArrowDown" />
        ) : (
          <img src={sortArrowUpIcon} alt="sortArrowUp" />
        )}
      </div>
      {!isCollapsed && data && (
        <div className="grid grid-cols-12 gap-4 w-full text-my-gray-900 mt-4">
          {Object.keys(data)
            .filter((key) => !exclusion.includes(key))
            .map((key, index) => (
              <div
                key={key}
                className="col-span-12 md:col-span-6 flex flex-col justify-start text-xxs md:text-xs lg:text-sm"
              >
                <p className="capitalize truncate font-medium text-my-gray-400 tracking-wider">
                  {`${key.replace(/_/g, " ")}:`}
                </p>
                <input
                  disabled
                  className="h-8 w-full text-my-gray-400 font-bold bg-my-gray-101 outline-none rounded-md px-4 mt-1"
                  value={
                    data[key]
                      ? formattingExclusion.includes(key)
                        ? data[key]
                        : isNaN(data[key]) && isValidDate(data[key])
                        ? moment(new Date(data[key])).format(
                            "DD/MM/YYYY hh:mm A"
                          )
                        : checkIsNumberAndHasDecimal(data[key])
                        ? numberWithCommas(
                            aroundDecimalValueToOneDecimal(data[key])
                          )
                        : !isNaN(data[key])
                        ? numberWithCommas(data[key])
                        : data[key]
                      : "N/A"
                  }
                />
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
