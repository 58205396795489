import axios from "axios";

import { showLoading, hideLoading } from "react-redux-loading";
import {
  signinRequest,
  logoutRequest,
  googleSigninRequest,
  userInfoRequest,
  saveUserInfoRequest,
  sendFeedbackRequest,
  changePasswordRequest,
  resetPasswordRequest,
  registrationRequest,
  getCsrfTokenRequest,
} from "../utils/api";
import { setErrorMessage } from "./shared";

import { saveProfileDataImpersonation } from "./adminPanel";

// Actions declaration
export const SIGN_IN = "SIGN_IN";
export const SIGN_UP = "SIGN_UP";
export const SIGN_OUT = "SIGN_OUT";
export const SAVE_PROFILE_DATA = "SAVE_PROFILE_DATA";

// Action functions for updating the store
export function signIn(authedUser) {
  return {
    type: SIGN_IN,
    authedUser,
  };
}

export function signUp(authedUser) {
  return {
    type: SIGN_UP,
    authedUser,
  };
}

export function signOut() {
  return {
    type: SIGN_OUT,
  };
}

export function saveProfileData(authedUser) {
  return {
    type: SAVE_PROFILE_DATA,
    authedUser,
  };
}

// Handling user signin request
export function handleSignIn(user, cb_handleLoginFailure) {
  return (dispatch) => {
    dispatch(showLoading());
    signinRequest(user)
      .then((response) => {
        const token = response.data.key;
        // setter
        localStorage.setItem("token", token);
        dispatch(handleSignInUsingToken(false));
      })
      .then(() =>
        dispatch(
          setErrorMessage({ message: "Logged in Successfully", type: "info" })
        )
      )
      .catch((err) => {
        let msg = "Failed to Login";
        if (err && err.response && err.response.data) {
          msg = JSON.stringify(err.response.data).replace(/"/g, "");
        }
        dispatch(
          setErrorMessage({
            message: msg,
            type: "error",
          })
        );
        cb_handleLoginFailure();
      })
      .then(() =>
        setTimeout(() => {
          dispatch(setErrorMessage({}));
        }, 3000)
      )
      .then(() => dispatch(hideLoading()));
  };
}

// Handling user Google signin request
export function handleGoogleSignIn(response) {
  return (dispatch) => {
    dispatch(showLoading());
    googleSigninRequest(response.accessToken).then((res) => {
      const token = res.data.key;
      // const token = "d4dfcd8345d1388f66ab380cd616bf02860d8fe1";
      axios.defaults.headers.common["Authorization"] = `Token ${token}`;
      userInfoRequest(token)
        .then((res) => {
          dispatch(
            signIn({
              ...res.data,
              email: response.profileObj.email,
              isGoogle: true,
            })
          );
        })
        .then(() =>
          dispatch(
            setErrorMessage({
              message: "Logged in Successfully",
              type: "info",
            })
          )
        )
        .catch((err) => {
          let msg = "Failed to Login";
          if (err && err.response && err.response.data) {
            msg = JSON.stringify(err.response.data).replace(/"/g, "");
          }
          dispatch(
            setErrorMessage({
              message: msg,
              type: "error",
            })
          );
        })
        .then(() =>
          setTimeout(() => {
            dispatch(setErrorMessage({}));
          }, 3000)
        )
        .then(() => dispatch(hideLoading()));
    });
  };
}

// Handling user signin using token request
export function handleSignInUsingToken(showMsg = true) {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Token ${token}`;
      userInfoRequest(token)
        .then((res) => {
          dispatch(signIn(res.data));
        })
        .then(() => {
          if (showMsg) {
            dispatch(
              setErrorMessage({
                message: "Logged in Successfully (Auto)",
                type: "info",
              })
            );
          }
        })
        .catch((err) => {
          localStorage.removeItem("token");
          axios.defaults.headers.common["Authorization"] = null;
          let msg = "Falied to Login (Auto)";
          if (err && err.response && err.response.data) {
            msg = JSON.stringify(err.response.data).replace(/"/g, "");
          }
          if (showMsg) {
            dispatch(
              setErrorMessage({
                message: msg,
                type: "error",
              })
            );
          }
        })
        .then(() => {
          if (showMsg) {
            setTimeout(() => {
              dispatch(setErrorMessage({}));
            }, 3000);
          }
        })
        .then(() => dispatch(hideLoading()));
    }
  };
}

// Handling user save request
export function handleSaveProfileData(user, cb_handleSaveSuccess) {
  return (dispatch, getState) => {
    dispatch(showLoading());

    const key = localStorage.getItem("token");
    saveUserInfoRequest({ ...user, key })
      .then((res) => {
        const { adminPanel } = getState();
        if (adminPanel?.isImpersonating) {
          dispatch(saveProfileDataImpersonation(res.data));
        } else {
          dispatch(saveProfileData(res.data));
        }

        dispatch(cb_handleSaveSuccess());
      })
      .then(() =>
        dispatch(
          setErrorMessage({
            message: "Profile Data Saved Successfully",
            type: "info",
          })
        )
      )
      .catch((err) => {
        let msg = "Failed to Save Profile Data";
        if (err && err.response && err.response.data) {
          msg = JSON.stringify(err.response.data).replace(/"/g, "");
        }
        dispatch(
          setErrorMessage({
            message: msg,
            type: "error",
          })
        );
      })
      .then(() =>
        setTimeout(() => {
          dispatch(setErrorMessage({}));
        }, 3000)
      )
      .then(() => dispatch(hideLoading()));
  };
}

// Handling user signout request
export function handleSignOut(
  cb_handleSuccess = () => {},
  cb_handleFailure = () => {}
) {
  return (dispatch) => {
    dispatch(showLoading());
    logoutRequest()
      .then(() => {
        localStorage.removeItem("token");
        axios.defaults.headers.common["Authorization"] = null;
        dispatch(signOut());
        dispatch(cb_handleSuccess());
      })
      .then(() =>
        dispatch(
          setErrorMessage({
            message: "Logged out Successfully",
            type: "info",
          })
        )
      )
      .catch((err) => {
        let msg = "Failed to Logout";
        if (err && err.response && err.response.data) {
          msg = JSON.stringify(err.response.data).replace(/"/g, "");
        }
        dispatch(
          setErrorMessage({
            message: msg,
            type: "error",
          })
        );
        cb_handleSuccess();
      })
      .then(() =>
        setTimeout(() => {
          dispatch(setErrorMessage({}));
        }, 3000)
      )
      .then(() => dispatch(hideLoading()));
  };
}

// Handling getting CSRF token request
export function handleFetchCsrfToken() {
  return (dispatch) => {
    dispatch(showLoading());
    getCsrfTokenRequest()
      .then((res) => {
        axios.defaults.headers.common["X-CSRFToken"] = res.data.csrfToken;
      })
      .then(() =>
        dispatch(
          setErrorMessage({
            message: "CSRF Token Fetched Successfully",
            type: "info",
          })
        )
      )
      .catch((err) => {
        let msg = "Failed to Fetch CSRF Token";
        if (err && err.response && err.response.data) {
          msg = JSON.stringify(err.response.data).replace(/"/g, "");
        }
        dispatch(
          setErrorMessage({
            message: msg,
            type: "error",
          })
        );
      })
      .then(() =>
        setTimeout(() => {
          dispatch(setErrorMessage({}));
        }, 3000)
      )
      .then(() => dispatch(hideLoading()));
  };
}

// Handling send feedback request
export function handleSendFeedback(text, cb_handleSuccess = () => {}) {
  return (dispatch) => {
    dispatch(showLoading());
    sendFeedbackRequest(text)
      .then(() => {
        dispatch(cb_handleSuccess());
      })
      .then(() =>
        dispatch(
          setErrorMessage({
            message: "Feedback Sent Successfully",
            type: "info",
          })
        )
      )
      .catch((err) => {
        let msg = "Failed to Send Feedback";
        if (err && err.response && err.response.data) {
          msg = JSON.stringify(err.response.data).replace(/"/g, "");
        }
        dispatch(
          setErrorMessage({
            message: msg,
            type: "error",
          })
        );
      })
      .then(() =>
        setTimeout(() => {
          dispatch(setErrorMessage({}));
        }, 3000)
      )
      .then(() => dispatch(hideLoading()));
  };
}

// Handling change password request
export function handleChangePassword(
  credentials,
  cb_handleChangePasswordSuccess = () => {}
) {
  return (dispatch) => {
    dispatch(showLoading());
    changePasswordRequest(credentials)
      .then(() => {
        dispatch(cb_handleChangePasswordSuccess());
      })
      .then(() =>
        dispatch(
          setErrorMessage({
            message: "Password Changed Successfully",
            type: "info",
          })
        )
      )
      .catch((err) => {
        let msg = "Failed to Change Password";
        if (err && err.response && err.response.data) {
          msg = JSON.stringify(err.response.data).replace(/"/g, "");
        }
        dispatch(
          setErrorMessage({
            message: msg,
            type: "error",
          })
        );
      })
      .then(() =>
        setTimeout(() => {
          dispatch(setErrorMessage({}));
        }, 3000)
      )
      .then(() => dispatch(hideLoading()));
  };
}

// Handling reset password request
export function handleResetPassword(
  email,
  cb_handleResetPasswordSuccess = () => {}
) {
  return (dispatch) => {
    dispatch(showLoading());
    resetPasswordRequest(email)
      .then((res) => {
        dispatch(cb_handleResetPasswordSuccess());
        return res.data.detail;
      })
      .then((message) =>
        dispatch(
          setErrorMessage({
            message,
            type: "info",
          })
        )
      )
      .catch((err) => {
        let msg = "Failed to Reset Password";
        if (err && err.response && err.response.data) {
          msg = JSON.stringify(err.response.data).replace(/"/g, "");
        }
        dispatch(
          setErrorMessage({
            message: msg,
            type: "error",
          })
        );
      })
      .then(() =>
        setTimeout(() => {
          dispatch(setErrorMessage({}));
        }, 3000)
      )
      .then(() => dispatch(hideLoading()));
  };
}

// Handling change password request
export function handleRegistration(registrationObject) {
  return (dispatch) => {
    dispatch(showLoading());
    registrationRequest(registrationObject)
      .then((res) => {
        return res.data.detail;
      })
      .then((message) =>
        dispatch(
          setErrorMessage({
            message,
            type: "info",
          })
        )
      )
      .catch((err) => {
        let msg = "Failed to Register";
        if (err && err.response && err.response.data) {
          msg = JSON.stringify(err.response.data).replace(/"/g, "");
        }
        dispatch(
          setErrorMessage({
            message: msg,
            type: "error",
          })
        );
      })
      .then(() =>
        setTimeout(() => {
          dispatch(setErrorMessage({}));
        }, 3000)
      )
      .then(() => dispatch(hideLoading()));
  };
}
