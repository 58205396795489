import { useState } from "react";
import SpeciesTopBar from "./SpeciesTopBar";
import classnames from "classnames";
import SpeciesPics from "./SpeciesPics";
import SpeciesTable from "./SpeciesTable";

function Species({ tree_data }) {
  const [selectedView, setSelectedView] = useState("table");
  return (
    <div className="w-full h-full">
      <SpeciesTopBar
        selectedView={selectedView}
        setSelectedView={setSelectedView}
      />
      <div className="flex items-center w-full my-4">
        <div className="w-full border-t border-my-gray-102"></div>
      </div>
      {selectedView === "table" ? (
        <SpeciesTable tree_data={tree_data} />
      ) : (
        <SpeciesPics tree_data={tree_data} />
      )}
    </div>
  );
}

export default Species;
