import classnames from "classnames";

function SpeciesTable({ tree_data }) {
  return (
    <div className="w-full h-full">
      {tree_data && (
        <div className="row-start-1 row-end-23 w-full max-h-96 h-max rounded-xl overflow-y-auto scrollbar scrollbar-thumb-transparent scrollbar-track-transparent">
          <table className="min-w-full text-xxs md:text-xs">
            <thead className="sticky top-0 border-b">
              <tr className="py-4">
                <th
                  scope="col"
                  className="pl-3 pr-2 py-4 text-left font-medium tracking-wider"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="pl-3 pr-2 py-4 text-left font-medium tracking-wider"
                >
                  Type
                </th>
                <th
                  scope="col"
                  className="pl-3 pr-2 py-4 text-left font-medium tracking-wider"
                >
                  Quantity
                </th>
              </tr>
            </thead>
            <tbody className="font-medium">
              {tree_data.map((record, index) => (
                <tr
                  key={record.id}
                  className={classnames({
                    "border-b": index !== tree_data.length - 1,
                  })}
                >
                  <td
                    scope="col"
                    className="pl-3 pr-2 py-4 text-left font-medium tracking-wider"
                  >
                    {record?.tree?.plant_specie}
                  </td>
                  <td
                    scope="col"
                    className="pl-3 pr-2 py-4 text-left font-medium tracking-wider"
                  >
                    {record?.tree?.plant_type}
                  </td>
                  <td
                    scope="col"
                    className="pl-3 pr-2 py-4 text-left font-medium tracking-wider"
                  >
                    {record?.quantity}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default SpeciesTable;
