import { Fragment, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import classnames from "classnames";
import axios from "axios";
import { connect } from "react-redux";
import LoadingBar from "react-redux-loading";
import { Route, Switch, Redirect } from "react-router-dom";

import {
  handleSignInUsingToken,
  handleFetchCsrfToken,
} from "../../actions/authedUser";

import Panel from "../admin/Panel";
import Signin from "../auth/Signin";
import Signup from "../auth/Signup";
import ResetPasswordModal from "../auth/ResetPasswordModal";
import PrivacyPolicyModal from "../auth/PrivacyPolicyModal";
import Home from "../main/Home";
import Page404 from "../error/Page404";
import ProfileModal from "../user/ProfileModal";
import ProfilePhotoModal from "../user/ProfilePhotoModal";
import CustomizeTableColumnsModal from "../dashboard/table/CustomizeTableColumnsModal";
import RestorationSiteModal from "../dashboard/table/RestorationSiteModal";
import TreeModal from "../dashboard/table/TreeModal";
import FilterModal from "../dashboard/table/FilterModal";
import AdvancedFilterModal from "../dashboard/table/AdvancedFilterModal";
import CertificateModal from "../dashboard/table/CertificateModal";
import FeedbackModal from "../user/FeedbackModal";
import SignoutModal from "../auth/SignoutModal";
import ChangePasswordModal from "../auth/ChangePasswordModal";
import AuthorizedUsersModal from "../admin/AuthorizedUsersModal";
import AddNewCompanyModal from "../admin/AddNewCompanyModal";
import CreateCompanyProfileModal from "../admin/CreateCompanyProfileModal";
import CreateCompanyProfilePhotoModal from "../admin/CreateCompanyProfilePhotoModal";

import { BASE_URL } from "../../utils/constants";

import useWindowDimensions from "../main/useWindowDimensions";

import { ChakraProvider } from "@chakra-ui/react";

import moment from "moment";

function App({ authedUser, shared, adminPanel, dispatch }) {
  const [loggedIn, setLoggedIn] = useState(false);

  const [isPrivacyPolicyModalOpen, setIsPrivacyPolicyModalOpen] =
    useState(false);
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] =
    useState(false);
  const [site, setSite] = useState({});
  const [tree, setTree] = useState({});
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isProfilePhotoModalOpen, setIsProfilePhotoModalOpen] = useState(false);
  const [isRestorationSiteModalOpen, setIsRestorationSiteModalOpen] =
    useState(false);
  const [isCertificateModalOpen, setIsCertificateModalOpen] = useState(false);
  const [isTreeModalOpen, setIsTreeModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [
    isCustomizeTableColumnsModalOpen,
    setIsCustomizeTableColumnsModalOpen,
  ] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [isSignoutModalOpen, setIsSignoutModalOpen] = useState(false);
  const [isAuthorizedUsersModalOpen, setIsAuthorizedUsersModalOpen] =
    useState(false);
  const [isAddNewCompanyModalOpen, setIsAddNewCompanyModalOpen] =
    useState(false);
  const [isCreateCompanyProfileModalOpen, setIsCreateCompanyProfileModalOpen] =
    useState(false);
  const [
    isCreateCompanyProfilePhotoModalOpen,
    setIsCreateCompanyProfilePhotoModalOpen,
  ] = useState(false);

  const [cb_handleCloseProfilePhotoModal, setCbHandleCloseProfilePhotoModal] =
    useState(() => {});
  const [
    cb_handleCloseCreateCompanyProfileModal,
    setCbHandleCloseCreateCompanyProfileModal,
  ] = useState(() => {});
  const [
    cb_handleCloseCreateCompanyProfilePhotoModal,
    setCbHandleCloseCreateCompanyProfilePhotoModal,
  ] = useState(() => {});
  const [cb_handleApplyChanges, setCbHandleApplyChanges] = useState(() => {});
  const [leftColumnItems, setLeftColumnItems] = useState([]);
  const [rightColumnItems, setRightColumnItems] = useState([]);
  const [indexOfSelectedRecord, setIndexOfSelectedRecord] = useState(-1);
  const [userIdOfSelectedRecord, setUserIdOfSelectedRecord] =
    useState(undefined);
  const [tableFilter, setTableFilter] = useState("");
  const [searchValues, setSearchValues] = useState({});
  const [mySingleObject, setMySingleObject] = useState({});
  const [filter_id, setFilter_Id] = useState("");
  const [filter_customerIdNumber, setFilter_CustomerIdNumber] = useState("");
  const [filter_printOrderNumber, setFilter_PrintOrderNumber] = useState("");
  const [filter_invoiceNumber, setFilter_InvoiceNumber] = useState("");
  const [filter_printOrderType, setFilter_PrintOrderType] = useState("");
  const [filter_serviceProvider, setFilter_ServiceProvider] = useState("");
  const [filter_facility, setFilter_Facility] = useState("");
  const [filter_certificateNumber, setFilter_CertificateNumber] = useState("");

  useEffect(() => {
    const loggedIn = Object.keys(authedUser).length !== 0;
    setLoggedIn(loggedIn);
  }, [authedUser]);

  useEffect(() => {
    configureAxios();
    dispatch(handleFetchCsrfToken());
    dispatch(handleSignInUsingToken());
  }, []);

  const configureAxios = () => {
    axios.defaults.baseURL = BASE_URL;
    // axios.defaults.headers.common["X-CSRF-TOKEN"] = null;
    // axios.defaults.headers.common["X-CSRFToken"] = getCookie("csrftoken");
  };

  const openProfileModal = () => {
    setIsProfileModalOpen(true);
  };

  const closeProfileModal = () => {
    setIsProfileModalOpen(false);
  };

  const openProfilePhotoModal = (cb_handleCloseProfilePhotoModal) => {
    setIsProfilePhotoModalOpen(true);
    setCbHandleCloseProfilePhotoModal(cb_handleCloseProfilePhotoModal);
  };

  const closeProfilePhotoModal = () => {
    setIsProfilePhotoModalOpen(false);
  };

  const openPrivacyPolicyModal = () => {
    setIsPrivacyPolicyModalOpen(true);
  };

  const closePrivacyPolicyModal = () => {
    setIsPrivacyPolicyModalOpen(false);
  };

  const openResetPasswordModal = () => {
    setIsResetPasswordModalOpen(true);
  };

  const closeResetPasswordModal = () => {
    setIsResetPasswordModalOpen(false);
  };

  const openRestorationSiteModal = (site) => {
    setSite(site);
    setIsRestorationSiteModalOpen(true);
  };

  const closeRestorationSiteModal = () => {
    setIsRestorationSiteModalOpen(false);
  };

  const openTreeModal = (tree) => {
    setTree(tree);
    setIsTreeModalOpen(true);
  };

  const closeTreeModal = () => {
    setIsTreeModalOpen(false);
  };

  const openFilterModal = (site) => {
    setSite(site);
    setIsFilterModalOpen(true);
  };

  const closeFilterModal = () => {
    setIsFilterModalOpen(false);
  };

  const openCustomizeTableColumnsModal = (
    { leftColumnItems, rightColumnItems },
    cb_applyChanges
  ) => {
    setLeftColumnItems(leftColumnItems);
    setRightColumnItems(rightColumnItems);
    setCbHandleApplyChanges(cb_applyChanges);
    setIsCustomizeTableColumnsModalOpen(true);
  };

  const closeCustomizeTableColumnsModal = () => {
    setIsCustomizeTableColumnsModalOpen(false);
  };

  const openCertificateModal = (indexOfSelectedRecord, userId) => {
    setIndexOfSelectedRecord(indexOfSelectedRecord);
    setUserIdOfSelectedRecord(userId);
    setIsCertificateModalOpen(true);
  };

  const closeCertificateModal = () => {
    setIsCertificateModalOpen(false);
  };

  const openFeedbackModal = () => {
    setIsFeedbackModalOpen(true);
  };

  const closeFeedbackModal = () => {
    setIsFeedbackModalOpen(false);
  };

  const openChangePasswordModal = () => {
    setIsChangePasswordModalOpen(true);
  };

  const closeChangePasswordModal = () => {
    setIsChangePasswordModalOpen(false);
  };

  const openSignoutModal = () => {
    setIsSignoutModalOpen(true);
  };

  const closeSignoutModal = () => {
    setIsSignoutModalOpen(false);
  };

  const openAuthorizedUsersModal = () => {
    setIsAuthorizedUsersModalOpen(true);
  };

  const closeAuthorizedUsersModal = () => {
    setIsAuthorizedUsersModalOpen(false);
  };

  const openAddNewCompanyModal = () => {
    setIsAddNewCompanyModalOpen(true);
  };

  const closeAddNewCompanyModal = () => {
    setIsAddNewCompanyModalOpen(false);
  };

  const openCreateCompanyProfileModal = (
    cb_handleCloseCreateCompanyProfileModal
  ) => {
    setCbHandleCloseCreateCompanyProfileModal(
      cb_handleCloseCreateCompanyProfileModal
    );
    setIsCreateCompanyProfileModalOpen(true);
  };

  const closeCreateCompanyProfileModal = () => {
    setIsCreateCompanyProfileModalOpen(false);
  };

  const openCreateCompanyProfilePhotoModal = (
    cb_handleCloseCreateCompanyProfilePhotoModal
  ) => {
    setCbHandleCloseCreateCompanyProfilePhotoModal(
      cb_handleCloseCreateCompanyProfilePhotoModal
    );
    setIsCreateCompanyProfilePhotoModalOpen(true);
  };

  const closeCreateCompanyProfilePhotoModal = () => {
    setIsCreateCompanyProfilePhotoModalOpen(false);
  };

  const dropDownData = [
    {
      path: ["site.created_date", "site.name", "site.id"],
      separators: ["-", "-"],
      prefix: "RRG-",
      doFormat: true,
      formats: [
        { id: "site.created_date", output: "yyyy" },
        { id: "site.name", output: ["substring.0.3", "uppercase"] },
      ],
      label: "Restoration Site ID",
      isClickable: true,
      onClick: openRestorationSiteModal,
      onClickParameterPath: "site",
    },
    {
      path: ["site.name"],
      doFormat: true,
      label: "Restoration Site Name",
      isClickable: false,
      onClick: () => {},
      onClickParameterPath: null,
    },
    {
      path: ["site.region"],
      doFormat: true,
      label: "Region",
      isClickable: false,
      onClick: () => {},
      onClickParameterPath: null,
    },
    {
      path: ["committed_tree"],
      doFormat: true,
      label: "Number of Trees Committed",
      isClickable: false,
      onClick: () => {},
      onClickParameterPath: null,
    },
    {
      path: ["tree_planted"],
      doFormat: true,
      label: "Tree Planted?",
      isClickable: false,
      onClick: () => {},
      onClickParameterPath: null,
    },
    {
      path: ["plant_date"],
      doFormat: true,
      label: "Tree Plant Date",
      isClickable: false,
      onClick: () => {},
      onClickParameterPath: null,
    },
    {
      path: ["tree_in_escrow"],
      doFormat: true,
      label: "Tree In Escrow?",
      isClickable: false,
      onClick: () => {},
      onClickParameterPath: null,
    },
    {
      path: ["transaction.id", "transaction.tree.id"],
      separators: ["-"],
      doFormat: false,
      label: "Unique Tree ID",
      isClickable: true,
      onClick: openTreeModal,
      onClickParameterPath: "transaction.tree",
    },
    {
      path: ["date_tree_released"],
      doFormat: true,
      label: "Date Tree Released?",
      isClickable: false,
      onClick: () => {},
      onClickParameterPath: null,
    },
    {
      path: ["tree_batch_number", "site.total_plant_quantity"],
      separators: [" of "],
      doFormat: true,
      label: "Tree Batch Site Number",
      isClickable: false,
      onClick: () => {},
      onClickParameterPath: null,
    },
    {
      path: ["transaction.tree.plant_type"],
      doFormat: true,
      label: "Type of Plant Planted",
      isClickable: false,
      onClick: () => {},
      onClickParameterPath: null,
    },
    {
      path: ["transaction.tree.plant_specie"],
      doFormat: true,
      label: "Plant Specie",
      isClickable: false,
      onClick: () => {},
      onClickParameterPath: null,
    },
    {
      path: ["transaction.tree.annual_carbon_sequestered"],
      doFormat: true,
      label: "Annual Carbon Offset Generated",
      isClickable: false,
      onClick: () => {},
      onClickParameterPath: null,
      unit: "lbs",
    },
    {
      path: ["transaction.tree.total_life_carbon_sequestered"],
      doFormat: true,
      label: "Total Life Carbon Sequestered",
      isClickable: false,
      onClick: () => {},
      onClickParameterPath: null,
      unit: "lbs",
    },
  ];

  return (
    <div className="relative min-w-1/4 h-screen font-inter">
      <LoadingBar style={{ backgroundColor: "#15C13B", zIndex: 99 }} />
      <Transition
        show={
          Object.keys(shared).length !== 0 &&
          shared.message !== undefined &&
          shared.message.trim() !== ""
        }
        as={Fragment}
        enter="transition ease-out duration-700"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-500"
        leaveFrom="transform opacity-100 scale-200"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="absolute transition transition-scale ease-in duration-700 top-10 right-0 left-0 z-50 flex justify-center items-center bg-transparent font-medium md:font-bold text-xs md:text-base lg:text-xl">
          <div
            className={classnames(
              "bg-opacity-25 border-l-4 shadow-3xl py-2 md:py-4 px-10 md:px-16",
              {
                "bg-green-500 border-green-700 text-white":
                  shared.type === "info",
              },
              {
                "bg-red-500 border-red-700 text-white": shared.type === "error",
              }
            )}
          >
            {shared.message}
          </div>
        </div>
      </Transition>
      {/* <div className="absolute transition transition-scale ease-in duration-700 top-5 right-0 left-0 z-50 flex justify-center items-center bg-transparent font-medium md:font-bold text-xs md:text-base lg:text-xl">
        <div className="bg-my-gray-300 border-my-gray-300 text-white bg-opacity-25 border-l-4 shadow-3xl py-2 md:py-4 px-10 md:px-16">
          {`H: ${height} W: ${width}`}
        </div>
      </div> */}
      {isPrivacyPolicyModalOpen && (
        <PrivacyPolicyModal
          closeModal={closePrivacyPolicyModal}
          open={isPrivacyPolicyModalOpen}
        />
      )}
      {isResetPasswordModalOpen && (
        <ResetPasswordModal
          closeModal={closeResetPasswordModal}
          open={isResetPasswordModalOpen}
        />
      )}
      {isProfileModalOpen && (
        <ProfileModal
          closeModal={closeProfileModal}
          open={isProfileModalOpen}
          openProfilePhotoModal={openProfilePhotoModal}
        />
      )}
      {isProfilePhotoModalOpen && (
        <ProfilePhotoModal
          closeModal={closeProfilePhotoModal}
          open={isProfilePhotoModalOpen}
          cb_handleCloseModal={cb_handleCloseProfilePhotoModal}
        />
      )}
      {isCustomizeTableColumnsModalOpen && (
        <ChakraProvider>
          <CustomizeTableColumnsModal
            closeModal={closeCustomizeTableColumnsModal}
            open={isCustomizeTableColumnsModalOpen}
            leftColumnItems={leftColumnItems}
            rightColumnItems={rightColumnItems}
            cb_handleApplyChanges={cb_handleApplyChanges}
          />
        </ChakraProvider>
      )}
      {isRestorationSiteModalOpen && (
        <RestorationSiteModal
          closeModal={closeRestorationSiteModal}
          open={isRestorationSiteModalOpen}
          site={site}
        />
      )}
      {isTreeModalOpen && (
        <TreeModal
          closeModal={closeTreeModal}
          open={isTreeModalOpen}
          tree={tree}
        />
      )}
      {/* {isFilterModalOpen && (
        <FilterModal
          closeModal={closeFilterModal}
          open={isFilterModalOpen}
          site={site}
          setTableFilter={setTableFilter}
          filter_id={filter_id}
          setFilter_Id={setFilter_Id}
          filter_customerIdNumber={filter_customerIdNumber}
          setFilter_CustomerIdNumber={setFilter_CustomerIdNumber}
          filter_printOrderNumber={filter_printOrderNumber}
          setFilter_PrintOrderNumber={setFilter_PrintOrderNumber}
          filter_invoiceNumber={filter_invoiceNumber}
          setFilter_InvoiceNumber={setFilter_InvoiceNumber}
          filter_printOrderType={filter_printOrderType}
          setFilter_PrintOrderType={setFilter_PrintOrderType}
          filter_serviceProvider={filter_serviceProvider}
          setFilter_ServiceProvider={setFilter_ServiceProvider}
          filter_facility={filter_facility}
          setFilter_Facility={setFilter_Facility}
          filter_certificateNumber={filter_certificateNumber}
          setFilter_CertificateNumber={setFilter_CertificateNumber}
        />
      )} */}
      {isFilterModalOpen && (
        <AdvancedFilterModal
          closeModal={closeFilterModal}
          open={isFilterModalOpen}
          tableFilter={tableFilter}
          setTableFilter={setTableFilter}
          searchValues={searchValues}
          mySingleObject={mySingleObject}
          setMySingleObject={setMySingleObject}
          setSearchValues={setSearchValues}
        />
      )}
      {isCertificateModalOpen && (
        <CertificateModal
          closeModal={closeCertificateModal}
          open={isCertificateModalOpen}
          indexOfSelectedRecord={indexOfSelectedRecord}
          userIdOfSelectedRecord={userIdOfSelectedRecord}
          setIndexOfSelectedRecord={setIndexOfSelectedRecord}
          setUserIdOfSelectedRecord={setUserIdOfSelectedRecord}
        />
      )}
      {isFeedbackModalOpen && (
        <FeedbackModal
          closeModal={closeFeedbackModal}
          open={isFeedbackModalOpen}
        />
      )}
      {isChangePasswordModalOpen && (
        <ChangePasswordModal
          closeModal={closeChangePasswordModal}
          open={isChangePasswordModalOpen}
        />
      )}

      {isAuthorizedUsersModalOpen && (
        <AuthorizedUsersModal
          closeModal={closeAuthorizedUsersModal}
          open={isAuthorizedUsersModalOpen}
        />
      )}
      {isAddNewCompanyModalOpen && (
        <AddNewCompanyModal
          closeModal={closeAddNewCompanyModal}
          open={isAddNewCompanyModalOpen}
          openCreateCompanyProfileModal={openCreateCompanyProfileModal}
        />
      )}
      {isCreateCompanyProfileModalOpen && (
        <CreateCompanyProfileModal
          closeModal={closeCreateCompanyProfileModal}
          open={isCreateCompanyProfileModalOpen}
          openCreateCompanyProfilePhotoModal={
            openCreateCompanyProfilePhotoModal
          }
          cb_handleCloseModal={cb_handleCloseCreateCompanyProfileModal}
        />
      )}
      {isCreateCompanyProfilePhotoModalOpen && (
        <CreateCompanyProfilePhotoModal
          closeModal={closeCreateCompanyProfilePhotoModal}
          open={isCreateCompanyProfilePhotoModalOpen}
          cb_handleCloseModal={cb_handleCloseCreateCompanyProfilePhotoModal}
        />
      )}
      {isSignoutModalOpen && (
        <SignoutModal
          closeModal={closeSignoutModal}
          open={isSignoutModalOpen}
        />
      )}
      <Switch>
        <Route
          path="/admin"
          render={(props) => {
            //
            return loggedIn ? (
              adminPanel?.isImpersonating || !authedUser?.is_admin ? (
                <Redirect to="/home/dashboard" />
              ) : (
                <Panel
                  {...props}
                  openProfileModal={openProfileModal}
                  openCustomizeTableColumnsModal={
                    openCustomizeTableColumnsModal
                  }
                  openCertificateModal={openCertificateModal}
                  openFeedbackModal={openFeedbackModal}
                  openChangePasswordModal={openChangePasswordModal}
                  openSignoutModal={openSignoutModal}
                  openAuthorizedUsersModal={openAuthorizedUsersModal}
                  openAddNewCompanyModal={openAddNewCompanyModal}
                  dropDownData={dropDownData}
                />
              )
            ) : (
              <Signin openResetPasswordModal={openResetPasswordModal} />
            );
          }}
        />
        <Route
          path="/home"
          render={(props) =>
            loggedIn ? (
              !adminPanel?.isImpersonating && authedUser?.is_admin ? (
                <Redirect to="/admin" />
              ) : (
                <Home
                  {...props}
                  openProfileModal={openProfileModal}
                  openCustomizeTableColumnsModal={
                    openCustomizeTableColumnsModal
                  }
                  openCertificateModal={openCertificateModal}
                  openFeedbackModal={openFeedbackModal}
                  openChangePasswordModal={openChangePasswordModal}
                  openSignoutModal={openSignoutModal}
                  dropDownData={dropDownData}
                  openFilterModal={openFilterModal}
                  tableFilter={tableFilter}
                />
              )
            ) : (
              <Signin openResetPasswordModal={openResetPasswordModal} />
            )
          }
        />
        <Route exact path="/" render={() => <Redirect to="/signin" />} />
        <Route
          path="/signin"
          render={() =>
            loggedIn ? (
              <Redirect to="/home" />
            ) : (
              <Signin openResetPasswordModal={openResetPasswordModal} />
            )
          }
        />
        <Route
          path="/signup"
          render={() =>
            loggedIn ? (
              <Redirect to="/home" />
            ) : (
              <Signup openPrivacyPolicyModal={openPrivacyPolicyModal} />
            )
          }
        />
        <Route path="*">
          <Page404 />
        </Route>
      </Switch>
    </div>
  );
}

const mapStateToProps = ({ authedUser, shared, adminPanel }) => ({
  authedUser,
  shared,
  adminPanel,
});

export default connect(mapStateToProps)(App);
