import { useEffect, useState } from "react";
import RsTopBar from "./RsTopBar/RsTopBar";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import MapView from "./MapView/MapView";
import GlobeView from "./GlobeView/GlobeView";
import { HandleGetRestorationSites } from "../../actions/restorationSites";

function RestorationSites({ match }) {
  const {
    data: rsData,
    isLoading: rsIsLoading,
    isError: rsIsError,
  } = HandleGetRestorationSites();
  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    if (rsData) {
      // const newMarkers = [];
      const newMarkers = rsData?.results.map((site) => ({
        id: String(site?.id),
        coordinates:
          site?.longitude && site?.latitude
            ? [Number(site?.longitude), Number(site?.latitude)]
            : [],
        city: "USA",
        color: "green",
        value: 135,
        company: site?.name,
        website: "https://www.crowdbotics.com/",
      }));

      // newMarkers.push({
      //   id: "111",
      //   city: "USA",
      //   color: "green",
      //   coordinates: [26.820553, 30.802498],
      //   value: 135,
      //   company: "Crowdbotics",
      //   website: "https://www.crowdbotics.com/",
      // });

      // newMarkers.push({
      //   id: "1",
      //   city: "USA",
      //   color: "green",
      //   coordinates: [37.0902, -95.7129],
      //   value: 135,
      //   company: "Crowdbotics",
      //   website: "https://www.crowdbotics.com/",
      // });

      setMarkers(newMarkers);
    }
  }, [rsData]);
  return (
    <div className="border col-start-1 md:col-start-2 lg:col-start-3 col-end-17 row-start-3 row-end-24 grid grid-rows-24 gap-y-2 h-full bg-white rounded-t-xl">
      <div className="row-span-2 flex items-center w-full rounded-t-xl bg-my-green-400">
        <span className="font-bold text-xs md:text-sm lg:text-base lg:text-xl text-center text-my-gray-900 ml-4">
          Restoration Sites
        </span>
      </div>
      <div className="row-span-2 w-full py-4 px-8 pr-12">
        <RsTopBar />
      </div>
      <Switch>
        <Route
          exact
          path="/home/restoration-sites"
          render={() => <Redirect to="/home/restoration-sites/map-view" />}
        />
        <Route
          exact
          path="/home/restoration-sites/map-view"
          render={() => (
            <div className="row-start-5 row-end-25">
              <MapView rsData={rsData} rsIsLoading={rsIsLoading} />
            </div>
          )}
        />
        <Route
          exact
          path="/home/restoration-sites/globe-view"
          render={() => (
            <div className="row-start-5 row-end-25">
              <GlobeView
                rsData={rsData}
                rsIsLoading={rsIsLoading}
                markers={markers}
              />
            </div>
          )}
        />
      </Switch>
    </div>
  );
}

export default withRouter(RestorationSites);
