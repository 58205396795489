import axios from "axios";

import { showLoading, hideLoading } from "react-redux-loading";
import { getTotalImpactDataRequest, getCsvUploadStatus } from "../utils/api";
import { setErrorMessage } from "./shared";

// Actions declaration
export const GET_TOTAL_IMPACT = "GET_TOTAL_IMPACT";
export const GET_CSV_UPLOAD_STATUS = "GET_CSV_UPLOAD_STATUS";

// Action functions for updating the store

export function getTotalImpactData(totalImpactData) {
  return {
    type: GET_TOTAL_IMPACT,
    totalImpactData,
  };
}

export function getUploadingStatusOfCsv(status) {
  return {
    type: GET_CSV_UPLOAD_STATUS,
    status,
  };
}

// Handling fetch total impact data request
export function handleGetTotalImpactData(showMessage = true) {
  return (dispatch, getState) => {
    dispatch(showLoading());
    const { authedUser, adminPanel } = getState();

    getTotalImpactDataRequest(
      adminPanel?.isImpersonating ? adminPanel?.client?.id : authedUser?.id
    )
      .then((response) => {
        dispatch(getTotalImpactData(response.data));
      })
      .then(() => {
        if (showMessage) {
          dispatch(
            setErrorMessage({
              message: "Total Impact Data Fetched Successfully",
              type: "info",
            })
          );
        }
      })
      .catch((err) => {
        let msg = "Failed to Fetch Total Impact Data";
        if (err && err.response && err.response.data) {
          msg = JSON.stringify(err.response.data).replace(/"/g, "");
        }

        dispatch(
          setErrorMessage({
            message: msg,
            type: "error",
          })
        );
      })
      .then(() =>
        setTimeout(() => {
          dispatch(setErrorMessage({}));
        }, 3000)
      )
      .then(() => dispatch(hideLoading()));
  };
}

// Handling fetch the uploading status of CSV file request
export function handleGetUploadingStatusOfCsv(showMessage = true) {
  return (dispatch) => {
    dispatch(showLoading());

    getCsvUploadStatus()
      .then((response) => {
        dispatch(getUploadingStatusOfCsv(response.data));
      })
      .then(() => {
        if (showMessage) {
          dispatch(
            setErrorMessage({
              message: "Uploading Status Fetched Successfully",
              type: "info",
            })
          );
        }
      })
      .catch((err) => {
        let msg = "Failed to Fetch Uploading Status";
        if (err && err.response && err.response.data) {
          msg = JSON.stringify(err.response.data).replace(/"/g, "");
        }
        dispatch(
          setErrorMessage({
            message: msg,
            type: "error",
          })
        );
      })
      .then(() =>
        setTimeout(() => {
          dispatch(setErrorMessage({}));
        }, 1000)
      )
      .then(() => dispatch(hideLoading()));
  };
}
