// Import Authorized users actions
import { GET_TOTAL_IMPACT, GET_CSV_UPLOAD_STATUS } from "../actions/dashboard";

// Dashboard reducer
export default function dashboard(state = {}, action) {
  switch (action.type) {
    case GET_TOTAL_IMPACT:
      return { ...state, totalImpact: action.totalImpactData };
    case GET_CSV_UPLOAD_STATUS:
      return { ...state, csvStatus: action.status };
    default:
      return state;
  }
}
