import classnames from "classnames";

import useWindowDimensions from "../../main/useWindowDimensions";

function SpeciesTopBar({ selectedView, setSelectedView }) {
  const { isSmScreen } = useWindowDimensions();

  return (
    <div className="flex justify-between items-center gap-x-4 w-full">
      <div className="font-montserrat font-bold text-xl">Species</div>

      <div className="flex items-center bg-my-gray-101 rounded">
        <span
          onClick={() => {
            if (selectedView !== "pics") {
              setSelectedView("pics");
            }
          }}
          className={classnames(
            "cursor-pointer flex justify-center items-center rounded h-full p-2",
            { "cursor-default bg-my-green-101": selectedView === "pics" }
          )}
        >
          <svg
            style={{
              height: isSmScreen ? 15 : 20,
              width: isSmScreen ? 15 : 22,
            }}
            width="18"
            height="20"
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 5C9 2.79086 7.20914 1 5 1C2.79086 1 1 2.79086 1 5V11C1 13.2091 2.79086 15 5 15C7.20914 15 9 13.2091 9 11V5Z"
              stroke={selectedView === "pics" ? "#FFFFFF" : "#8F92A1"}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14 5H12C10.3431 5 9 6.34315 9 8V12C9 13.6569 10.3431 15 12 15H14C15.6569 15 17 13.6569 17 12V8C17 6.34315 15.6569 5 14 5Z"
              stroke={selectedView === "pics" ? "#FFFFFF" : "#8F92A1"}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5 19V11"
              stroke={selectedView === "pics" ? "#FFFFFF" : "#8F92A1"}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13 19V12"
              stroke={selectedView === "pics" ? "#FFFFFF" : "#8F92A1"}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        <span
          onClick={() => {
            if (selectedView !== "table") {
              setSelectedView("table");
            }
          }}
          className={classnames(
            "cursor-pointer flex justify-center items-center rounded h-full p-2",
            { "cursor-default bg-my-green-101": selectedView === "table" }
          )}
        >
          <svg
            style={{
              height: isSmScreen ? 15 : 20,
              width: isSmScreen ? 15 : 22,
            }}
            width="21"
            height="22"
            viewBox="0 0 21 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 20.4092H19.4903"
              stroke={selectedView === "table" ? "#FFFFFF" : "#8F92A1"}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1 14.1367H19.4903"
              stroke={selectedView === "table" ? "#FFFFFF" : "#8F92A1"}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1 1.59082H19.4903"
              stroke={selectedView === "table" ? "#FFFFFF" : "#8F92A1"}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1 7.86328H19.4903"
              stroke={selectedView === "table" ? "#FFFFFF" : "#8F92A1"}
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </div>
    </div>
  );
}

export default SpeciesTopBar;
