import { useState, useEffect } from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function useWindowDimensions() {
  const { width } = getWindowDimensions();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [isSmScreen, setIsSmScreen] = useState(false);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (width <= 768) {
      setIsSmScreen(true);
    } else {
      setIsSmScreen(false);
    }
  }, [width]);

  return { ...windowDimensions, isSmScreen };
}
