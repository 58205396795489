function Tags({ site_tag }) {
  return (
    <div className="w-full h-full">
      <div className="font-montserrat font-bold text-xl">Tags</div>
      <div className="flex items-center w-full my-4">
        <div className="w-full border-t border-my-gray-102"></div>
      </div>
      {site_tag && (
        <div className="grid grid-cols-5 grid-rows-1 auto-cols-fr auto-rows-fr gap-3">
          {site_tag.map((tagObj) => (
            <span
              className="bg-my-gray-201 rounded-md text-center py-2"
              key={tagObj?.id}
            >
              {`#${tagObj?.name}`}
            </span>
          ))}
        </div>
      )}
    </div>
  );
}

export default Tags;
