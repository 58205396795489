import { useState } from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import SummaryBar from "./summary/SummaryBar";
import Charts from "./chart/Charts";
import DataTable from "./table/DataTable";

function Dashboard({
  openCustomizeTableColumnsModal,
  openCertificateModal,
  dropDownData,
  openFilterModal,
  tableFilter,
  keepFetchingData,
  fetchGraphData,
}) {
  const [isInitCall, setIsInitCall] = useState(true);
  return (
    <div className="border col-start-1 md:col-start-2 lg:col-start-3 col-end-17 row-start-3 row-end-24 grid grid-rows-24 gap-y-2 h-full bg-white rounded-t-xl">
      <SummaryBar keepFetchingData={keepFetchingData} />
      <Charts
        fetchGraphData={fetchGraphData}
        isInitCall={isInitCall}
        setIsInitCall={setIsInitCall}
      />
      <DataTable
        openCustomizeTableColumnsModal={openCustomizeTableColumnsModal}
        openCertificateModal={openCertificateModal}
        dropDownData={dropDownData}
        openFilterModal={openFilterModal}
        tableFilter={tableFilter}
        keepFetchingData={keepFetchingData}
      />
      <Switch>
        <Route
          exact
          path="/home/dashboard"
          render={() => <Redirect to="/home/dashboard/barChart" />}
        />
      </Switch>
    </div>
  );
}

const mapStateToProps = ({ authedUser }) => ({
  authedUser,
});

export default withRouter(connect(mapStateToProps)(Dashboard));
