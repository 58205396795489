import axios from "axios";

import { showLoading, hideLoading } from "react-redux-loading";
import {
  getUsersInAdminPanelRequest,
  createUserProfileRequest,
  getUserRequest,
  createCompanyProfileRequest,
  getAllRestorationSitesRequest,
  getAllTreesRequest,
  getAllTreesSitesRequest,
  getAllCompaniesRequest,
  getAllAdminCompaniesRequest,
  releaseTreesFromEscrowRequest,
  downloadCompaniesReportBlobRequest,
} from "../utils/api";
import { setErrorMessage } from "./shared";

import { downloadFromBlob } from "../utils/helper";

// Actions declaration
export const GET_USERS_IN_ADMIN_PANEL = "GET_USERS_IN_ADMIN_PANEL";
export const IMPERSONATE = "IMPERSONATE";
export const CLOSE_IMPERSONATION = "CLOSE_IMPERSONATION";
export const SAVE_PROFILE_DATA_IMPERSONATION =
  "SAVE_PROFILE_DATA_IMPERSONATION";
export const GET_COMPANIES = "GET_COMPANIES";
export const GET_ADMIN_COMPANIES = "GET_ADMIN_COMPANIES";
export const ADD_COMPANY = "ADD_COMPANY";
export const GET_RESTORATION_SITES = "GET_RESTORATION_SITES";
export const GET_TREES = "GET_TREES";
export const GET_TREES_SITES = "GET_TREES_SITES";

// Action functions for updating the store
export function getUsersOfAdminPanel(users) {
  return {
    type: GET_USERS_IN_ADMIN_PANEL,
    users,
  };
}

export function getAdminCompanies(companies) {
  return {
    type: GET_ADMIN_COMPANIES,
    companies,
  };
}

export function getCompanies(companies) {
  return {
    type: GET_COMPANIES,
    companies,
  };
}

export function addCompany(company) {
  return {
    type: ADD_COMPANY,
    company,
  };
}

export function impersonate(client) {
  return {
    type: IMPERSONATE,
    client,
  };
}

export function closeImpersonation() {
  return {
    type: CLOSE_IMPERSONATION,
  };
}

export function saveProfileDataImpersonation(client) {
  return {
    type: SAVE_PROFILE_DATA_IMPERSONATION,
    client,
  };
}

export function getRestorationSitesData(sites) {
  return {
    type: GET_RESTORATION_SITES,
    sites,
  };
}

export function getTreesData(trees) {
  return {
    type: GET_TREES,
    trees,
  };
}

export function getTreesSitesData(treesSites) {
  return {
    type: GET_TREES_SITES,
    treesSites,
  };
}

// Handling fetch users in admin panel request
export function handleGetUsersInAdminPanel(companyId) {
  return (dispatch) => {
    dispatch(showLoading());
    getUsersInAdminPanelRequest(companyId)
      .then((response) => {
        dispatch(
          getUsersOfAdminPanel({ users: response.data.users, companyId })
        );
      })
      .then(() =>
        dispatch(
          setErrorMessage({
            message: "Users -Of Admin Panel- Fetched Successfully",
            type: "info",
          })
        )
      )
      .catch((err) => {
        let msg = "Failed to Fetch Users -Of Admin Panel-";
        if (err && err.response && err.response.data) {
          msg = JSON.stringify(err.response.data).replace(/"/g, "");
        }
        dispatch(
          setErrorMessage({
            message: msg,
            type: "error",
          })
        );
      })
      .then(() =>
        setTimeout(() => {
          dispatch(setErrorMessage({}));
        }, 3000)
      )
      .then(() => dispatch(hideLoading()));
  };
}

// Handling impersonation
export function handleImpersonation(id) {
  return (dispatch) => {
    dispatch(showLoading());

    getUserRequest(id)
      .then((response) => {
        const isGoogle = response.data?.social_login_image !== null;
        dispatch(impersonate({ ...response.data, isGoogle }));
      })
      .then(() =>
        dispatch(
          setErrorMessage({
            message: "Impersonation Started Successfully",
            type: "info",
          })
        )
      )
      .catch((err) => {
        let msg = "Failed to Start Impersonation";
        if (err && err.response && err.response.data) {
          msg = JSON.stringify(err.response.data).replace(/"/g, "");
        }
        dispatch(
          setErrorMessage({
            message: msg,
            type: "error",
          })
        );
      })
      .then(() =>
        setTimeout(() => {
          dispatch(setErrorMessage({}));
        }, 3000)
      )
      .then(() => dispatch(hideLoading()));
  };
}

// Handling stop impersonation
export function handleStopImpersonation() {
  return (dispatch) => {
    dispatch(showLoading());
    setTimeout(() => {
      dispatch(closeImpersonation());

      dispatch(
        setErrorMessage({
          message: "Impersonation Ended Successfully",
          type: "info",
        })
      );

      setTimeout(() => {
        dispatch(setErrorMessage({}));
      }, 3000);

      dispatch(hideLoading());
    }, 2000);
  };
}

// Handling user profile create request
export function handleCreateUserProfileData(user, cb_handleSaveSuccess) {
  return (dispatch) => {
    dispatch(showLoading());

    createUserProfileRequest(user)
      .then(() => {
        cb_handleSaveSuccess();
      })
      .then(() =>
        dispatch(
          setErrorMessage({
            message: "User Profile Created Successfully",
            type: "info",
          })
        )
      )
      .catch((err) => {
        let msg = "Failed to Create User Profile";
        if (err && err.response && err.response.data) {
          msg = JSON.stringify(err.response.data).replace(/"/g, "");
        }
        dispatch(
          setErrorMessage({
            message: msg,
            type: "error",
          })
        );
      })
      .then(() =>
        setTimeout(() => {
          dispatch(setErrorMessage({}));
        }, 3000)
      )
      .then(() => dispatch(hideLoading()));
  };
}

// Handling company profile create request
export function handleCreateCompanyProfileData(company, cb_handleSaveSuccess) {
  return (dispatch) => {
    dispatch(showLoading());

    createCompanyProfileRequest(company)
      .then((res) => {
        dispatch(addCompany(res.data));
        cb_handleSaveSuccess();
      })
      .then(() =>
        dispatch(
          setErrorMessage({
            message: "Company Profile Created Successfully",
            type: "info",
          })
        )
      )
      .catch((err) => {
        let msg = "Failed to Create Company Profile";
        if (err && err.response && err.response.data) {
          msg = JSON.stringify(err.response.data).replace(/"/g, "");
        }
        dispatch(
          setErrorMessage({
            message: msg,
            type: "error",
          })
        );
      })
      .then(() =>
        setTimeout(() => {
          dispatch(setErrorMessage({}));
        }, 3000)
      )
      .then(() => dispatch(hideLoading()));
  };
}

// Handling get restoration sites data request
export function handleGetRestorationSitesData() {
  return (dispatch) => {
    dispatch(showLoading());

    getAllRestorationSitesRequest()
      .then((res) => {
        dispatch(getRestorationSitesData(res.data));
      })
      .then(() =>
        dispatch(
          setErrorMessage({
            message: "Restoration Sites Fetched Successfully",
            type: "info",
          })
        )
      )
      .catch((err) => {
        let msg = "Failed to Fetch Restoration Sites";
        if (err && err.response && err.response.data) {
          msg = JSON.stringify(err.response.data).replace(/"/g, "");
        }
        dispatch(
          setErrorMessage({
            message: msg,
            type: "error",
          })
        );
      })
      .then(() =>
        setTimeout(() => {
          dispatch(setErrorMessage({}));
        }, 3000)
      )
      .then(() => dispatch(hideLoading()));
  };
}

// Handling get admin companies data request
export function handleGetAdminComapniesData() {
  return (dispatch) => {
    dispatch(showLoading());

    getAllAdminCompaniesRequest()
      .then((res) => {
        dispatch(getAdminCompanies(res.data));
      })
      .then(() =>
        dispatch(
          setErrorMessage({
            message: "Admin Companies Fetched Successfully",
            type: "info",
          })
        )
      )
      .catch((err) => {
        let msg = "Failed to Fetch Admin Companies";
        if (err && err.response && err.response.data) {
          msg = JSON.stringify(err.response.data).replace(/"/g, "");
        }
        dispatch(
          setErrorMessage({
            message: msg,
            type: "error",
          })
        );
      })
      .then(() =>
        setTimeout(() => {
          dispatch(setErrorMessage({}));
        }, 3000)
      )
      .then(() => dispatch(hideLoading()));
  };
}

// Handling get companies data request
export function handleGetComapniesData() {
  return (dispatch) => {
    dispatch(showLoading());

    getAllCompaniesRequest()
      .then((res) => {
        dispatch(getCompanies(res.data));
      })
      .then(() =>
        dispatch(
          setErrorMessage({
            message: "Companies Fetched Successfully",
            type: "info",
          })
        )
      )
      .catch((err) => {
        let msg = "Failed to Fetch Companies";
        if (err && err.response && err.response.data) {
          msg = JSON.stringify(err.response.data).replace(/"/g, "");
        }
        dispatch(
          setErrorMessage({
            message: msg,
            type: "error",
          })
        );
      })
      .then(() =>
        setTimeout(() => {
          dispatch(setErrorMessage({}));
        }, 3000)
      )
      .then(() => dispatch(hideLoading()));
  };
}

// Handling post release trees from escrow request
export function handleReleaseTreeFromEscrow(params, cb_handleSuccess) {
  return (dispatch) => {
    dispatch(showLoading());

    releaseTreesFromEscrowRequest(params)
      .then((res) => {
        // dispatch(getCompanies(res.data));
        cb_handleSuccess();
        return res.data;
      })
      .then((msg) =>
        dispatch(
          setErrorMessage({
            message: msg,
            type: "info",
          })
        )
      )
      .catch((err) => {
        cb_handleSuccess();

        let msg = "Failed to Release Trees From Escrow";
        if (err && err.response && err.response.data) {
          msg = JSON.stringify(err.response.data).replace(/"/g, "");
        }
        dispatch(
          setErrorMessage({
            message: msg,
            type: "error",
          })
        );
      })
      .then(() =>
        setTimeout(() => {
          dispatch(setErrorMessage({}));
        }, 3000)
      )
      .then(() => dispatch(hideLoading()));
  };
}

// Handling download companies report doc blob request
export function handleDownloadCompaniesReport() {
  return (dispatch) => {
    dispatch(showLoading());
    downloadCompaniesReportBlobRequest()
      .then((res) => {
        downloadFromBlob(res.data, "Companies Report.csv");
      })
      .then(() =>
        dispatch(
          setErrorMessage({
            message: "Companies Report Downloaded Successfully",
            type: "info",
          })
        )
      )
      .catch((err) => {
        let msg = "Failed To Download Companies Report";
        if (err && err.response && err.response.data) {
          msg = JSON.stringify(err.response.data).replace(/"/g, "");
        }
        dispatch(
          setErrorMessage({
            message: msg,
            type: "error",
          })
        );
      })
      .then(() =>
        setTimeout(() => {
          dispatch(setErrorMessage({}));
        }, 3000)
      )
      .then(() => dispatch(hideLoading()));
  };
}
