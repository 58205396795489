const markerTooltipRenderer = (marker) => {
  const tooltipContent = document.createElement("div");
  tooltipContent.innerHTML = marker?.company;
  tooltipContent.classList.add("font-bold");
  tooltipContent.classList.add("text-xl");

  return tooltipContent;
};

export default markerTooltipRenderer;
