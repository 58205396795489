import { connect } from "react-redux";
import {
  openModal,
  idImagePreviewer,
  addParam,
  paramKeyImageUrl,
} from "../../../actions/modals";
import ImagePreviewerModal from "./ImagePreviewerModal";
import { getImageUrlOfAwsToQuestionMark } from "../../../utils/helper";

function SpeciesPics({ tree_data, dispatch }) {
  return (
    <div className="w-full h-full">
      <ImagePreviewerModal />
      {tree_data && (
        <div className="grid grid-cols-4 grid-rows-1 auto-cols-fr auto-rows-fr gap-2">
          {tree_data.map((record) =>
            record?.tree?.tree_media.map((pic) => (
              <div
                onClick={() => {
                  dispatch(
                    addParam({
                      key: paramKeyImageUrl,
                      value: getImageUrlOfAwsToQuestionMark(pic?.image),
                    })
                  );
                  dispatch(openModal(idImagePreviewer));
                }}
                key={pic?.id}
                className="relative cursor-pointer col-span-1 row-span-1 px-2"
              >
                <img
                  className="w-24 h-24 rounded-xl"
                  src={getImageUrlOfAwsToQuestionMark(pic?.image)}
                  alt={pic?.image_name}
                />
                <div className="absolute top-0 left-0 w-24 h-24 flex justify-center items-center bg-black bg-opacity-70 opacity-0 hover:opacity-100 rounded-xl mx-2">
                  <div className="flex items-center gap-x-1">
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.01675 2.84375C7.01675 2.73601 6.97395 2.63267 6.89776 2.55649C6.82158 2.4803 6.71824 2.4375 6.6105 2.4375H1.21875C0.895517 2.4375 0.585524 2.5659 0.356964 2.79446C0.128404 3.02302 0 3.33302 0 3.65625L0 11.7812C0 12.1045 0.128404 12.4145 0.356964 12.643C0.585524 12.8716 0.895517 13 1.21875 13H9.34375C9.66698 13 9.97698 12.8716 10.2055 12.643C10.4341 12.4145 10.5625 12.1045 10.5625 11.7812V6.3895C10.5625 6.28176 10.5197 6.17842 10.4435 6.10224C10.3673 6.02605 10.264 5.98325 10.1562 5.98325C10.0485 5.98325 9.94517 6.02605 9.86899 6.10224C9.7928 6.17842 9.75 6.28176 9.75 6.3895V11.7812C9.75 11.889 9.7072 11.9923 9.63101 12.0685C9.55483 12.1447 9.45149 12.1875 9.34375 12.1875H1.21875C1.11101 12.1875 1.00767 12.1447 0.931488 12.0685C0.855301 11.9923 0.8125 11.889 0.8125 11.7812V3.65625C0.8125 3.54851 0.855301 3.44517 0.931488 3.36899C1.00767 3.2928 1.11101 3.25 1.21875 3.25H6.6105C6.71824 3.25 6.82158 3.2072 6.89776 3.13101C6.97395 3.05483 7.01675 2.95149 7.01675 2.84375Z"
                        fill="white"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M13 0.40625C13 0.298506 12.9572 0.195175 12.881 0.118988C12.8049 0.0428012 12.7015 0 12.5938 0L8.53128 0C8.42353 0 8.3202 0.0428012 8.24401 0.118988C8.16783 0.195175 8.12503 0.298506 8.12503 0.40625C8.12503 0.513994 8.16783 0.617325 8.24401 0.693512C8.3202 0.769699 8.42353 0.8125 8.53128 0.8125H11.6131L4.99365 7.43112C4.95588 7.4689 4.92592 7.51374 4.90547 7.56309C4.88503 7.61244 4.87451 7.66533 4.87451 7.71875C4.87451 7.77217 4.88503 7.82506 4.90547 7.87441C4.92592 7.92376 4.95588 7.9686 4.99365 8.00638C5.03142 8.04415 5.07626 8.07411 5.12561 8.09455C5.17496 8.11499 5.22786 8.12551 5.28128 8.12551C5.33469 8.12551 5.38759 8.11499 5.43694 8.09455C5.48629 8.07411 5.53113 8.04415 5.5689 8.00638L12.1875 1.38694V4.46875C12.1875 4.57649 12.2303 4.67983 12.3065 4.75601C12.3827 4.8322 12.486 4.875 12.5938 4.875C12.7015 4.875 12.8049 4.8322 12.881 4.75601C12.9572 4.67983 13 4.57649 13 4.46875V0.40625Z"
                        fill="white"
                      />
                    </svg>
                    <span className="text-white">Open</span>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
}

export default connect()(SpeciesPics);
