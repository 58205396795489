import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import "../../App.css";

import { IoClose } from "react-icons/io5";

import { handleAddAuthorizedUser } from "../../actions/authorizedUsers";

import ModalSelect from "../common/ModalSelect";

function InviteUserModal({
  open,
  closeModal,
  dispatch,
  cb_handleCloseModal,
  adminPanel,
  companyId,
}) {
  const cancelButtonRef = useRef();
  const [email, setEmail] = useState("");
  const [isAddingUser, setIsAddingUser] = useState(false);

  const [selectedCompany, setSelectedCompany] = useState({
    id: 0,
    name: "Empty",
  });

  useEffect(() => {
    const company =
      companyId &&
      adminPanel?.companies &&
      adminPanel?.companies.results.length > 0
        ? adminPanel?.companies.results.filter(
            (companyObj) => companyObj.id === companyId
          )
        : null;
    if (company && company.length > 0) {
      setSelectedCompany(company[0]);
    }
  }, [open]);

  const handleCloseModal = () => {
    cb_handleCloseModal();
    closeModal();
  };

  const handleClickInvite = () => {
    setIsAddingUser(true);
    dispatch(
      handleAddAuthorizedUser(
        {
          email,
          company_id: selectedCompany.id === 0 ? null : selectedCompany.id,
        },
        handleAddAuthorizedUsersSuccess,
        handleAddAuthorizedUsersFailure
      )
    );
  };

  const handleAddAuthorizedUsersSuccess = () => {
    setIsAddingUser(false);
    handleCloseModal();
  };

  const handleAddAuthorizedUsersFailure = () => {
    setIsAddingUser(false);
    handleCloseModal();
  };

  return (
    <>
      <Transition show={open} as={Fragment}>
        <Dialog
          as="div"
          className="text-sm font-inter bg-black bg-opacity-50 fixed inset-0 z-20 overflow-y-auto"
          initialFocus={cancelButtonRef}
          static
          open={open}
          onClose={handleCloseModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full sm:w-2/3 md:w-3/5 xl:w-6/12 2xl:w-1/3 transition transition-width ease-in duration-700 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="z-10 h-0 absolute top-0 left-0 bg-transparent py-4">
                  <input
                    className="z-10 h-0 cursor-auto bg-my-gray-300 placeholder-my-gray-300 "
                    placeholder=""
                  />
                </div>
                <div className="relative flex justify-between items-center w-full h-20 rounded-tl-xl rounded-tr-xl bg-my-green-400 px-8">
                  <p className="capitalize text-my-gray-900 font-bold text-lg">
                    Invite User to Platform
                  </p>
                  <div>
                    <span
                      ref={cancelButtonRef}
                      onClick={handleCloseModal}
                      className="cursor-pointer font-medium text-white text-xs hover:text-opacity-75"
                    >
                      <IoClose
                        size={25}
                        className="text-my-gray-400 hover:text-my-red-100"
                      />
                    </span>
                  </div>
                </div>
                <div className="text-my-gray-900 px-16 mt-10">
                  <p className="font-medium tracking-wide text-my-gray-400">
                    Add an email below, and we will send an invite to the
                    platform.
                  </p>
                  <div className="grid grid-cols-12 gap-4 text-my-gray-900 mt-16 mb-8">
                    <div className="col-span-12 md:col-span-6 flex flex-col justify-start w-full">
                      <p className="font-medium tracking-wider">Email:</p>
                      <input
                        className="h-10 w-full font-bold outline-none bg-my-gray-201 disabled:bg-my-gray-101 rounded-md px-6 mt-1"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="col-span-12 md:col-span-6 flex gap-y-1 flex-col justify-start">
                      <p className="font-medium tracking-wider">Company:</p>
                      <div className="z-10 h-10">
                        <ModalSelect
                          isDisabled={companyId}
                          options={
                            adminPanel?.companies
                              ? [
                                  { id: 0, name: "Empty" },
                                  ...adminPanel?.companies?.results,
                                ]
                              : [{ id: 0, name: "Empty" }]
                          }
                          selected={selectedCompany}
                          setSelected={setSelectedCompany}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="relative flex justify-end items-center w-full h-20 rounded-bl-xl rounded-br-xl bg-my-green-400">
                  <div className="cursor-pointer flex justify-center items-center w-42 lg:w-52 h-12 tracking-widest text-my-sky-blue-200 font-bold rounded-md border-2 border-my-sky-blue-200 bg-white my-4 mr-4">
                    <p className="uppercase mx-3">cancel</p>
                  </div>
                  <div
                    onClick={handleClickInvite}
                    className="cursor-pointer flex justify-center items-center w-62 lg:w-72 h-12 tracking-widest text-white font-bold rounded-md bg-gradient-to-b from-my-sky-blue-100 to-my-sky-blue-200 my-4 mr-4"
                  >
                    {isAddingUser && (
                      <svg
                        className="animate-spin mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                    <p className="uppercase mx-3">send invitation</p>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

const mapStateToProps = ({ adminPanel }) => ({
  adminPanel,
});

export default connect(mapStateToProps)(InviteUserModal);
