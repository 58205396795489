import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import classnames from "classnames";

import { IoClose } from "react-icons/io5";

function FilterModal({
  open,
  closeModal,
  setTableFilter,
  filter_id,
  setFilter_Id,
  filter_customerIdNumber,
  setFilter_CustomerIdNumber,
  filter_printOrderNumber,
  setFilter_PrintOrderNumber,
  filter_invoiceNumber,
  setFilter_InvoiceNumber,
  filter_printOrderType,
  setFilter_PrintOrderType,
  filter_serviceProvider,
  setFilter_ServiceProvider,
  filter_facility,
  setFilter_Facility,
  filter_certificateNumber,
  setFilter_CertificateNumber,
}) {
  const cancelButtonRef = useRef();
  const [id, setId] = useState("");
  const [customerIdNumber, setCustomerIdNumber] = useState("");
  const [printOrderNumber, setPrintOrderNumber] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [printOrderType, setPrintOrderType] = useState("");
  const [serviceProvider, setServiceProvider] = useState("");
  const [facility, setFacility] = useState("");
  const [certificateNumber, setCertificateNumber] = useState("");

  useEffect(() => {
    setId(filter_id);
    setCustomerIdNumber(filter_customerIdNumber);
    setPrintOrderNumber(filter_printOrderNumber);
    setInvoiceNumber(filter_invoiceNumber);
    setPrintOrderType(filter_printOrderType);
    setServiceProvider(filter_serviceProvider);
    setFacility(filter_facility);
    setCertificateNumber(filter_certificateNumber);
  }, []);

  const handleSearch = () => {
    let filterString = "";

    setFilter_Id(id);
    setFilter_CustomerIdNumber(customerIdNumber);
    setFilter_PrintOrderNumber(printOrderNumber);
    setFilter_InvoiceNumber(invoiceNumber);
    setFilter_PrintOrderType(printOrderType);
    setFilter_ServiceProvider(serviceProvider);
    setFilter_Facility(facility);
    setFilter_CertificateNumber(certificateNumber);

    if (id && id !== "") {
      filterString += `transaction__id=${id}&`;
    }
    if (customerIdNumber && customerIdNumber !== "") {
      filterString += `transaction__customer_id_number=${customerIdNumber}&`;
    }
    if (printOrderNumber && printOrderNumber !== "") {
      filterString += `transaction__print_order_number=${printOrderNumber}&`;
    }
    if (invoiceNumber && invoiceNumber !== "") {
      filterString += `transaction__invoice_number=${invoiceNumber}&`;
    }
    if (printOrderType && printOrderType !== "") {
      filterString += `transaction__print_order_type=${printOrderType}&`;
    }
    if (serviceProvider && serviceProvider !== "") {
      filterString += `transaction__service_provider=${serviceProvider}&`;
    }
    if (facility && facility !== "") {
      filterString += `transaction__facility=${facility}&`;
    }
    if (certificateNumber && certificateNumber !== "") {
      filterString += `transaction__certificate_number=${certificateNumber}&`;
    }
    if (filterString !== "") {
      filterString = filterString.substr(0, filterString.length - 1);
    } else {
      filterString = undefined;
    }
    setTableFilter(filterString);
    closeModal();
  };

  return (
    <>
      <Transition show={open} as={Fragment}>
        <Dialog
          as="div"
          className="font-inter bg-black bg-opacity-50 fixed inset-0 z-20 overflow-y-auto"
          initialFocus={cancelButtonRef}
          static
          open={open}
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full sm:w-2/3 md:w-3/5 xl:w-6/12 2xl:w-6/12 transition transition-width ease-in duration-700 my-8 text-sm text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="z-10 h-0 absolute top-0 left-0 bg-transparent py-4">
                  <input
                    className="z-10 h-0 cursor-auto bg-my-gray-300 placeholder-my-gray-300 "
                    placeholder=""
                  />
                </div>
                <div className="relative flex justify-between items-center w-full h-20 rounded-tl-xl rounded-tr-xl bg-my-green-400 px-8">
                  <p className="text-my-gray-900 font-bold text-lg">
                    Restoration Data Filters
                  </p>
                  <div>
                    <span
                      ref={cancelButtonRef}
                      onClick={closeModal}
                      className="cursor-pointer font-medium text-white hover:text-opacity-75"
                    >
                      <IoClose
                        size={25}
                        className="text-my-gray-400 hover:text-my-red-100"
                      />
                    </span>
                  </div>
                </div>
                <div className="w-full h-full px-8 md:px-16 my-8 text-xxs md:text-sm">
                  <div className="text-my-gray-900 mt-10">
                    <div className="grid grid-cols-12 gap-x-4 gap-y-4 w-full text-my-gray-900">
                      <div className="col-span-3 flex flex-col justify-start">
                        <p className="capitalize font-medium text-my-gray-400 tracking-wider">
                          ID
                        </p>
                        <input
                          value={id}
                          onChange={(e) => setId(e.target.value)}
                          className="h-10 w-full font-bold outline-none border border-my-gray-201 rounded-lg px-4 mt-1"
                        />
                      </div>
                      <div className="col-span-9 md:col-span-6 flex justify-center items-center gap-x-3">
                        <div className="flex flex-col justify-start">
                          <p className="capitalize font-medium text-my-gray-400 tracking-wider">
                            Date Range
                          </p>
                          <input
                            disabled
                            className="h-10 w-full font-bold outline-none border border-my-gray-201 rounded-lg px-4 mt-1"
                          />
                        </div>
                        <span className="self-center mt-4">to</span>
                        <div className="self-end flex flex-col justify-start">
                          <input
                            disabled
                            className="h-10 w-full font-bold outline-none border border-my-gray-201 rounded-lg px-4 mt-1"
                          />
                        </div>
                      </div>
                      <div className="col-span-6 md:col-span-3 flex flex-col justify-start">
                        <p className="capitalize font-medium text-my-gray-400 tracking-wider">
                          Customer ID / Number
                        </p>
                        <input
                          value={customerIdNumber}
                          onChange={(e) => setCustomerIdNumber(e.target.value)}
                          className="h-10 w-full font-bold outline-none border border-my-gray-201 rounded-lg px-4 mt-1"
                        />
                      </div>
                      <div className="col-span-6 md:col-span-4 flex flex-col justify-start">
                        <p className="capitalize font-medium text-my-gray-400 tracking-wider">
                          Print Order Number
                        </p>
                        <input
                          value={printOrderNumber}
                          onChange={(e) => setPrintOrderNumber(e.target.value)}
                          className="h-10 w-full font-bold outline-none border border-my-gray-201 rounded-lg px-4 mt-1"
                        />
                      </div>
                      <div className="col-span-5 md:col-span-2 flex flex-col justify-start">
                        <p className="capitalize font-medium text-my-gray-400 tracking-wider">
                          Invoice Number
                        </p>
                        <input
                          value={invoiceNumber}
                          onChange={(e) => setInvoiceNumber(e.target.value)}
                          className="h-10 w-full font-bold outline-none border border-my-gray-201 rounded-lg px-4 mt-1"
                        />
                      </div>
                      <div className="col-span-5 md:col-span-3 flex flex-col justify-start">
                        <p className="capitalize font-medium text-my-gray-400 tracking-wider">
                          Print Order Type
                        </p>
                        <input
                          value={printOrderType}
                          onChange={(e) => setPrintOrderType(e.target.value)}
                          className="h-10 w-full font-bold outline-none border border-my-gray-201 rounded-lg px-4 mt-1"
                        />
                      </div>
                      <div className="col-span-5 md:col-span-3 flex flex-col justify-start">
                        <p className="capitalize font-medium text-my-gray-400 tracking-wider">
                          Service Provider
                        </p>
                        <input
                          value={serviceProvider}
                          onChange={(e) => setServiceProvider(e.target.value)}
                          className="h-10 w-full font-bold outline-none border border-my-gray-201 rounded-lg px-4 mt-1"
                        />
                      </div>
                      <div className="col-span-4 md:col-span-2 flex flex-col justify-start">
                        <p className="capitalize font-medium text-my-gray-400 tracking-wider">
                          Facility
                        </p>
                        <input
                          value={facility}
                          onChange={(e) => setFacility(e.target.value)}
                          className="h-10 w-full font-bold outline-none border border-my-gray-201 rounded-lg px-4 mt-1"
                        />
                      </div>
                      <div className="col-span-5 md:col-span-3 flex flex-col justify-start">
                        <p className="capitalize font-medium text-my-gray-400 tracking-wider">
                          Certificate Number
                        </p>
                        <input
                          value={certificateNumber}
                          onChange={(e) => setCertificateNumber(e.target.value)}
                          className="h-10 w-full font-bold outline-none border border-my-gray-201 rounded-lg px-4 mt-1"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="relative flex justify-end items-center w-full h-20 rounded-bl-xl rounded-br-xl bg-my-green-400">
                  <div
                    onClick={closeModal}
                    className="cursor-pointer flex justify-center items-center w-42 lg:w-52 h-12 tracking-widest text-my-sky-blue-200 font-bold rounded-md border-2 border-my-sky-blue-200 bg-white my-4 mr-4"
                  >
                    <p className="uppercase mx-3">cancel</p>
                  </div>
                  <div
                    onClick={handleSearch}
                    className="cursor-pointer flex justify-center items-center w-62 lg:w-72 h-12 tracking-widest text-white font-bold rounded-md bg-gradient-to-b from-my-sky-blue-100 to-my-sky-blue-200 my-4 mr-4"
                  >
                    <p className="uppercase mx-3">Search</p>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default connect()(FilterModal);
