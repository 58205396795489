import axios from "axios";

import { showLoading, hideLoading } from "react-redux-loading";
import {
  getGraphDataRequest,
  getFilteredGraphDataRequest,
  getGraphSummaryDataRequest,
} from "../utils/api";
import { setErrorMessage } from "./shared";

// Actions declaration
export const GET_GRAPH_DATA = "GET_GRAPH_DATA";
export const GET_GRAPH_SUMMARY_DATA = "GET_GRAPH_SUMMARY_DATA";

// Action functions for updating the store
export function getGraphData(graphData) {
  return {
    type: GET_GRAPH_DATA,
    graphData,
  };
}

export function getGraphSummaryData(graphSummaryData) {
  return {
    type: GET_GRAPH_SUMMARY_DATA,
    graphSummaryData,
  };
}

// Handling fetch graph data request
export function handleGetGraphData() {
  return (dispatch, getState) => {
    dispatch(showLoading());
    const { authedUser, adminPanel } = getState();
    getGraphDataRequest(
      adminPanel?.isImpersonating ? adminPanel?.client?.id : authedUser?.id
    )
      .then((response) => {
        dispatch(
          getGraphData({
            ...response.data,
            flag: JSON.parse(response.config.data).flag,
          })
        );
      })
      .then(() =>
        dispatch(
          setErrorMessage({
            message: "Graph Data Fetched Successfully",
            type: "info",
          })
        )
      )
      .catch((err) => {
        let msg = "Failed to Fetch Graph Data";
        if (err && err.response && err.response.data) {
          msg = JSON.stringify(err.response.data).replace(/"/g, "");
        }
        dispatch(
          setErrorMessage({
            message: msg,
            type: "error",
          })
        );
      })
      .then(() =>
        setTimeout(() => {
          dispatch(setErrorMessage({}));
        }, 3000)
      )
      .then(() => dispatch(hideLoading()));
  };
}

// Handling fetch graph data request
export function handleGetGraphSummaryData({ flag, value }, showMessage = true) {
  return (dispatch, getState) => {
    dispatch(showLoading());
    const { adminPanel } = getState();
    const object = {
      flag,
      value,
      userId: adminPanel?.isImpersonating ? adminPanel?.client?.id : undefined,
    };
    getGraphSummaryDataRequest(object)
      .then((response) => {
        dispatch(getGraphSummaryData(response.data));
      })
      .then(() => {
        if (showMessage) {
          dispatch(
            setErrorMessage({
              message: "Graph Summary Data Fetched Successfully",
              type: "info",
            })
          );
        }
      })
      .catch((err) => {
        let msg = "Failed to Fetch Graph Summary Data";
        if (err && err.response && err.response.data) {
          msg = JSON.stringify(err.response.data).replace(/"/g, "");
        }
        dispatch(
          setErrorMessage({
            message: msg,
            type: "error",
          })
        );
      })
      .then(() =>
        setTimeout(() => {
          dispatch(setErrorMessage({}));
        }, 3000)
      )
      .then(() => dispatch(hideLoading()));
  };
}

// Handling fetch filtered graph data request
export function handleGetFilteredGraphData(
  { flag, value },
  showMessage = true
) {
  return (dispatch, getState) => {
    dispatch(showLoading());
    const { authedUser, adminPanel } = getState();
    getFilteredGraphDataRequest({
      flag,
      value,
      u_id: adminPanel?.isImpersonating
        ? adminPanel?.client?.id
        : authedUser?.id,
    })
      .then((response) =>
        dispatch(
          getGraphData({
            ...response.data,
            flag: JSON.parse(response.config.data).flag,
          })
        )
      )
      .then(() => {
        if (showMessage) {
          dispatch(
            setErrorMessage({
              message: "Graph Data Fetched Successfully",
              type: "info",
            })
          );
        }
      })
      .catch((err) => {
        let msg = "Failed to Fetch Graph Data";
        if (err && err.response && err.response.data) {
          msg = JSON.stringify(err.response.data).replace(/"/g, "");
        }
        dispatch(
          setErrorMessage({
            message: msg,
            type: "error",
          })
        );
      })
      .then(() =>
        setTimeout(() => {
          dispatch(setErrorMessage({}));
        }, 3000)
      )
      .then(() => dispatch(hideLoading()));
  };
}
