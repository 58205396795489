import { useState, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import moment from "moment";
import customizeIcon from "../../assets/icons/dashboard/table/customize.svg";
import arrowDownIcon from "../../assets/icons/dashboard/table/arrowDown.svg";
import arrowUpIcon from "../../assets/icons/dashboard/table/arrowUp.svg";
import graphIcon from "../../assets/icons/dashboard/table/graph.svg";
import sortArrowDownIcon from "../../assets/icons/dashboard/table/sortArrowDown.svg";
import sortArrowUpIcon from "../../assets/icons/dashboard/table/sortArrowUp.svg";

import useWindowDimensions from "../main/useWindowDimensions";

import MyTable from "./MyTable";

import {
  checkIsNumberAndHasDecimal,
  aroundDecimalValueToOneDecimal,
  numberWithCommas,
} from "../../utils/helper";

import Tippy from "@tippyjs/react";

import { handleImpersonation } from "../../actions/adminPanel";

function MyClientsTable({
  columnsInit,
  columnsClientsInit,
  limit,
  offsetInit,
  handleGetData,
  handleGetClientData,
  openCustomizeTableColumnsModal,
  tableData,
  openCertificateModal,
  openAddNewUserModal,
  dispatch,
  restorationDataPerUser,
  dropDownData,
  companyId,
}) {
  const { isSmScreen } = useWindowDimensions();

  const [columns, setColumns] = useState(columnsClientsInit);
  const [data, setData] = useState([]);
  const [pages, setPages] = useState(1);
  const [offset, setOffset] = useState(offsetInit);
  const [selectedPage, setSelectedPage] = useState(1);
  const [expandedRecords, setExpandedRecords] = useState([]);
  const [sortedColumn, setSortedColumn] = useState(null);

  useEffect(() => {
    if (tableData?.results) {
      setData(tableData?.results);
    }
  }, [tableData]);

  useEffect(() => {
    dispatch(handleGetClientData(companyId));
  }, [companyId]);

  // useEffect(() => {
  //
  //   if (tableData?.count) {
  //     setData(tableData?.results);
  //     const numPages = Math.ceil(tableData?.count / Number(limit));
  //     const pages = [];
  //     for (let i = 1; i <= numPages; i++) {
  //       pages.push(i);
  //     }

  //     setPages(pages);

  //     let offset = undefined;

  //     if (tableData?.current && tableData?.current.includes("&")) {
  //       offset = Number(
  //         tableData?.current.split("&")[1].replace("offset=", "")
  //       );
  //     }

  //     if (offset !== undefined) {
  //       setOffset(offset);
  //       setSelectedPage(offset / limit + 1);
  //     }
  //   }
  // }, [tableData]);

  const handleSorting = (id) => {
    const col = columns.filter((column) => column.id === id)[0];
    if (sortedColumn === null || sortedColumn.id !== col.id) {
      setSortedColumn({ ...col, order: "asc" });
    } else {
      if (sortedColumn.order === "asc") {
        setSortedColumn({ ...sortedColumn, order: "desc" });
      } else {
        setSortedColumn(null);
      }
    }
  };

  const handleApplyChanges = ({ items, selected }) => {
    const leftColumns = items.map((item) => ({ ...item, isVisible: true }));
    const rightColumns = selected.map((item) => ({
      ...item,
      isVisible: false,
    }));
    setColumns([
      {
        id: "sn",
        content: "SN",
        isVisible: true,
      },
      ...leftColumns,
      ...rightColumns,
    ]);
  };

  const handleClickedRow = (id) => {
    if (expandedRecords.includes(id)) {
      setExpandedRecords(expandedRecords.filter((expId) => expId !== id));
    } else {
      setExpandedRecords([...expandedRecords, id]);
    }
  };

  const visibleColumns = columns.filter((column) => column.isVisible === true);

  const inVisibleColumns = columns.filter(
    (column) => column.isVisible === false
  );
  return (
    // <Fragment className="row-start-1 row-end-25 grid grid-rows-24 gap-y-1">
    <Fragment>
      <div className="row-start-1 row-end-23 w-full rounded-xl overflow-y-auto scrollbar scrollbar-thumb-transparent scrollbar-track-transparent">
        <table className="min-w-full text-xxs md:text-xs">
          <thead className="sticky z-10 top-0 shadow-md">
            <tr className="py-4 bg-my-green-400">
              {visibleColumns.map((column, index) => (
                <th
                  key={column.id}
                  scope="col"
                  className={classnames(
                    "pl-3 pr-2 py-4 text-left font-medium tracking-wider border-my-gray-102 border-r",
                    { "rounded-tl-lg": index === 0 }
                  )}
                >
                  <span
                    onClick={() => {
                      if (
                        sortedColumn === null ||
                        sortedColumn.id !== column.id
                      ) {
                        handleSorting(column.id);
                      }
                    }}
                    className={classnames(
                      "flex justify-between items-center h-full",
                      {
                        "cursor-pointer":
                          sortedColumn === null ||
                          sortedColumn.id !== column.id,
                      }
                    )}
                  >
                    <Tippy
                      arrow={true}
                      placement="top"
                      content={column.content}
                    >
                      <span className="capitalized">{column.content}</span>
                    </Tippy>

                    <span
                      onClick={() => handleSorting(column.id)}
                      className="cursor-pointer flex justify-center items-center w-3 h-3"
                    >
                      {sortedColumn?.id === column.id &&
                      sortedColumn.order === "asc" ? (
                        <img
                          style={{
                            height: isSmScreen ? 15 : 20,
                            width: isSmScreen ? 15 : 20,
                          }}
                          src={sortArrowUpIcon}
                          alt="sortArrowUp"
                        />
                      ) : sortedColumn?.id === column.id &&
                        sortedColumn.order === "desc" ? (
                        <img
                          style={{
                            height: isSmScreen ? 15 : 20,
                            width: isSmScreen ? 15 : 20,
                          }}
                          src={sortArrowDownIcon}
                          alt="sortArrowDown"
                        />
                      ) : (
                        <img
                          style={{
                            height: isSmScreen ? 15 : 20,
                            width: isSmScreen ? 15 : 20,
                          }}
                          className="opacity-1"
                          src={sortArrowDownIcon}
                          alt="sortArrowDown"
                        />
                      )}
                    </span>
                  </span>
                </th>
              ))}

              <th
                onClick={() => openAddNewUserModal(companyId)}
                scope="col"
                className={classnames(
                  "cursor-pointer pl-12 py-4 text-left font-bold text-xs md:text-sm tracking-wider bg-gradient-to-tl from-my-green-102 to-my-green-101 rounded-tr-lg",
                  { "rounded-tl-lg": visibleColumns.length === 0 }
                )}
              >
                <span className="flex justify-center items-center gap-x-6 h-full text-white">
                  <span className="uppercase">add new</span>
                  <img
                    style={{
                      height: isSmScreen ? 15 : 20,
                      width: isSmScreen ? 15 : 20,
                    }}
                    className="mx-4"
                    src={customizeIcon}
                    alt="customize"
                  />
                </span>
              </th>
            </tr>
          </thead>
          <tbody className="font-medium">
            {data
              .sort((a, b) => a?.id - b?.id)
              .map((record, index) => (
                <Fragment key={record?.id}>
                  <tr
                    key={record?.id}
                    className={classnames("w-full mt-4", {
                      "bg-my-blue-101": index % 2 !== 0,
                      "bg-white": index % 2 === 0,
                      "z-10": expandedRecords.includes(record?.id),
                      "border-b border-my-gray-102":
                        !expandedRecords.includes(record?.id) &&
                        index !== data.length - 1,
                      "mb-16": expandedRecords.includes(record?.id),
                    })}
                  >
                    {visibleColumns.map((column, colIndex) => (
                      <td
                        key={column.id}
                        className={classnames(
                          "whitespace-nowrap w-max pl-3 py-4",
                          {
                            "rounded-bl-xl":
                              colIndex === 0 &&
                              index === data.length - 1 &&
                              !expandedRecords.includes(record?.id),
                          }
                        )}
                      >
                        {column.id === "sn" ? (
                          <div className="flex flex items-center">
                            {index + 1 + (selectedPage - 1) * limit}
                          </div>
                        ) : column.type === "datetime" ? (
                          <div className="flex flex items-center">
                            {moment
                              .utc(record[column.id])
                              .format("DD/MM/YYYY hh:mm A")}
                          </div>
                        ) : (
                          <div className="flex flex items-center">
                            <p
                              className={classnames({
                                "cursor-pointer text-my-sky-blue-200 border-b-2 border-dotted":
                                  column.id === "certificate_number",
                              })}
                            >
                              {checkIsNumberAndHasDecimal(record[column.id])
                                ? numberWithCommas(
                                    aroundDecimalValueToOneDecimal(
                                      record[column.id]
                                    )
                                  )
                                : !isNaN(record[column.id])
                                ? numberWithCommas(record[column.id])
                                : record[column.id]}
                            </p>
                          </div>
                        )}
                      </td>
                    ))}
                    <td
                      className={classnames(
                        "whitespace-nowrap w-max border-l border-my-gray-102 py-2",
                        {
                          "bg-my-blue-101": index % 2 !== 0,
                          "bg-my-gray-103":
                            index % 2 === 0 &&
                            !expandedRecords.includes(record?.id),
                          "bg-white":
                            index % 2 === 0 &&
                            expandedRecords.includes(record?.id),
                          "rounded-br-xl":
                            index === data.length - 1 &&
                            !expandedRecords.includes(record?.id),
                          "rounded-bl-xl":
                            visibleColumns.length === 0 &&
                            index === data.length - 1 &&
                            !expandedRecords.includes(record?.id),
                        }
                      )}
                    >
                      <div className="flex justify-evenly items-center ml-2">
                        <span
                          onClick={() =>
                            dispatch(
                              handleImpersonation(
                                record.id
                                // {
                                // id: 23,
                                // first_name: "Alex",
                                // last_name: "Henige",
                                // profile_image: null,
                                // company_info: "",
                                // address: "asdas",
                                // phone_number: null,
                                // is_admin: false,
                                // social_login_image:
                                //   "https://lh3.googleusercontent.com/a-/AOh14GjMzXqHAFKxdP5SMzMQVUxRv2Y5i2gNLvpCw9Hz=s96-c"
                                // address: null,
                                // company_info: "null",
                                // first_name: "user3",
                                // id: 28,
                                // is_admin: false,
                                // isGoogle: false,
                                // last_name: "demo",
                                // phone_number: "",
                                // profile_image:
                                //   "https://snowy-forest-26993.s3.amazonaws.com/media/media/profile_image/waiting_XnKbnA3.gif?AWSAccessKeyId=AKIA4KGTUZ6KBXRUZKP2&Signature=EMfuYUSRwbGXN3nPQWe7UhnPHjY%3D&Expires=1632841203",
                                // social_login_image: null
                                // id: 14,
                                // first_name: "Mohamed",
                                // last_name: "Kamal",
                                // profile_image: null,
                                // company_info: null,
                                // address: null,
                                // phone_number: null,
                                // is_admin: false,
                                // isGoogle: true,
                                // social_login_image:
                                //   "https://lh3.googleusercontent.com/a-/AOh14Gjm8DRNQOHL8CsEbsgZmdBeMx_1nmM2Zq5Gm2Ozjg=s96-c"
                                // }
                              )
                            )
                          }
                        >
                          <img
                            style={{
                              height: isSmScreen ? 15 : 20,
                              width: isSmScreen ? 15 : 20,
                            }}
                            className="cursor-pointer"
                            src={graphIcon}
                            alt="graph"
                          />
                        </span>
                        <span
                          className="cursor-pointer"
                          onClick={() => handleClickedRow(record?.id)}
                        >
                          {expandedRecords.includes(record?.id) ? (
                            <img
                              style={{
                                height: isSmScreen ? 15 : 20,
                                width: isSmScreen ? 15 : 20,
                              }}
                              src={arrowUpIcon}
                              alt="arrowUp"
                            />
                          ) : (
                            <img
                              style={{
                                height: isSmScreen ? 15 : 20,
                                width: isSmScreen ? 15 : 20,
                              }}
                              src={arrowDownIcon}
                              alt="arrowDown"
                            />
                          )}
                        </span>
                      </div>
                    </td>
                  </tr>
                  {expandedRecords.includes(record?.id) && (
                    <tr className="w-full">
                      <td colSpan={visibleColumns.length + 1}>
                        <div
                          className={classnames(
                            "grid grid-rows-24 gap-y-1 w-full",
                            {
                              "bg-my-blue-101": index % 2 !== 0,
                              "bg-my-gray-103":
                                index % 2 === 0 &&
                                !expandedRecords.includes(record?.id),
                              // "shadow-cust-small border rounded-xl border-my-green-300":
                              //   expandedRecords.includes(record?.id),
                            }
                          )}
                        >
                          <MyTable
                            columnsInit={columnsInit}
                            limit={limit}
                            offsetInit={offsetInit}
                            openCustomizeTableColumnsModal={
                              openCustomizeTableColumnsModal
                            }
                            openCertificateModal={openCertificateModal}
                            tableData={
                              Object.keys(restorationDataPerUser).length > 0
                                ? restorationDataPerUser[record.id]
                                : {}
                            }
                            handleGetData={handleGetData}
                            search=""
                            userId={record.id}
                            dropDownData={dropDownData}
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))}
          </tbody>
        </table>
      </div>
      {pages.length > 0 && (
        <div className="row-span-2 flex justify-center items-center gap-x-2 text-sm">
          <p
            onClick={() => {
              if (tableData?.previous) {
                dispatch(
                  handleGetClientData({
                    limit,
                    offset: (selectedPage - 2) * limit,
                  })
                );
              }
            }}
            className={classnames({
              "cursor-pointer text-my-sky-blue-200": tableData?.previous,
              "cursor-default text-my-gray-300": !tableData?.previous,
            })}
          >{`< Previous`}</p>

          {pages.map((page) => (
            <span
              onClick={() => {
                if (selectedPage !== page) {
                  dispatch(
                    handleGetClientData({
                      limit,
                      offset: (page - 1) * limit,
                    })
                  );
                }
              }}
              key={page}
              className={classnames("px-1 px-2 py-1 rounded", {
                "cursor-pointer text-my-gray-400 hover:text-white hover:bg-my-green-101 hover:bg-opacity-50":
                  selectedPage !== page,
                "bg-my-green-101 text-white": selectedPage === page,
              })}
            >
              {page}
            </span>
          ))}

          <p
            onClick={() => {
              if (tableData?.next) {
                dispatch(
                  handleGetClientData({
                    limit,
                    offset: selectedPage * limit,
                  })
                );
              }
            }}
            className={classnames({
              "cursor-pointer text-my-sky-blue-200": tableData?.next,
              "cursor-default text-my-gray-300": !tableData?.next,
            })}
          >{`Next >`}</p>
        </div>
      )}
    </Fragment>
  );
}

const mapStateToProps = ({ restorationDataPerUser }) => ({
  restorationDataPerUser,
});

export default connect(mapStateToProps)(MyClientsTable);
