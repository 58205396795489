import React, { Component } from "react";
import UnderContruction from "../../assets/images/under-construction.png";

class NoMatch extends Component {
  render() {
    return (
      <div className="col-start-3 col-end-17 row-start-3 row-end-24 flex justify-center items-center">
        <img src={UnderContruction} alt="under construction" />
      </div>
    );
  }
}

export default NoMatch;
