import { useState } from "react";
import { connect } from "react-redux";

import { handleGetRestorationDataPerUser } from "../../../actions/restorationDataPerUser";
import { handleGetRestorationData } from "../../../actions/restorationData";

import searchIcon from "../../../assets/icons/dashboard/table/search.svg";

import MyTable from "../../common/MyTable";

import { IoFilter } from "react-icons/io5";

const columnsInit = [
  {
    id: "sn",
    content: "SN",
    isVisible: true,
    doFormat: true,
    type: "number",
  },
  {
    id: "id",
    content: "ID",
    isVisible: true,
    doFormat: false,
    type: "number",
  },
  {
    id: "date",
    content: "Date",
    isVisible: true,
    doFormat: true,
    type: "datetime",
  },
  {
    id: "customer_id_number",
    content: "Customer ID / Number",
    isVisible: true,
    doFormat: true,
    type: "text",
  },
  {
    id: "print_order_number",
    content: "Print Order Number",
    isVisible: true,
    doFormat: true,
    type: "text",
  },
  {
    id: "invoice_number",
    content: "Invoice Number",
    isVisible: true,
    doFormat: true,
    type: "text",
  },
  {
    id: "print_order_type",
    content: "Print Order Type",
    isVisible: true,
    doFormat: true,
    type: "text",
  },
  {
    id: "service_provider",
    content: "Service Provider",
    isVisible: true,
    doFormat: true,
    type: "text",
  },
  {
    id: "facility",
    content: "Facility",
    isVisible: true,
    doFormat: true,
    type: "text",
  },
  {
    id: "certificate_number",
    content: "Certificate Number",
    isVisible: true,
    doFormat: true,
    type: "text",
  },
];

const limit = 100;
const offsetInit = 0;

function DataTable({
  openCustomizeTableColumnsModal,
  restorationData,
  restorationDataPerUser,
  adminPanel,
  authedUser,
  dropDownData,
  openCertificateModal,
  openFilterModal,
  tableFilter,
  keepFetchingData,
}) {
  const [search, setSearch] = useState("");
  return (
    <div className="row-start-12 row-end-25 grid grid-rows-24 gap-y-2 w-full px-2 md:px-8">
      <div className="row-span-2 flex gap-x-2 w-full">
        <div className="flex items-center w-full  bg-my-gray-101 border rounded border-my-gray-201">
          <input
            placeholder="Search..."
            className="flex-grow text-xs placeholder-my-gray-400 bg-transparent outline-none px-4"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <span className="w-12 h-full bg-gradient-to-tl from-my-green-102 to-my-green-101 rounded-r py-1">
            <img className="w-full h-full" src={searchIcon} alt="search" />
          </span>
        </div>
        <span
          onClick={openFilterModal}
          className="cursor-pointer relative flex justify-center items-center font-medium text-white hover:text-opacity-75 border rounded px-2"
        >
          {tableFilter && (
            <span className="absolute -top-1 -right-1 w-2 h-2 rounded-full bg-my-sky-blue-100"></span>
          )}
          <IoFilter
            size={20}
            className="text-my-green-300 hover:text-my-green-100"
          />
        </span>
      </div>
      <div className="row-start-3 row-end-25 grid grid-rows-24 gap-y-1 w-full">
        <MyTable
          columnsInit={columnsInit}
          limit={limit}
          offsetInit={offsetInit}
          openCustomizeTableColumnsModal={openCustomizeTableColumnsModal}
          openCertificateModal={openCertificateModal}
          restorationData
          tableData={
            adminPanel?.isImpersonating
              ? restorationDataPerUser[adminPanel?.client?.id]
              : restorationData
          }
          handleGetData={
            adminPanel?.isImpersonating
              ? handleGetRestorationDataPerUser
              : handleGetRestorationData
          }
          search={search}
          dropDownData={dropDownData}
          userId={
            adminPanel?.isImpersonating ? adminPanel?.client?.id : undefined
          }
          tableFilter={tableFilter}
          keepFetchingData={keepFetchingData}
        />
      </div>
    </div>
  );
}

const mapStateToProps = ({
  adminPanel,
  authedUser,
  restorationData,
  restorationDataPerUser,
}) => ({
  adminPanel,
  authedUser,
  restorationData,
  restorationDataPerUser,
});

export default connect(mapStateToProps)(DataTable);
