import React from "react";
import ReactDOM from "react-dom";
import { ChakraProvider } from "@chakra-ui/react";
import "./index.css";
import App from "./components/app/App";
import reportWebVitals from "./reportWebVitals";

import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";

//Redux
import { createStore } from "redux";

// React redux
import { Provider } from "react-redux";

// React router
import { BrowserRouter } from "react-router-dom";

// Middleware
import middleware from "./middleware";

// Reducers
import rootReducer from "./reducers";

import { StyledEngineProvider } from "@mui/material/styles";
import Demo from "./components/dashboard/chart/BasicDateRangePicker";

// Create app store
const store = createStore(rootReducer, middleware);

// ReactDOM.render(
//   <StyledEngineProvider injectFirst>
//     <Demo />
//   </StyledEngineProvider>,
//   document.querySelector("#root")
// );

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        {/* <ChakraProvider> */}
        <StyledEngineProvider injectFirst>
          <App />
          {/* <Demo /> */}
        </StyledEngineProvider>
        {/* </ChakraProvider> */}
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
