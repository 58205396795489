import { connect } from "react-redux";
import classnames from "classnames";
import { NavLink, withRouter } from "react-router-dom";

import logoImage from "../../assets/images/logo_black.svg";

function LeftBar({ match, location, isLeftBarOpen }) {
  return (
    <div
      className={classnames(
        "absolute z-50 md:z-0 top-0 bottom-0 md:static md:col-span-1 lg:col-span-2 row-start-3 row-end-25 transform ease-in-out transition-all duration-1000 bg-my-blue-100 md:bg-transparent",
        {
          "left-0 w-max rounded-l-lg shadow-2xl": isLeftBarOpen === true,
          "-left-full": isLeftBarOpen === false,
        }
      )}
    >
      <div className="grid items-stretch w-full h-full pb-10">
        <div className="self-start flex flex-col gap-2 w-full h-max text-xs text-my-gray-900">
          <NavLink
            className="cursor-pointer w-full bg-white rounded-tr rounded-br py-2"
            to={`${match.url}/dashboard`}
            activeClassName="cursor-default w-full text-white bg-gradient-to-b from-my-sky-blue-100 to-my-sky-blue-200 rounded-tr rounded-br py-2"
          >
            <div className="flex items-center justify-start md:justify-center lg:justify-start pl-6 md:pl-0 lg:pl-2 xl:pl-6">
              <span className="w-4 h-4">
                <svg
                  className="text-current w-full h-full"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 16L4 18C4 19.1046 5.07452 20 6.4 20L7.6 20C8.92548 20 10 19.1046 10 18L10 16C10 14.8954 8.92548 14 7.6 14L6.4 14C5.07452 14 4 14.8954 4 16Z"
                    stroke={
                      location.pathname.includes("dashboard")
                        ? "currentColor"
                        : "#8F92A1"
                    }
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14 16L14 18C14 19.1046 14.7675 20 15.7143 20L18.2857 20C19.2325 20 20 19.1046 20 18L20 16C20 14.8954 19.2325 14 18.2857 14L15.7143 14C14.7675 14 14 14.8954 14 16Z"
                    stroke={
                      location.pathname.includes("dashboard")
                        ? "currentColor"
                        : "#8F92A1"
                    }
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4 6L4 8C4 9.10457 4.89543 10 6 10L18 10C19.1046 10 20 9.10457 20 8L20 6C20 4.89543 19.1046 4 18 4L6 4C4.89543 4 4 4.89543 4 6Z"
                    stroke={
                      location.pathname.includes("dashboard")
                        ? "currentColor"
                        : "#8F92A1"
                    }
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>

              <span className="block md:hidden lg:block pl-6 md:pl-0 lg:pl-2 xl:pl-6 pr-6 md:pr-0">
                Dashboard
              </span>
            </div>
          </NavLink>
          <NavLink
            className="cursor-pointer bg-white rounded-tr rounded-br py-2"
            to={`${match.url}/restoration-sites`}
            activeClassName="cursor-default text-white bg-gradient-to-b from-my-sky-blue-100 to-my-sky-blue-200 rounded-tr rounded-br py-2"
          >
            <div className="w-full flex items-center justify-start md:justify-center lg:justify-start pl-6 md:pl-0 lg:pl-2 xl:pl-6">
              <span className="w-4 h-4">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 7C12 4.79086 10.2091 3 8 3C5.79086 3 4 4.79086 4 7V13C4 15.2091 5.79086 17 8 17C10.2091 17 12 15.2091 12 13V7Z"
                    stroke={
                      location.pathname.includes("restoration-sites")
                        ? "currentColor"
                        : "#8F92A1"
                    }
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="ro und"
                  />
                  <path
                    d="M17 7H15C13.3431 7 12 8.34315 12 10V14C12 15.6569 13.3431 17 15 17H17C18.6569 17 20 15.6569 20 14V10C20 8.34315 18.6569 7 17 7Z"
                    stroke={
                      location.pathname.includes("restoration-sites")
                        ? "currentColor"
                        : "#8F92A1"
                    }
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="ro und"
                  />
                  <path
                    d="M8 21V13"
                    stroke={
                      location.pathname.includes("restoration-sites")
                        ? "currentColor"
                        : "#8F92A1"
                    }
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="ro und"
                  />
                  <path
                    d="M16 21V14"
                    stroke={
                      location.pathname.includes("restoration-sites")
                        ? "currentColor"
                        : "#8F92A1"
                    }
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="ro und"
                  />
                </svg>
              </span>
              <span className="block md:hidden lg:block pl-6 md:pl-0 lg:pl-2 xl:pl-6 pr-6 md:pr-0">
                Restoration Sites
              </span>
            </div>
          </NavLink>
          <NavLink
            className="cursor-pointer bg-white rounded-tr rounded-br py-2"
            exact
            to={`${match.url}/marketing-portal`}
            activeClassName="cursor-default text-white bg-gradient-to-b from-my-sky-blue-100 to-my-sky-blue-200 rounded-tr rounded-br py-2"
          >
            <div className="w-full flex items-center justify-start md:justify-center lg:justify-start pl-6 md:pl-0 lg:pl-2 xl:pl-6">
              <span className="w-4 h-4">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 4L4 8L12 12L20 8L12 4Z"
                    stroke={
                      location.pathname.endsWith("marketing-portal")
                        ? "currentColor"
                        : "#8F92A1"
                    }
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4 12L12 16L20 12"
                    stroke={
                      location.pathname.endsWith("marketing-portal")
                        ? "currentColor"
                        : "#8F92A1"
                    }
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4 16L12 20L20 16"
                    stroke={
                      location.pathname.endsWith("marketing-portal")
                        ? "currentColor"
                        : "#8F92A1"
                    }
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <span className="block md:hidden lg:block pl-6 md:pl-0 lg:pl-2 xl:pl-6 pr-6 md:pr-0">
                Marketing Portal
              </span>
            </div>
          </NavLink>
          <NavLink
            className="cursor-pointer bg-white rounded-tr rounded-br py-2"
            exact
            to={`${match.url}/referrals`}
            activeClassName="cursor-default text-white bg-gradient-to-b from-my-sky-blue-100 to-my-sky-blue-200 rounded-tr rounded-br py-2"
          >
            <div className="w-full flex items-center justify-start md:justify-center lg:justify-start pl-6 md:pl-0 lg:pl-2 xl:pl-6">
              <span className="w-4 h-4">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z"
                    stroke={
                      location.pathname.endsWith("referrals")
                        ? "currentColor"
                        : "#8F92A1"
                    }
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="ro und"
                  />
                  <path
                    d="M18 9C19.6569 9 21 7.65685 21 6C21 4.34315 19.6569 3 18 3C16.3431 3 15 4.34315 15 6C15 7.65685 16.3431 9 18 9Z"
                    stroke={
                      location.pathname.endsWith("referrals")
                        ? "currentColor"
                        : "#8F92A1"
                    }
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="ro und"
                  />
                  <path
                    d="M18 21C19.6569 21 21 19.6569 21 18C21 16.3431 19.6569 15 18 15C16.3431 15 15 16.3431 15 18C15 19.6569 16.3431 21 18 21Z"
                    stroke={
                      location.pathname.endsWith("referrals")
                        ? "currentColor"
                        : "#8F92A1"
                    }
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="ro und"
                  />
                  <path
                    d="M8.7002 10.7L15.3002 7.30005"
                    stroke={
                      location.pathname.endsWith("referrals")
                        ? "currentColor"
                        : "#8F92A1"
                    }
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="ro und"
                  />
                  <path
                    d="M8.7002 13.3L15.3002 16.7"
                    stroke={
                      location.pathname.endsWith("referrals")
                        ? "currentColor"
                        : "#8F92A1"
                    }
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="ro und"
                  />
                </svg>
              </span>
              <span className="block md:hidden lg:block pl-6 md:pl-0 lg:pl-2 xl:pl-6 pr-6 md:pr-0">
                Referrals
              </span>
            </div>
          </NavLink>
          <NavLink
            className="cursor-pointer bg-white rounded-tr rounded-br py-2"
            exact
            to={`${match.url}/billing`}
            activeClassName="cursor-default text-white bg-gradient-to-b from-my-sky-blue-100 to-my-sky-blue-200 rounded-tr rounded-br py-2"
          >
            <div className="w-full flex items-center justify-start md:justify-center lg:justify-start pl-6 md:pl-0 lg:pl-2 xl:pl-6">
              <span className="w-4 h-4">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H14L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21Z"
                    stroke={
                      location.pathname.endsWith("billing")
                        ? "currentColor"
                        : "#8F92A1"
                    }
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.35 10C14.2505 9.71762 14.0687 9.47148 13.8281 9.29327C13.5875 9.11505 13.2991 9.01292 13 9H11C10.6022 9 10.2206 9.15804 9.93934 9.43934C9.65804 9.72064 9.5 10.1022 9.5 10.5C9.5 10.8978 9.65804 11.2794 9.93934 11.5607C10.2206 11.842 10.6022 12 11 12H13C13.3978 12 13.7794 12.158 14.0607 12.4393C14.342 12.7206 14.5 13.1022 14.5 13.5C14.5 13.8978 14.342 14.2794 14.0607 14.5607C13.7794 14.842 13.3978 15 13 15H11C10.7009 14.9871 10.4125 14.8849 10.1719 14.7067C9.93128 14.5285 9.74952 14.2824 9.65 14"
                    stroke={
                      location.pathname.endsWith("billing")
                        ? "currentColor"
                        : "#8F92A1"
                    }
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 15V16.5M12 7.5V9V7.5Z"
                    stroke={
                      location.pathname.endsWith("billing")
                        ? "currentColor"
                        : "#8F92A1"
                    }
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <span className="block md:hidden lg:block pl-6 md:pl-0 lg:pl-2 xl:pl-6 pr-6 md:pr-0">
                Billing
              </span>
            </div>
          </NavLink>
          <NavLink
            className="cursor-pointer bg-white rounded-tr rounded-br py-2"
            exact
            to={`${match.url}/documents`}
            activeClassName="cursor-default text-white bg-gradient-to-b from-my-sky-blue-100 to-my-sky-blue-200 rounded-tr rounded-br py-2"
          >
            <div className="w-full flex items-center justify-start md:justify-center lg:justify-start pl-6 md:pl-0 lg:pl-2 xl:pl-6">
              <span className="w-4 h-4">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 3V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H19"
                    stroke={
                      location.pathname.endsWith("documents")
                        ? "currentColor"
                        : "#8F92A1"
                    }
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H14L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21Z"
                    stroke={
                      location.pathname.endsWith("documents")
                        ? "currentColor"
                        : "#8F92A1"
                    }
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9 9H10"
                    stroke={
                      location.pathname.endsWith("documents")
                        ? "currentColor"
                        : "#8F92A1"
                    }
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9 13H15"
                    stroke={
                      location.pathname.endsWith("documents")
                        ? "currentColor"
                        : "#8F92A1"
                    }
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9 17H15"
                    stroke={
                      location.pathname.endsWith("documents")
                        ? "currentColor"
                        : "#8F92A1"
                    }
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <span className="block md:hidden lg:block pl-6 md:pl-0 lg:pl-2 xl:pl-6 pr-6 md:pr-0">
                Documents
              </span>
            </div>
          </NavLink>
        </div>
        <div className="self-end">
          <div className="flex flex-col items-center text-black text-xxs">
            <img
              className="w-10 md:w-6 lg:w-10 h-10 md:h-6 lg:h-10"
              src={logoImage}
              alt="logo"
            />
            <p className="block md:hidden lg:block">Powered by</p>
            <p className="w-full block md:hidden lg:block text-center font-bold">
              Reduce. Reuse. Grow. Inc.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = () => ({});

export default withRouter(connect(mapStateToProps)(LeftBar));
