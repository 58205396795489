import { Component } from "react";
import isEmpty from "lodash.isempty";

// components:
import Marker from "./Marker";

// examples:
import GoogleMap from "./GoogleMap";

// Return map bounds based on list of places
const getMapBounds = (map, maps, places) => {
  const bounds = new maps.LatLngBounds();

  places.forEach((place) => {
    bounds.extend(
      new maps.LatLng(place.geometry.location.lat, place.geometry.location.lng)
    );
  });
  return bounds;
};

// Re-center map when resizing the window
const bindResizeListener = (map, maps, bounds) => {
  maps.event.addDomListenerOnce(map, "idle", () => {
    maps.event.addDomListener(window, "resize", () => {
      map.fitBounds(bounds);
    });
  });
};

// Fit map to its bounds after the api is loaded
const apiIsLoaded = (map, maps, places) => {
  // Get bounds by our places
  const bounds = getMapBounds(map, maps, places);
  // Fit map to bounds
  map.fitBounds(bounds);
  // Bind the resize listener
  bindResizeListener(map, maps, bounds);
};

class Map extends Component {
  constructor(props) {
    super(props);

    this.state = {
      places: [],
    };
  }

  componentDidMount() {
    fetch("places.json")
      .then((response) => response.json())
      .then((data) => this.setState({ places: data.results }));
  }

  render() {
    const { places } = this.state;
    const { site } = this.props;
    return (
      <>
        {/* {!isEmpty(places) && ( */}
        <GoogleMap
          defaultZoom={10}
          //   center={[31.200092, 29.918739]}
          defaultCenter={{
            lat: Number(site?.latitude),
            lng: Number(site?.longitude),
          }}
          yesIWantToUseGoogleMapApiInternals
          //   onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, places)}
        >
          {/* {places.map((place) => (
            <Marker
              key={place.id}
              text={place.name}
              // lat={place.geometry.location.lat}
              // lng={place.geometry.location.lng}
              lat={26.820553}
              lng={30.802498}
            />
          ))} */}
          <Marker
            key={1}
            text={"Demo"}
            // lat={place.geometry.location.lat}
            // lng={place.geometry.location.lng}

            lat={Number(site?.latitude)}
            lng={Number(site?.longitude)}
          />
        </GoogleMap>
        {/* )} */}
      </>
    );
  }
}

export default Map;
