import { useRef, useEffect, useState } from "react";
import RsSmall from "../RsSmall/RsSmall";
import { Spinner } from "../../common/Icons";
import ReactGlobe, { defaultDotMarkerOptions } from "react-globe";
import globeTexture_1 from "../../../assets/images/globe/texture/1.png";
import { markerRenderer, markerRenderer2 } from "./markerRenderer";
import markerTooltipRenderer from "./markerTooltipRenderer";

function GlobeView({ rsData, rsIsLoading, markers = [] }) {
  const globeParentRef = useRef(null);

  const [isLoadingGlobeTexture, setIsLoadingGlobeTexture] = useState(true);

  useEffect(() => {}, [
    globeParentRef?.current,
    isLoadingGlobeTexture,
    markers,
  ]);

  const globeTexture_2 =
    "//unpkg.com/three-globe/example/img/earth-blue-marble.jpg";

  const cloudTexture_1 =
    "https://raw.githubusercontent.com/chrisrzhou/react-globe/main/textures/clouds2.png";

  return (
    <div className="h-full overflow-y-auto px-8 pr-4">
      {!rsData || rsIsLoading ? (
        <div className="w-full h-full flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div className="h-full grid grid-cols-2 grid-rows-1 gap-x-4 overflow-hidden">
          <div
            ref={globeParentRef}
            className="col-span-1 row-span-1 overflow-hidden"
          >
            {markers.length === 0 ? (
              <div className="flex justify-center items-center h-full">
                <span className="font-bold text-3xl">No Coordinates found</span>
              </div>
            ) : (
              <ReactGlobe
                markers={markers}
                globeTexture={globeTexture_2}
                // globeCloudsTexture={cloudTexture_1}
                globeBackgroundTexture={null}
                height={
                  globeParentRef?.current
                    ? globeParentRef?.current?.offsetHeight
                    : "0"
                }
                options={{
                  cameraAutoRotateSpeed: 1,
                  cameraRotateSpeed: 0.5,
                  enableCameraAutoRotate: true,
                  enableCameraRotate: true,

                  enableMarkerGlow: true,
                  markerRadiusScaleRange: [0.005, 0.02],
                  markerType: "dot",

                  globeCloudsOpacity: 1,
                  markerRenderer: markerRenderer,
                  markerTooltipRenderer,
                }}
                onGlobeTextureLoaded={() => setIsLoadingGlobeTexture(false)}
              />
            )}
          </div>
          <div className="col-span-1 row-span-1 pr-2">
            <div className="h-full w-full grid grid-cols-1 lg:grid-cols-2 grid-flow-row auto-rows-max gap-2 overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-my-gray-201 scrollbar-track-transparent px-4">
              {rsData?.results?.map((site) => (
                //   <div key={site?.id} className="col-span-1 row-span-1">
                <RsSmall key={site?.id} site={site} showMap={false} />
                //   </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default GlobeView;
