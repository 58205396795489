export const NumberFormater = (number) => {
  if (number > 1000000000) {
    return (number / 1000000000).toString() + "B";
  } else if (number > 1000000) {
    return (number / 1000000).toString() + "M";
  } else if (number > 1000) {
    return (number / 1000).toString() + "K";
  } else {
    return number.toString();
  }
};

export const getCookie = (name) => {
  var cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

export const getDataDynamicallyFromObject = (path, obj) => {
  return path.split(".").reduce(function (prev, curr) {
    return prev ? prev[curr] : null;
  }, obj || window.self);
};

export const checkIsNumberAndHasDecimal = (value) => {
  return !isNaN(value) && value % 1 > 0;
};

export const aroundDecimalValueToOneDecimal = (value) => {
  return Math.round(value * 10) / 10;
};

export const aroundDecimalValueToCustomDecimal = (value, numOfDecimals) => {
  return Number(value).toFixed(numOfDecimals);
};

export const numberWithCommas = (number) => {
  return new Intl.NumberFormat().format(number);
  // return number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};

export const downloadFromBlob = (blob, filename) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  // the filename you want
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

export const isValidDate = (value) => {
  try {
    return (
      (value.match(/-/g) || []).length === 2 &&
      (value.match(/:/g) || []).length === 2
    );
  } catch {
    return true;
  }
};

export const getImageUrlOfAwsToQuestionMark = (url) => {
  return url.substring(0, url.indexOf("?"));
};
