import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef, useState, useEffect } from "react";
import { connect } from "react-redux";

import { IoClose } from "react-icons/io5";

import { handleReleaseTreeFromEscrow } from "../../actions/adminPanel";

import ModalSelect from "../common/ModalSelect";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import CustomLuxonUtils from "../common/CustomLuxonUtils";

function ReleaseTreesFromEscrowModal({
  open,
  closeModal,
  openLoadingModal,
  closeLoadingModal,
  dispatch,
  adminPanel,
}) {
  const cancelButtonRef = useRef();
  const [amount, setAmount] = useState(0);
  const [selectedDate, handleDateChange] = useState(new Date());
  const [selectedSite, setSelectedSite] = useState({
    id: 0,
    name: "Empty",
  });

  const handleCloseModal = () => {
    closeModal();
  };

  const handleSendRequestSuccess = () => {
    closeLoadingModal();
  };

  const handleReleaseTreeFromEscrowClick = () => {
    const dateObj = new Date(selectedDate);
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth();
    const day = dateObj.getDate();

    dispatch(
      handleReleaseTreeFromEscrow(
        {
          site: selectedSite.id,
          date: `${year}-${month + 1}-${day}`,
          amount,
        },
        handleSendRequestSuccess
      )
    );

    setTimeout(() => {
      openLoadingModal();
    }, 100);
    closeModal();
  };

  return (
    <>
      <Transition show={open} as={Fragment}>
        <Dialog
          as="div"
          className="text-sm font-inter bg-black bg-opacity-50 fixed inset-0 z-20 overflow-y-auto"
          initialFocus={cancelButtonRef}
          static
          open={open}
          onClose={() => {}}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full sm:w-2/3 md:w-3/5 xl:w-6/12 2xl:w-5/12 transition transition-width ease-in duration-700 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="z-10 h-0 absolute top-0 left-0 bg-transparent py-4">
                  <input
                    className="z-10 h-0 cursor-auto bg-my-gray-300 placeholder-my-gray-300 "
                    placeholder=""
                  />
                </div>
                <div className="relative flex justify-between items-center w-full h-20 rounded-tl-xl rounded-tr-xl bg-my-green-400 px-8">
                  <p className="text-my-gray-900 font-bold text-lg">
                    Release Trees From Escrow
                  </p>
                  <div>
                    <span
                      ref={cancelButtonRef}
                      onClick={handleCloseModal}
                      className="cursor-pointer font-medium text-white text-xs hover:text-opacity-75"
                    >
                      <IoClose
                        size={25}
                        className="text-my-gray-400 hover:text-my-red-100"
                      />
                    </span>
                  </div>
                </div>
                <div className="w-full h-full px-16 my-8">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-12 md:col-span-6 flex gap-y-1 flex-col justify-start">
                      <p className="font-medium tracking-wider">
                        Restoration Site:
                      </p>
                      <div className="z-10 h-10">
                        <ModalSelect
                          isDisabled={false}
                          options={
                            adminPanel?.sites
                              ? [
                                  { id: 0, name: "Empty" },
                                  ...adminPanel?.sites?.results,
                                ]
                              : [{ id: 0, name: "Empty" }]
                          }
                          selected={selectedSite}
                          setSelected={setSelectedSite}
                        />
                      </div>
                    </div>
                    <div className="col-span-12 md:col-span-6 flex flex-col justify-start">
                      <p className="font-medium tracking-wider">Amount:</p>
                      <input
                        className="h-10 w-full font-bold outline-none bg-my-gray-201 disabled:bg-my-gray-101 rounded-md px-4 mt-1"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </div>
                    <div className="col-span-12 md:col-span-6 flex gap-y-1 flex-col justify-start">
                      <MuiPickersUtilsProvider utils={CustomLuxonUtils}>
                        <KeyboardDatePicker
                          PopoverProps={{
                            PaperProps: {
                              style: {
                                backgroundColor: "#F9FAFA",
                                fontFamily: "Inter",
                                borderWidth: "0px",
                              },
                            },
                          }}
                          SelectProps={{
                            backgroundColor: "#87C55C",
                          }}
                          inputProps={{
                            style: {
                              color: "#1A202C",
                              borderColor: "transparent",
                              fontFamily: "Inter",
                              borderStyle: "none",
                              borderWidth: "0px",
                              // borderRadius: radii[3],
                              outline: "none",
                              // fontSize: fontSizes[3],
                              padding: `2px 2px`,
                            },
                          }}
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          value={selectedDate}
                          onChange={handleDateChange}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                </div>

                <div className="relative flex justify-end items-center w-full h-20 rounded-bl-xl rounded-br-xl bg-my-green-400">
                  <div
                    onClick={handleCloseModal}
                    className="cursor-pointer flex justify-center items-center w-42 lg:w-52 h-12 tracking-widest text-my-sky-blue-200 font-bold rounded-md border-2 border-my-sky-blue-200 bg-white my-4 mr-4"
                  >
                    <p className="uppercase mx-3">cancel</p>
                  </div>
                  <div
                    onClick={handleReleaseTreeFromEscrowClick}
                    className="cursor-pointer flex justify-center items-center w-62 lg:w-72 h-12 tracking-widest text-white font-bold rounded-md bg-gradient-to-b from-my-sky-blue-100 to-my-sky-blue-200 my-4 mr-4"
                  >
                    <p className="uppercase mx-3">release</p>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

const mapStateToProps = ({ adminPanel }) => ({
  adminPanel,
});

export default connect(mapStateToProps)(ReleaseTreesFromEscrowModal);
