import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef } from "react";
import { connect } from "react-redux";

import logoImage from "../../assets/images/logo_black.svg";

function PrivacyPolicyModal({ open, closeModal }) {
  const cancelButtonRef = useRef();

  const handleCloseModal = () => {
    closeModal();
  };

  return (
    <>
      <Transition show={open} as={Fragment}>
        <Dialog
          as="div"
          className="text-sm font-inter bg-black bg-opacity-50 fixed inset-0 z-20 overflow-y-auto"
          initialFocus={cancelButtonRef}
          static
          open={open}
          onClose={handleCloseModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full sm:w-2/3 md:w-3/5 xl:w-6/12 2xl:w-5/12 transition transition-width ease-in duration-700 my-8 text-left align-middle transition-all transform bg-my-blue-100 shadow-xl rounded-2xl px-8 py-6">
                <div className="z-10 h-0 absolute top-0 left-0 bg-transparent py-4">
                  <input
                    className="z-10 h-0 cursor-auto bg-my-gray-300 placeholder-my-gray-300 "
                    placeholder=""
                  />
                </div>
                <div className="flex justify-between items-center">
                  <div
                    onClick={handleCloseModal}
                    className="cursor-pointer flex items-center text-my-sky-blue-101"
                  >{`< Go Back`}</div>
                  <p className="text-lg font-bold tracking-wider">{`Privacy & Policy`}</p>
                  <div className="">
                    <div className="flex flex-col items-center text-black text-xxs">
                      <img className="w-10 h-10" src={logoImage} alt="logo" />
                      <p>Powered by</p>
                      <p className="font-bold">Reduce. Reuse. Grow. Inc.</p>
                    </div>
                  </div>
                </div>
                <div className="w-full h-full bg-white rounded-lg px-16 py-8 mt-8">
                  <p className="w-full font-bold text-lg text-my-gray-901">
                    Introduction
                  </p>
                  <p className="w-full text-my-gray-400 mt-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    semper nisl nec mauris vestibulum venenatis ut eu justo.
                    Fusce at ipsum vitae felis facilisis imperdiet nec ut urna.
                    In convallis ullamcorper iaculis. Etiam non sodales nisl,
                    viverra faucibus urna. Praesent sed ornare elit. Quisque et
                    odio eget nulla ullamcorper porta vel eget mauris. Ut eget
                    arcu ante. Donec hendrerit ultrices ante, quis aliquam lorem
                    suscipit consequat. Pellentesque habitant morbi tristique
                    senectus et netus et malesuada fames ac turpis egestas.
                    Suspendisse efficitur sapien et efficitur mollis. Donec id
                    congue mauris.
                  </p>
                  <p className="w-full font-bold text-lg text-my-gray-901 mt-6">
                    Using Our Services
                  </p>
                  <p className="w-full text-my-gray-400 mt-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    semper nisl nec mauris vestibulum venenatis ut eu justo.
                    Fusce at ipsum vitae felis facilisis imperdiet nec ut urna.
                    In convallis ullamcorper iaculis. Etiam non sodales nisl,
                    viverra faucibus urna. Praesent sed ornare elit. Quisque et
                    odio eget nulla ullamcorper porta vel eget mauris. Ut eget
                    arcu ante. Donec hendrerit ultrices ante, quis aliquam lorem
                    suscipit consequat. Pellentesque habitant morbi tristique
                    senectus et netus et malesuada fames ac turpis egestas.
                    Suspendisse efficitur sapien et efficitur mollis. Donec id
                    congue mauris.
                  </p>
                  <p className="w-full font-bold text-lg text-my-gray-901 mt-6">
                    Privacy and Copyright Protection
                  </p>
                  <p className="w-full text-my-gray-400 mt-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    semper nisl nec mauris vestibulum venenatis ut eu justo.
                    Fusce at ipsum vitae felis facilisis imperdiet nec ut urna.
                    In convallis ullamcorper iaculis. Etiam non sodales nisl,
                    viverra faucibus urna. Praesent sed ornare elit. Quisque et
                    odio eget nulla ullamcorper porta vel eget mauris. Ut eget
                    arcu ante. Donec hendrerit ultrices ante, quis aliquam lorem
                    suscipit consequat. Pellentesque habitant morbi tristique
                    senectus et netus et malesuada fames ac turpis egestas.
                    Suspendisse efficitur sapien et efficitur mollis. Donec id
                    congue mauris.
                  </p>
                  <p className="w-full text-my-gray-400 mt-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    semper nisl nec mauris vestibulum venenatis ut eu justo.
                    Fusce at ipsum vitae felis facilisis imperdiet nec ut urna.
                    In convallis ullamcorper iaculis. Etiam non sodales nisl,
                    viverra faucibus urna. Praesent sed ornare elit. Quisque et
                    odio eget nulla ullamcorper porta vel eget mauris. Ut eget
                    arcu ante. Donec hendrerit ultrices ante, quis aliquam lorem
                    suscipit consequat. Pellentesque habitant morbi tristique
                    senectus et netus et malesuada fames ac turpis egestas.
                    Suspendisse efficitur sapien et efficitur mollis. Donec id
                    congue mauris.
                  </p>
                  <p className="w-full text-my-gray-400 mt-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    semper nisl nec mauris vestibulum venenatis ut eu justo.
                    Fusce at ipsum vitae felis facilisis imperdiet nec ut urna.
                    In convallis ullamcorper iaculis. Etiam non sodales nisl,
                    viverra faucibus urna. Praesent sed ornare elit. Quisque et
                    odio eget nulla ullamcorper porta vel eget mauris. Ut eget
                    arcu ante. Donec hendrerit ultrices ante, quis aliquam lorem
                    suscipit consequat. Pellentesque habitant morbi tristique
                    senectus et netus et malesuada fames ac turpis egestas.
                    Suspendisse efficitur sapien et efficitur mollis. Donec id
                    congue mauris.
                  </p>
                  <p className="w-full font-bold text-lg text-my-gray-901 mt-6">
                    Your Content in Our Services
                  </p>
                  <p className="w-full text-my-gray-400 mt-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    semper nisl nec mauris vestibulum venenatis ut eu justo.
                    Fusce at ipsum vitae felis facilisis imperdiet nec ut urna.
                    In convallis ullamcorper iaculis. Etiam non sodales nisl,
                    viverra faucibus urna. Praesent sed ornare elit. Quisque et
                    odio eget nulla ullamcorper porta vel eget mauris. Ut eget
                    arcu ante. Donec hendrerit ultrices ante, quis aliquam lorem
                    suscipit consequat. Pellentesque habitant morbi tristique
                    senectus et netus et malesuada fames ac turpis egestas.
                    Suspendisse efficitur sapien et efficitur mollis. Donec id
                    congue mauris.
                  </p>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default connect()(PrivacyPolicyModal);
