import axios from "axios";

import { showLoading, hideLoading } from "react-redux-loading";
import { getRestorationDataPerUserRequest } from "../utils/api";
import { setErrorMessage } from "./shared";

// Actions declaration
export const GET_RESTORATION_DATA_PER_USER = "GET_RESTORATION_DATA_PER_USER";

// Action functions for updating the store
export function getRestorationDataPerUser(restorationData) {
  return {
    type: GET_RESTORATION_DATA_PER_USER,
    restorationData,
  };
}

// Handling fetch restoration data per user request
export function handleGetRestorationDataPerUser(
  parameters,
  showMessage = true
) {
  return (dispatch) => {
    dispatch(showLoading());
    getRestorationDataPerUserRequest(parameters)
      .then((response) => {
        
        dispatch(
          getRestorationDataPerUser({
            ...response.data,
            current: response.config.url,
            userId: parameters.userId,
          })
        );
      })
      .then(() => {
        if (showMessage) {
          dispatch(
            setErrorMessage({
              message: "Restoration Data Fetched Successfully",
              type: "info",
            })
          );
        }
      })
      .catch((err) => {
        let msg = "Failed to Fetch Restoration Data";
        if (err && err.response && err.response.data) {
          msg = JSON.stringify(err.response.data).replace(/"/g, "");
        }
        dispatch(
          setErrorMessage({
            message: msg,
            type: "error",
          })
        );
      })
      .then(() =>
        setTimeout(() => {
          dispatch(setErrorMessage({}));
        }, 3000)
      )
      .then(() => dispatch(hideLoading()));
  };
}
