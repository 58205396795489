// Import Authorized users actions
import { GET_RESTORATION_DATA } from "../actions/restorationData";

// Authorized users reducer
export default function restorationData(state = [], action) {
  switch (action.type) {
    case GET_RESTORATION_DATA:
      return action.restorationData;
    default:
      return state;
  }
}
