// Import authed user actions
import {
  SIGN_UP,
  SIGN_IN,
  SAVE_PROFILE_DATA,
  SIGN_OUT
} from "../actions/authedUser";

// Authed user reducer
export default function authedUser(state = {}, action) {
  switch (action.type) {
    case SIGN_UP:
    case SIGN_IN:
    case SAVE_PROFILE_DATA:
      return {
        ...state,
        ...action.authedUser
      };
    case SIGN_OUT:
      return {};
    default:
      return state;
  }
}
