import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import classnames from "classnames";

import { handleUploadCsvFile } from "../../../actions/restorationData";

import { IoClose } from "react-icons/io5";
import { AiOutlineCloudUpload } from "react-icons/ai";

function UploadCsvModal({
  open,
  closeModal,
  openUploadCsvExampleModal,
  dispatch,
  isUploadingCsv,
}) {
  const cancelButtonRef = useRef();
  const [subDialogOpen, setSubDialogeOpen] = useState(false);
  const [csv, setCsv] = useState([]);

  useEffect(() => {
    setCsv([]);
  }, [open]);

  const handleCloseUploadCsvExampleModal = () => {
    setSubDialogeOpen(false);
  };

  const handleOpenUploadCsvExampleModal = () => {
    setSubDialogeOpen(true);
    openUploadCsvExampleModal(() => () => handleCloseUploadCsvExampleModal());
  };

  const handleCloseModal = () => {
    if (!subDialogOpen) {
      closeModal();
    }
  };

  const handleConfirm = () => {
    dispatch(handleUploadCsvFile(csv[0], handleUploadCsvSuccess));
  };

  const handleUploadCsvSuccess = () => {
    setCsv([]);
    closeModal();
  };

  return (
    <>
      <Transition show={open} as={Fragment}>
        <Dialog
          as="div"
          className="text-sm font-inter bg-black bg-opacity-50 fixed inset-0 z-20 overflow-y-auto"
          initialFocus={cancelButtonRef}
          static
          open={open}
          onClose={handleCloseModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full sm:w-2/3 md:w-3/5 xl:w-6/12 2xl:w-5/12 transition transition-width ease-in duration-700 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="z-10 h-0 absolute top-0 left-0 bg-transparent py-4">
                  <input
                    className="z-10 h-0 cursor-auto bg-my-gray-300 placeholder-my-gray-300 "
                    placeholder=""
                  />
                </div>
                <div className="relative flex justify-between items-center w-full h-20 rounded-tl-xl rounded-tr-xl bg-my-green-400 px-8">
                  <span className="flex">
                    <span className="text-my-gray-900 font-bold text-lg">
                      Upload CSV File
                    </span>

                    {isUploadingCsv && (
                      <AiOutlineCloudUpload
                        size={25}
                        className="animate-bounce text-my-blue-300 ml-4"
                      />
                    )}
                  </span>
                  <div>
                    <span
                      ref={cancelButtonRef}
                      onClick={handleCloseModal}
                      className="cursor-pointer font-medium text-white text-xs hover:text-opacity-75"
                    >
                      <IoClose
                        size={25}
                        className="text-my-gray-400 hover:text-my-red-100"
                      />
                    </span>
                  </div>
                </div>
                <div className="w-full h-full px-16 my-8">
                  <span className="w-full text-my-gray-400">
                    To upload CSV file, please drag & drop file in the box.{" "}
                    <span
                      onClick={handleOpenUploadCsvExampleModal}
                      className="cursor-pointer text-my-sky-blue-101 ml-1"
                    >
                      How to format CSV correctly?
                    </span>
                  </span>

                  <div className="flex flex-col w-full items-center justify-center mt-6">
                    <label className="relative w-full flex flex-col items-center py-6 bg-white text-blue rounded-lg tracking-wide uppercase border-2 border-dashed cursor-pointer">
                      <svg
                        width="92"
                        height="74"
                        viewBox="0 0 92 74"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M76.6526 25.0674C72.4006 7.94696 55.2224 -2.44792 38.2836 1.84972C25.0464 5.20832 15.4347 16.7718 14.4444 30.5296C5.0454 32.0962 -1.31754 41.0672 0.232453 50.567C1.61033 59.0125 8.85059 65.1974 17.3191 65.1635H31.6925V59.3524H17.3191C10.9685 59.3524 5.82031 54.149 5.82031 47.7303C5.82031 41.3117 10.9685 36.1082 17.3191 36.1082C18.9068 36.1082 20.1938 34.8074 20.1938 33.2027C20.1794 18.7606 31.7513 7.04116 46.0401 7.02682C58.4091 7.01429 69.0568 15.8525 71.4495 28.118C71.6857 29.3427 72.669 30.2783 73.8929 30.4424C81.752 31.5736 87.2155 38.9298 86.0965 46.873C85.0917 54.0057 79.0663 59.3165 71.9381 59.3524H60.4394V65.1635H71.9381C83.0517 65.1295 92.0337 55.996 91.9999 44.7632C91.9719 35.4129 85.6396 27.2863 76.6526 25.0674Z"
                          fill="black"
                          fillOpacity="0.15"
                        />
                        <path
                          d="M44.0249 36.9512L32.5262 48.5733L36.5795 52.6701L43.1913 46.0164V73.8804H48.9406V46.0164L55.5237 52.6701L59.577 48.5733L48.0782 36.9512C46.9569 35.8245 45.1462 35.8245 44.0249 36.9512Z"
                          fill="black"
                          fillOpacity="0.15"
                        />
                      </svg>
                      <input
                        onChange={(e) => {
                          const files = e.target.files;
                          if (files.length > 0) {
                            setCsv((csv) => [...csv, ...e.target.files]);
                          }
                        }}
                        type="file"
                        // multiple
                        className="cursor-pointer absolute w-full top-0 bottom-0 right-0 left-0 bg-my-gray-100 opacity-1"
                      />
                    </label>
                    {csv.length > 0 && (
                      <div className="flex flex-wrap items-center justify-start gap-2 w-full mt-6">
                        {csv.map((csvFile, selectedIndex) => {
                          return (
                            <div
                              key={selectedIndex}
                              className="flex items-center justify-center w-max bg-my-gray-101 px-4 py-2"
                            >
                              <p className="text-xs">{`"${csvFile?.name}"`}</p>
                              <span
                                onClick={() => {
                                  setCsv(
                                    csv.filter(
                                      (csvFile, index) =>
                                        index !== selectedIndex
                                    )
                                  );
                                }}
                                className="cursor-pointer font-medium text-white text-xs hover:text-opacity-75 ml-4"
                              >
                                <IoClose
                                  size={15}
                                  className="text-my-gray-400 hover:text-my-red-100"
                                />
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>

                <div className="relative flex justify-end items-center w-full h-20 rounded-bl-xl rounded-br-xl bg-my-green-400">
                  <div
                    onClick={handleCloseModal}
                    className="cursor-pointer flex justify-center items-center w-42 lg:w-52 h-12 tracking-widest text-my-sky-blue-200 font-bold rounded-md border-2 border-my-sky-blue-200 bg-white my-4 mr-4"
                  >
                    <p className="uppercase mx-3">cancel</p>
                  </div>
                  <div
                    onClick={() => {
                      if (csv.length > 0) {
                        handleConfirm();
                      }
                    }}
                    className={classnames(
                      "flex justify-center items-center w-62 lg:w-72 h-12 tracking-widest font-bold rounded-md my-4 mr-4",
                      {
                        "cursor-pointer bg-gradient-to-b from-my-sky-blue-100 to-my-sky-blue-200 text-white":
                          csv.length > 0,
                        "bg-my-gray-201 text-my-sky-blue-101 text-opacity-50":
                          csv.length === 0,
                      }
                    )}
                  >
                    <p className="uppercase mx-3">confirm</p>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default connect()(UploadCsvModal);
