import { useState } from "react";
import MonthlyEscrowReleaseTopBar from "./MonthlyEscrowReleaseTopBar";
import MonthlyEscrowReleaseBody from "./MonthlyEscrowReleaseBody";

function MonthlyEscrowRelease() {
  const [selectedView, setSelectedView] = useState("graph");

  return (
    <div className="w-full h-full">
      <MonthlyEscrowReleaseTopBar
        selectedView={selectedView}
        setSelectedView={setSelectedView}
      />
      <div className="flex items-center w-full my-4">
        <div className="w-full border-t border-my-gray-102"></div>
      </div>
      <MonthlyEscrowReleaseBody selectedView={selectedView} />
    </div>
  );
}

export default MonthlyEscrowRelease;
