import { combineReducers } from "redux";

import authedUser from "./authedUser";
import authorizedUsers from "./authorizedUsers";
import restorationData from "./restorationData";
import restorationDataPerUser from "./restorationDataPerUser";
import graphData from "./graphData";
import dashboard from "./dashboard";
import shared from "./shared";
import adminPanel from "./adminPanel";
import modals from "./modals";
import { loadingBarReducer } from "react-redux-loading";

export default combineReducers({
  authedUser,
  authorizedUsers,
  restorationData,
  restorationDataPerUser,
  graphData,
  dashboard,
  shared,
  adminPanel,
  modals,
  loadingBar: loadingBarReducer,
});
