import React, { useState, useEffect } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  CartesianAxis,
  Tooltip,
  ResponsiveContainer
} from "recharts";
import { connect } from "react-redux";
import Tippy from "@tippyjs/react";
import classnames from "classnames";

import { NumberFormater } from "../../../utils/helper";

import useWindowDimensions from "../../main/useWindowDimensions";

const dataInit = [
  {
    name: "Jan",
    pv: 2000,
    amt: 1100
  },
  {
    name: "Feb",
    pv: 1098,
    amt: 2210
  },
  {
    name: "Mar",
    pv: 2800,
    amt: 2990
  },
  {
    name: "Apr",
    pv: 3908,
    amt: 2000
  },
  {
    name: "May",
    pv: 4800,
    amt: 4181
  },
  {
    name: "Jun",
    pv: 3800,
    amt: 2500
  },
  {
    name: "Jul",
    pv: 4300,
    amt: 2100
  },
  {
    name: "Aug",
    pv: 5500,
    amt: 4290
  },
  {
    name: "Sep",
    pv: 2400,
    amt: 2400
  },
  {
    name: "Oct",
    pv: 5500,
    amt: 4290
  },
  {
    name: "Nov",
    pv: 4800,
    amt: 2181
  },
  {
    name: "Dec",
    pv: 1398,
    amt: 2210
  }
];

function StackedAreaChart({
  selectedFirstGraph,
  selectedSecondGraph,
  data,
  keys
}) {
  // const RepositoryCoverageTimelineGraphTooltip = (props) => {
  //   const classes = useStyles({});

  //   if (props.payload && props.payload.length >= 1) {
  //     const { createdTimestamp, lineValue, branchValue } =
  //       props.payload[0].payload;

  //     return (
  //       <div
  //         className={classes.box}
  //         data-testid="coverage-timeline-graph-tooltip"
  //       >
  //         <div className={classes.line}>
  //           <span className={classes.label}>Line coverage</span>
  //           <span data-testid="tooltip-line-coverage-percentage">
  //             {lineValue}%
  //           </span>
  //         </div>
  //         <div className={classes.line}>
  //           <span className={classes.label}>Branch coverage</span>
  //           <span data-testid="tooltip-branch-coverage-percentage">
  //             {branchValue}%
  //           </span>
  //         </div>
  //         <div className={classes.line}>
  //           <span className={classes.label}>Run date</span>
  //           <span data-testid="tooltip-run-date">
  //             {moment(createdTimestamp).format("MMM Do YYYY h:mm a")}
  //           </span>
  //         </div>
  //       </div>
  //     );
  //   } else {
  //     return <span data-testid="empty-tooltip" />;
  //   }
  // };

  const { isSmScreen } = useWindowDimensions();

  return (
    <div className="col-start-1 col-end-17 md:col-end-15 row-start-3 row-end-17">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          margin={{ top: 4, left: -15, right: 4, bottom: 0 }}
          data={data}
        >
          <defs>
            <linearGradient id="firstGraph" x1="0" y1="0" x2="0" y2="1">
              <stop offset="75%" stopColor="#00A5D6" stopOpacity={0.3} />
              <stop
                offset="100%"
                stopColor="rgba(77, 192, 226, .2)"
                stopOpacity={1}
              />
            </linearGradient>
            <linearGradient id="secondGraph" x1="0" y1="0" x2="0" y2="1">
              <stop offset="75%" stopColor="#80BF54" stopOpacity={0.6} />
              <stop
                offset="100%"
                stopColor="rgba(128, 191, 84, .3)"
                stopOpacity={1}
              />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="7 7" />
          {/* <CartesianAxis tick={false} /> */}
          <XAxis
            tickLine={false}
            tick={{ fontSize: 10, transform: "translate(0, 3)" }}
            dataKey="name"
          />
          <YAxis
            tickLine={false}
            tick={{ fontSize: 10, transform: "translate(-3, 0)" }}
            tickFormatter={NumberFormater}
          />
          {/* <Tooltip content={<RepositoryCoverageTimelineGraphTooltip />} /> */}
          <Tooltip content={<div></div>} />
          {keys.map((key, index) => (
            <Area
              key={key}
              type="monotone"
              dataKey={key}
              stackId="1"
              stroke={`${
                key === selectedSecondGraph.id ? "#619B3A" : "#00A5D6"
              }`}
              strokeWidth={3}
              fill={`url(#${
                key === selectedSecondGraph.id ? "secondGraph" : "firstGraph"
              })`}
              activeDot={
                <CustomizedDot
                  dataLabel={
                    key === selectedFirstGraph.id
                      ? selectedFirstGraph.name
                      : selectedSecondGraph.name
                  }
                  placement={`${
                    key === selectedSecondGraph.id
                      ? isSmScreen
                        ? "top"
                        : "right"
                      : isSmScreen
                      ? "bottom"
                      : "left"
                  }`}
                  data={data}
                />
              }
            />
          ))}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default connect()(StackedAreaChart);

class CustomizedDot extends React.Component {
  render() {
    const {
      cx,
      cy,
      fill,
      placement,
      dataKey,
      payload,
      dataLabel,
      index,
      data
    } = this.props;

    const pastValue = index === 0 ? "NA" : data[index - 1][dataKey];
    const presentValue = payload[dataKey];
    const percentage =
      pastValue === "NA"
        ? 0
        : (presentValue - pastValue) /
          (pastValue === 0 && presentValue > 0
            ? pastValue
            : pastValue === 0
            ? 0.0000000000001
            : pastValue);

    return (
      <Tippy
        delay={1000}
        hideDelay={1000}
        duration={800}
        animation="fade"
        theme="light"
        arrow={true}
        visible={true}
        placement={placement}
        content={
          <div className="font-inter w-24 md:w-36 flex flex-col rounded-xl p-1 md:p-2">
            <span className="font-bold text-sm md:text-xl text-my-gray-901">
              {new Intl.NumberFormat().format(presentValue)}
            </span>
            <span className="capitalize font-light text-xxs md:text-xs text-my-gray-900 tracking-wider mt-1">
              {dataLabel}
            </span>
            <span
              className={classnames("self-end text-xxs mt-2", {
                "text-my-red-100": Math.sign(percentage) === -1,
                "text-my-green-101": Math.sign(percentage) === 1
              })}
            >
              {new Intl.NumberFormat("en-US", {
                style: "percent",
                minimumFractionDigits: 1,
                maximumFractionDigits: 1
              }).format(percentage)}
            </span>
          </div>
        }
      >
        <circle
          cx={cx}
          cy={cy}
          fill={fill}
          stroke="white"
          strokeWidth={2}
          r={5}
          cursor="pointer"
        >
          <span className="tooltiptext">Tooltip text</span>
        </circle>
      </Tippy>
    );
  }
}
