import * as React from "react";
import TextField from "@mui/material/TextField";
import DateRangePicker from "@mui/lab/DateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Box from "@mui/material/Box";

function BasicDateRangePicker({ selectedRangeDate, setSelectedRangeDate }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateRangePicker
        PopoverProps={{
          PaperProps: {
            style: {
              backgroundColor: "#F9FAFA",
              fontFamily: "Inter",
              borderWidth: "0px",
            },
          },
        }}
        startText="From"
        endText="To"
        value={selectedRangeDate}
        onChange={(newValue) => {
          setSelectedRangeDate(newValue);
        }}
        renderInput={(startProps, endProps) => (
          <React.Fragment>
            <TextField
              {...startProps}
              className="fix-thai"
              variant="standard"
              size="small"
              format="dd/MM/yyyy"
            />
            <Box sx={{ mx: 2 }}> to </Box>
            <TextField
              {...endProps}
              className="fix-thai"
              variant="standard"
              size="small"
              format="dd/MM/yyyy"
            />
          </React.Fragment>
        )}
      />
    </LocalizationProvider>
  );
}

export default BasicDateRangePicker;
