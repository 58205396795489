import classnames from "classnames";
import {
  checkIsNumberAndHasDecimal,
  aroundDecimalValueToOneDecimal,
  aroundDecimalValueToCustomDecimal,
  numberWithCommas,
} from "../../../utils/helper";

function SummaryCard({ value, label, isFirst, isLast }) {
  return (
    <div
      className={classnames(
        "flex flex-col justify-end md:gap-y-1 w-max h-full bg-white py-0.5 md:py-1 px-2 md:px-5 lg:px-9",
        {
          "rounded-tr-lg rounded-br-lg": isFirst,
          "rounded-tl-lg rounded-bl-lg": isLast,
          "rounded-lg": !isFirst && !isLast,
        }
      )}
    >
      <p className="font-bold md:font-extrabold text-base md:text-lg text-my-gray-900">
        {value
          ? checkIsNumberAndHasDecimal(value)
            ? numberWithCommas(aroundDecimalValueToCustomDecimal(value, 3))
            : !isNaN(value)
            ? numberWithCommas(value)
            : value
          : "0"}
      </p>
      <p className="capitalize text-xxs md:text-xs text-my-gray-400 w-max">
        {label}
      </p>
    </div>
  );
}

export default SummaryCard;
